import axios from "axios";
import env from "../../../../env/src_config";
import {isNull} from "../../../../izUtils";
import axiosErrorResponse from "../../../../hoc/AxiosErrorResponse/AxiosErrorResponse";

export const restoreLocalStorage = (objectId, reloadState, headers, infoTabRef) => {
    localStorage.removeItem('SODNEDRAZBE.JSData');
    for ( let i = 0; i < localStorage.length; i++ ) {
        let key = localStorage.key(i);
        if (key.startsWith("SODNEDRAZBE.subjectFiles_")) {
            localStorage.removeItem(key);
        }
    }

    axios.post(env.api.private + 'publication', objectId, headers).then((response) => {
        if (response.status === 200) {
            let localData = { "values": { "root": { }, "entities": [], "subjects": [ {} ], "files": [ {} ], "subjectFiles": [[{}]] } };
            if (objectId.id !== undefined) {
                localData.id = objectId.id;
            }
            let i;
            for(i = 0; i < response.data.fields.root.length; i++) {
                if (!isNull(response.data.fields.root[i].value)) {
                    localData.values.root[response.data.fields.root[i].id] = response.data.fields.root[i].value;
                }
            }

            if (!isNull(response.data.fields.subjects)) {
                for(i = 0; i < response.data.fields.subjects.length; i++) {

                    if (localData.values.subjects[i] === undefined) {
                        localData.values.subjects[i] = {};
                    }

                    for (let j = 0; j < response.data.fields.subjects[i].length; j++) {
                        if (!isNull(response.data.fields.subjects[i][j].value)) {
                            localData.values.subjects[i][response.data.fields.subjects[i][j].id] = response.data.fields.subjects[i][j].value;
                        }
                    }
                }
            }

            if (!isNull(response.data.fields.subjectFiles) && response.data.fields.subjectFiles.length !== 0) {
                let tmpFile, subjectFiles;
                for(i = 0; i < response.data.fields.subjectFiles.length; i++) {
                    subjectFiles = [];
                    for(let j = 0; j < response.data.fields.subjectFiles[i].length; j++) {
                        tmpFile = {};
                        for(let k = 0; k < response.data.fields.subjectFiles[i][j].length; k++) {
                            if (response.data.fields.subjectFiles[i][j][k].value !== null) {
                                tmpFile[response.data.fields.subjectFiles[i][j][k].id] = response.data.fields.subjectFiles[i][j][k].value;
                            }

                        }
                        subjectFiles.push(tmpFile);
                    }
                    if (subjectFiles.length === 0) {
                        subjectFiles.push({});
                    }
                    localStorage.setItem('SODNEDRAZBE.subjectFiles_'+i, JSON.stringify({values: { files: subjectFiles } }));
                }
            }

            if (!isNull(response.data.fields.files)) {
                for (i = 0; i < response.data.fields.files.length; i++) {
                    for (let j = 0; j < response.data.fields.files[i].length; j++) {
                        if (!isNull(response.data.fields.files[i][j].value)) {
                            if (localData.values.files[i] === undefined) {
                                localData.values.files[i] = {};
                            }
                            localData.values.files[i][response.data.fields.files[i][j].id] = response.data.fields.files[i][j].value;
                        }
                    }
                }
                let tmpFiles = [];
                for(let i = 0; i < localData.values.files.length; i++) {
                    if (localData.values.files[i].fileId !== undefined) {
                        tmpFiles.push(localData.values.files[i]);
                    }
                }
                if (!tmpFiles.length) {
                    tmpFiles.push({});
                }
                localData.values.files = tmpFiles;
            }

            if (!isNull(response.data.fields.entities)) {
                for (i = 0; i < response.data.fields.entities.length; i++) {
                    for (let j = 0; j < response.data.fields.entities[i].length; j++) {
                        if (!isNull(response.data.fields.entities[i][j].value)) {
                            if (localData.values.entities[i] === undefined) {
                                localData.values.entities[i] = {};
                            }
                            localData.values.entities[i][response.data.fields.entities[i][j].id] = response.data.fields.entities[i][j].value;
                        }
                    }
                }
            }

            localStorage.setItem('SODNEDRAZBE.JSData',JSON.stringify(localData));
            if (infoTabRef !== undefined) {
                if (infoTabRef.current !== undefined) {
                    infoTabRef.current.click();
                }
            }
            reloadState();
        }
    }, (error) => {
        axiosErrorResponse(error);
    });
}
