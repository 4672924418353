import React, { Component } from 'react';
import {linker} from "../../Helpers/Linker";

class Bail extends Component {

    render() {
        const fieldsLeft = ['estimatedPrice', 'startingPrice', 'stepPrice'].map(input => {
            return linker(input, this.props.data, this.props.selectChangeRootHandler, this.props.textChangeRootHandler, null, "4/8");
        });
        const fieldsRight = ['securityPrice', 'lowestPrice'].map(input => {
            return linker(input, this.props.data, this.props.selectChangeRootHandler, this.props.textChangeRootHandler, null, "4/8");
        });

        return (
            <div className="tab-pane" id="bail" role="tabpanel">
                <div className="row">
                    <div className="col-lg-6">
                        {fieldsLeft}
                    </div>
                    <div className="col-lg-6">
                        {fieldsRight}
                    </div>
                </div>
            </div>
        );
    }
}

export default Bail;
