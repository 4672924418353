import React, { Component, Fragment } from 'react';
import {withTranslation} from 'react-i18next';
import { withRouter } from 'react-router-dom';
import moment from "moment";
import Loading from '../../../hoc/Loading/Loading'
import ReactGA from 'react-ga4';
import {connect} from 'react-redux';

/* import HOC */
import Breadcrumb from "../../../hoc/Header/Breadcrumbs/Breadcrumb";
import Swal from "sweetalert2";

/* import Create tabs */
import Import from './Parts/Import/Import';
import Info from './Parts/Info/Info';
import SaleInfo from './Parts/SaleInfo/SaleInfo';
import SaleLocation from './Parts/SaleLocation/SaleLocation';
import Narok from './Parts/Narok/Narok';
import Bail from './Parts/Bail/Bail';
import Debtors from "./Parts/Debtors/Debtors";
import SubjectList from "./Parts/Subject/SubjectList";
import SpecailBail from './Parts/SpecialBail/SpecialBail';
import File from "./Parts/Files/File";

/* import helpers */
import {addressChangeRootHandler, selectChangeRootHandler, textChangeRootHandler, datetimeChangeRootHandler, selectChangeSubjectHandler, textChangeSubjectHandler, datetimeChangeSubjectHandler, selectChangeFilesHandler, textChangeFilesHandler} from './Helpers/ChangeHandlers';
import {transformer} from './Helpers/Transformer';
import {countErrors, countGroupErrors} from './Helpers/CountErrors';
import { fillSearch } from './Helpers/FillSearch';

/* import content parts */
import Tab from '../../../hoc/ContentParts/Tabs/Tab';
import axios from "axios";
import env from "../../../env/src_config";
import {isNull} from "../../../izUtils";
import {restoreLocalStorage} from './Helpers/SaveAndRestore';

import './Create.css';
import axiosErrorResponse from "../../../hoc/AxiosErrorResponse/AxiosErrorResponse";
import {getCity} from "../../../hoc/Form/Input/countries";

class Create2 extends Component {
    constructor(props) {
        super(props);
        const {t} = this.props;
        this.state = {
            id: this.props.match.params.id,
            data: null,
            addresses: null,
            entities: null,
            files: null,
            storedFiles: null,
            showNarokTab: true,
            showSaleLocationTab: true,
            label: [t('single.label.registerType'), t('single.label.caseNumber'), t('single.label.caseYear')],
            errorFields: null,
            lastActionSaved: false,
            draftPublished: false,
        };

        this.infoTabRef = React.createRef();

        this.initialJSDataState = {"values":{"root":{},"entities":[],"subjects":[{}], "subjectFiles": [[{}]], "files":[{}]}};

        this.headers = null;
        this.hash = null;
    }

    showLeavePrompt = false;

    fieldsInTabs = {
        info: ['procedureType', 'procedureSubtype', 'caseNumber', 'caseYear', 'court', 'registerType', 'judge', 'executor', 'bankruptcyTrustee'],
        debtors: [],
        saleInfo: ['saleForm', 'saleMethod', 'saleType', 'debtAmount', 'saleSequenceNumber', 'saleStartAt', 'saleEndAt', 'notifyAt', 'isDynamicExtension', 'dynamicExtensionInterval', 'isResale', 'isPreviousPublication', 'previousPublicationId', 'previousPublicationUrl', 'saleDescription'],
        saleLocation: ['saleTitle', 'saleAddress', 'salePlace'],
        narok: ['openBidAt', 'openBidTitle', 'openBidAddress', 'openBidPlace'],
        bail: ['estimatedPrice', 'startingPrice', 'stepPrice', 'securityPrice', 'lowestPrice'],
        specialBail: ['managerTitle', 'managerAddress', 'managerIban']
    }

    componentDidMount() {
        this.reactGa();
        this.headers = {...this.props.options.postHeaders};

        if (this.props.match.params.id !== undefined) {
            restoreLocalStorage({id: this.props.match.params.id}, this.reloadState, this.headers, false);
        } else {
            this.justClearStorage();
            localStorage.setItem('SODNEDRAZBE.JSData', JSON.stringify(this.initialJSDataState));
            this.reloadState();
        }

        this.props.history.block(() => {
            const { t } = this.props;
            if (this.showLeavePrompt) return t('draft.alert.leave');
        });
    }

    reactGa = () => {
        if (this.props.initializedGA) {
            ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
        }
    }

    save = (saveStatus, redirectAfterSave, hideSwal) => {
        this.showLeavePrompt = false;
        const { t } = this.props;
        const JSData = localStorage.getItem('SODNEDRAZBE.JSData');
        let data = JSON.parse(JSData);

        /* delete creditor if user is Stecajni upravitelj */
        if (this.props.options.parsedToken.vashRoleId === 304) {
            for (let i = 0; i < data.values.entities.length; i++) {
                if (data.values.entities[i] !== null) {
                    if (data.values.entities[i].type === "creditor") {
                        delete data.values.entities[i];
                    }
                }
            }
        }

        /* delete empty fields */
        let keys = Object.keys(data.values.root);
        for(let i = 0; i < keys.length; i++) {
            if (data.values.root[keys[i]].length === 0) {
                delete data.values.root[keys[i]];
            }
        }

        for (let i = 0; i < data.values.entities.length; i++) {
            if (data.values.entities[i] !== undefined) {
                keys = Object.keys(data.values.entities[i]);
                for(let j = 0; j < keys.length; j++) {
                    if (data.values.entities[i][keys[j]] === null) {
                        delete data.values.entities[i][keys[j]];
                    } else if (data.values.entities[i][keys[j]].length === 0) {
                        delete data.values.entities[i][keys[j]];
                    }
                }
            }
        }

        for (let i = 0; i < data.values.subjects.length; i++) {
            if (data.values.subjects[i] !== undefined) {
                keys = Object.keys(data.values.subjects[i]);
                for (let j = 0; j < keys.length; j++) {
                    if (data.values.subjects[i][keys[j]] === null) {
                        delete data.values.subjects[i][keys[j]];
                    } else if (data.values.subjects[i][keys[j]].length === 0) {
                        delete data.values.subjects[i][keys[j]];
                    }
                }
            }
        }

        for (let i = 0; i < data.values.files.length; i++) {
            keys = Object.keys(data.values.files[i]);
            for(let j = 0; j < keys.length; j++) {
                if (data.values.files[i][keys[j]] === null) {
                    delete data.values.files[i][keys[j]];
                } else if (data.values.files[i][keys[j]].length === 0) {
                    delete data.values.files[i][keys[j]];
                }
            }
        }
        /* END of delete empty fields */

        if (data.values.files.length > 1) {
            if (data.values.files[0].fileId === undefined) {
                data.values.files.shift();
                localStorage.setItem('SODNEDRAZBE.JSData',JSON.stringify(data));
            }
        }

        if (data.values.subjects.length >= 1) {
            let storageFiles;
            data.values.subjectFiles = [];
            for(let i = 0; i < data.values.subjects.length; i++) {
                if (data.values.subjects[i].viewAddress !== undefined) {
                    if (data.values.subjects[i].viewAddress.length === 0) {
                        delete data.values.subjects[i].viewAddress;
                    }
                }

                let tmpStorage = localStorage.getItem("SODNEDRAZBE.subjectFiles_"+i);
                if ((tmpStorage === null) || (tmpStorage === undefined)) {
                    storageFiles = [];
                } else {
                    storageFiles = JSON.parse(tmpStorage);
                    if (storageFiles.values.files[0] !== undefined) {
                        if (storageFiles.values.files[0].fileId === undefined) {
                            storageFiles = [];
                        } else {
                            storageFiles = storageFiles.values.files;
                        }
                    } else {
                        storageFiles = [];
                    }
                }
                data.values.subjectFiles.push(storageFiles);
            }
        }

        if (saveStatus === 'draft') {
            data['saveDraft'] = true;
        } else if (saveStatus === 'pending') {
            data['save'] = true;
        }

        /* workaround ker je bil edo len */
        if ((data.values.root.saleForm === "7bb966a3-ecb4-4df0-ba57-3ce6df44a019") && (data.values.root.saleMethod === "2640f02f-2318-41b5-ab70-1fb95e2514bc")) {
            data.values.root.isDynamicExtension = true;
            data.values.root.dynamicExtensionInterval = 2;
        }

        if (data.values.root.isResale === undefined) {
            data.values.root.isResale = false;
        }
        /* end of workaround ker je bil edo len */

        axios.post(env.api.private + 'publication', data, this.headers).then((response) => {
            data['saveDraft'] = false;
            data['save'] = false;
            let errorFields = null;
            if (response.status === 200) {
                data['id'] = response.data.id;

                if (saveStatus === 'draft') {
                    localStorage.setItem('SODNEDRAZBE.JSData',JSON.stringify(data));
                    if ((hideSwal === undefined) || (hideSwal === false)) {
                        Swal.fire(
                            {
                                title: t('saved'),
                                html: t('draft.alert.SaveToDraft'),
                                icon: 'success',
                                confirmButtonColor: '#1068ce',
                                confirmButtonText: t('doUnderstand')
                            }
                        ).then((result) => {
                            if (redirectAfterSave) {
                                this.props.history.push({pathname: '/subscriber/drafts/my-list'});
                            }
                        });
                    }
                } else if (saveStatus === 'pending') {
                    if (response.data.valid) {
                        Swal.fire(
                            {
                                title: t('Published'),
                                html: t('draft.alert.DraftIsPublished'),
                                icon: 'success',
                                confirmButtonColor: '#1068ce',
                                confirmButtonText: t('doUnderstand')
                            }
                        ).then((result) => {
                            if (redirectAfterSave) this.props.history.push({pathname: '/subscriber/published/my-list'});
                            this.justClearStorage();
                            this.reloadState();
                            if (response.data.id !== null) fillSearch(response.data.id, this.headers);
                        });
                    } else {
                        errorFields = this.errorStatus(response.data.fields, true);
                    }
                }
            }
            this.setState({errorFields: errorFields});
        }, (error) => {
            Swal.fire(
                {
                    title: t('error'),
                    html: t('draft.alert.ErrorSavingDraft'),
                    icon: 'error',
                    confirmButtonColor: '#1068ce',
                    confirmButtonText: t('doUnderstand')
                }
            );
        });
    }

    errorStatus = (fields, displaySwal) => {
        const {t} = this.props;
        let errorTxt = '';
        let errorFields = {
            root: [],
            subjects: {},
            files: {}
        };
        for (let i = 0; i < fields.root.length; i++) {
            if (!isNull(fields.root[i].error)) {
                errorFields.root.push(fields.root[i].id);
                errorTxt += '<br /><b>' + fields.root[i].title + '</b>: ' + fields.root[i].error.title;

                if (fields.root[i].id === "fileCheck") {
                    //console.log(fields.root[i]);
                    errorFields.files[100] = [fields.root[i].id];
                }
            }
        }

        if (fields.subjects !== null) {
            let ErrorTxtFilled = false;
            for (let i = 0; i < fields.subjects.length; i++) {
                for(let j = 0; j < fields.subjects[i].length; j++) {
                    if (!isNull(fields.subjects[i][j].error)) {
                        if (!ErrorTxtFilled) {
                            errorTxt += '<br /><b> '+t('draft.subject.item')+' ' + (i+1) + '</b>: '+t('draft.subject.error');
                            ErrorTxtFilled = true;
                        }
                        if (errorFields.subjects[i] === undefined) {
                            errorFields.subjects[i] = [];
                        }
                        errorFields.subjects[i].push(fields.subjects[i][j].id);
                    }
                }
            }
        }

        if (fields.files !== null) {
            let ErrorTxtFilled = false;
            for (let i = 0; i < fields.files.length; i++) {
                for(let j = 0; j < fields.files[i].length; j++) {
                    if (!isNull(fields.files[i][j].error)) {
                        if (!ErrorTxtFilled) {
                            errorTxt += '<br /><b> '+t('file')+' ' + (i+1) + ' - ' +  fields.files[i][j].title + '</b>: ' + fields.files[i][j].error.title;
                            ErrorTxtFilled = true;
                        }
                        if (errorFields.files[i] === undefined) {
                            errorFields.files[i] = [];
                        }
                        //console.log(fields.files[i][j]);
                        errorFields.files[i].push(fields.files[i][j].id);
                    }
                }
            }
        }

        if (displaySwal) {
            Swal.fire({
                title: t('draft.alert.DraftIncorrect'),
                html: errorTxt,
                icon: 'error',
                confirmButtonColor: '#1068ce',
                confirmButtonText: t('doUnderstand')
            })
        }

        return errorFields;
    }

    componentWillUnmount() {
        this.showLeavePrompt = false;
    }

    clearLocalStorageAndLeave = (t) => {
        this.justClearStorage();
        this.showLeavePrompt = false;
        this.clearState();
        this.props.history.push({pathname: '/'});
    }

    justClearStorage = () => {
        localStorage.removeItem('SODNEDRAZBE.JSData');

        for ( let i = 0; i < localStorage.length; i++ ) {
            let key = localStorage.key(i);
            if (key.startsWith("SODNEDRAZBE.subjectFiles_")) {
                localStorage.removeItem(key);
            }
        }
    }

    clearLocalStorage = (t, notify) => {
        this.justClearStorage();

        if (notify) {
            Swal.fire(
                {
                    title: t('Fields'),
                    html: t('draft.alert.FilledFieldsEmptied'),
                    icon: 'success',
                    confirmButtonColor: '#1068ce',
                    confirmButtonText: t('doUnderstand')
                }
            );
        }
        this.clearState();
        this.reloadState();
    }

    showNarokTab = (display) => {
        this.setState({showNarokTab: display});
    }

    clearState = (showLeavePrompt) => {
        this.setState({id: null, data: null, addresses: null, entities: null, files: null, storedFiles: null, showNarokTab: true, showSaleLocationTab: true, errorFields: null});
    }

    reloadState = () => {
        const JSData = localStorage.getItem('SODNEDRAZBE.JSData');
        let data = this.initialJSDataState; //get initial form

        if (isNull(JSData)) {
            localStorage.setItem('SODNEDRAZBE.JSData', JSON.stringify(data));
        } else {
            data = JSON.parse(JSData);
            let files = '';
            data.values.subjectFiles = [];
            for(let i = 0; i < data.values.subjects.length; i++) {
                data.values.subjectFiles[i] = [];
                files = JSON.parse(localStorage.getItem('SODNEDRAZBE.subjectFiles_'+i));
                if (files === null) {
                    data.values.subjectFiles[i].push({});
                } else {
                    data.values.subjectFiles[i] = files.values.files;
                }
            }
        }

        axios.post(env.api.private + 'publication', data, this.headers).then((response) => {
            if (response.status === 200) {
                this.transformReturnedValues(response);

                const errorFields = this.errorStatus(response.data.fields, false);
                this.setState({errorFields: errorFields});
            }
        }, (error) => {
            if (error.response.data.debug === "failed to create publication: denied publication modify in status deleted") {
                localStorage.setItem('SODNEDRAZBE.JSData',JSON.stringify(this.initialJSDataState));
                this.reloadState();
            } else {
                axiosErrorResponse(error);
            }
        });
    }

    transformReturnedValues = async (response) => {
        let mainResponseData = transformer(response.data.fields.root);

        /* show tabs */
        let showNarok = false;
        for(let i = 0; i < this.fieldsInTabs.narok.length; i++) {
            if (mainResponseData[this.fieldsInTabs.narok[i]].display) {
                showNarok = true;
            }
        }

        let showBail = false;
        for(let i = 0; i < this.fieldsInTabs.bail.length; i++) {
            if (mainResponseData[this.fieldsInTabs.bail[i]].display) {
                showBail = true;
            }
        }

        let showSaleLocation = false;
        for(let i = 0; i < this.fieldsInTabs.saleLocation.length; i++) {
            if (mainResponseData[this.fieldsInTabs.saleLocation[i]].display) {
                showSaleLocation = true;
            }
        }

        let showSpecialBail = false;

        for(let i = 0; i < this.fieldsInTabs.specialBail.length; i++) {
            if (mainResponseData[this.fieldsInTabs.specialBail[i]].display) {
                showSpecialBail = true;
            }
        }

        let Reload = false;

        if (showSaleLocation && (this.props.options.parsedToken.vashRoleId === 484) && (mainResponseData.saleTitle.value === null)) {
            let JSData = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));
            if ((this.state.data !== null) && (this.state.data.court !== null) && (this.state.data.court.value !== null)) {

                for (let i = 0; i < this.state.data.court.options.length; i++) {
                    if (this.state.data.court.options[i].value === this.state.data.court.value) {
                        let label = this.state.data.court.options[i].label;
                        if (env.is_development) {
                            label = label.substring(6);
                        }
                        const court = require("../../../hoc/Form/Input/json/court.json");

                        for (let j = 0; j < court.length; j++) {
                            if (court[j].title === label) {
                                const body = {
                                    "save": true,
                                    "values": {
                                        "street": court[j].street,
                                        "houseNumber": court[j].number,
                                        "zip": court[j].post,
                                        "city": getCity(court[j].post),
                                        "region": "",
                                        "country": "Slovenija"
                                    }
                                }
                                JSData.values.root.saleTitle = label;
                                await axios.post(env.api.private + 'address', body, this.headers).then((response) => {
                                    if (response.status === 200) {
                                        JSData.values.root.saleAddress = response.data.record.id;
                                    }
                                });
                                localStorage.setItem('SODNEDRAZBE.JSData', JSON.stringify(JSData));
                                Reload = true;
                            }
                        }
                        break;
                    }
                }
            }
        }

        if (showNarok && (this.props.options.parsedToken.vashRoleId === 484) && (mainResponseData.openBidTitle.value === null)) {
            let JSData = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));
            if ((this.state.data !== null) && (this.state.data.court !== null) && (this.state.data.court.value !== null)) {

                for (let i = 0; i < this.state.data.court.options.length; i++) {
                    if (this.state.data.court.options[i].value === this.state.data.court.value) {
                        JSData.values.root.openBidTitle = this.state.data.court.options[i].value;
                        JSData.values.root.openBidAddress = JSData.values.root.saleAddress;
                        localStorage.setItem('SODNEDRAZBE.JSData', JSON.stringify(JSData));
                        Reload = true;
                        break;
                    }
                }
            }
        }
        if (Reload) {
            this.reloadState();
            return;
        }
        /* end of show tabs */

        let subjects = [];
        if (!isNull(response.data.fields.subjects)) {
            for(let i = 0; i < response.data.fields.subjects.length; i++) {
                subjects.push(transformer(response.data.fields.subjects[i], i));
            }
        }

        let files = [];
        if (!isNull(response.data.fields.files)) {
            for (let i = 0; i < response.data.fields.files.length; i++) {
                files.push(transformer(response.data.fields.files[i]));
            }
        }

        let subjectFiles = [];
        if (!isNull(response.data.fields.subjectFiles)) {
            for (let i = 0; i < response.data.fields.subjectFiles.length; i++) {
                let tmpSubFiles = [];
                for (let j = 0; j < response.data.fields.subjectFiles[i].length; j++) {
                    tmpSubFiles.push(transformer(response.data.fields.subjectFiles[i][j]));
                }
                subjectFiles.push(tmpSubFiles);
            }
        }

        /* label */
        const {t} = this.props;
        let label = [t('single.label.registerType'), t('single.label.caseNumber'), t('single.label.caseYear')];
        if (mainResponseData['registerType'] !== undefined) {
            if (mainResponseData['registerType'].value !== null) {
                if ((mainResponseData['registerType'].options !== null) && (mainResponseData['registerType'].options !== undefined)) {
                    if (mainResponseData['registerType'].value.length === 0) {
                        label[0] = t('single.label.registerType');
                    } else {
                        for(let j = 0; j < mainResponseData['registerType'].options.length; j++) {
                            if (mainResponseData['registerType'].options[j].value === mainResponseData['registerType'].value) {
                                label[0] = mainResponseData['registerType'].options[j].label;
                            }
                        }
                    }
                }
            }
        }

        if ((mainResponseData['caseNumber'].value !== null) && (mainResponseData['caseNumber'].value.length !== 0)) {
            label[1] = mainResponseData['caseNumber'].value;
        }
        if ((mainResponseData['caseYear'].value !== null) && (mainResponseData['caseYear'].value.length !== 0)) {
            label[2] = mainResponseData['caseYear'].value;
        }

        this.setState({
            data: mainResponseData,
            subjects: subjects,
            files: files,
            subjectFiles: subjectFiles,
            storedFiles: isNull(response.data.values.files) ? [] : response.data.values.files,
            entities: isNull(response.data.fields.entities) ? [] : response.data.fields.entities,
            addresses: isNull(response.data.values.addresses) ? [] : response.data.values.addresses,
            showNarokTab: showNarok,
            showBailTab: showBail,
            showSpecialBail: showSpecialBail,
            showSaleLocation: showSaleLocation,
            label: label
        });
    }

    /* changes */
    selectChangeRootHandler = async (e) => {
        if (e.type !== undefined) {
            this.showLeavePrompt = true;
        }
        if ((e.target.name === 'court') && (this.props.options.parsedToken.vashRoleId === 484)) {
            const JSData = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));
            JSData.values.root.saleTitle = e.target.selectedOptions[0].label;
            if ((JSData.values.root.openBidTitle !== undefined) && (JSData.values.root.openBidTitle.length !== 0)) {
                JSData.values.root.openBidTitle = e.target.selectedOptions[0].value;
            }
            const court = require("../../../hoc/Form/Input/json/court.json");
            let search = e.target.selectedOptions[0].label;
            if (env.is_development) {
                search = search.substring(6);
            }
            const EventVar = {target: {value: e.target.value, name: e.target.name}};
            for (let i = 0; i < court.length; i++) {
                if (court[i].title === search) {
                    const body = {
                        "save": true,
                        "values": {
                            "street": court[i].street,
                            "houseNumber": court[i].number,
                            "zip": court[i].post,
                            "city": getCity(court[i].post),
                            "region": "",
                            "country": "Slovenija"
                        }
                    }
                    await axios.post(env.api.private + 'address', body, this.headers).then((response) => {
                        if (response.status === 200) {
                            JSData.values.root.saleAddress = response.data.record.id;
                            if ((JSData.values.root.openBidAddress !== undefined) && (JSData.values.root.openBidAddress.length !== 0)) {
                                JSData.values.root.openBidAddress = JSData.values.root.saleAddress;
                            }
                        }
                    });
                    localStorage.setItem('SODNEDRAZBE.JSData', JSON.stringify(JSData));
                    selectChangeRootHandler(EventVar, this.reloadState);
                    break;
                }
            }
        } else {
            selectChangeRootHandler(e, this.reloadState);
        }
    }

    textChangeRootHandler = (e, name) => {
        this.showLeavePrompt = true;
        let copyState = {...this.state.data};

        if (e instanceof moment) {  //only for year
            copyState[name].value = e.format('YYYY');
            this.setState({data: copyState});
            let data = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));
            data.values.root[name] = copyState[name].value;
            localStorage.setItem('SODNEDRAZBE.JSData', JSON.stringify(data));
            this.reloadState();
        } else if ((name === 'caseYear') && (e === null)) {
            let data = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));
            data.values.root[name] = '';
            localStorage.setItem('SODNEDRAZBE.JSData', JSON.stringify(data));
            this.reloadState();
        } else {
            textChangeRootHandler(e, this.reloadState);
        }
    }

    datetimeChangeRootHandler = (moment, name) => {
        this.showLeavePrompt = true;
        datetimeChangeRootHandler(moment, name, this.reloadState);
    }

    addressChangeRootHandler = (currentAddress, name, index) => {
        this.showLeavePrompt = true;
        addressChangeRootHandler(currentAddress, name, this.headers, this.reloadState, index);
    }

    /* subject changes */
    selectChangeSubjectHandler = (e, index) => {
        this.showLeavePrompt = true;
        selectChangeSubjectHandler(e, index, this.reloadState);
    }

    textChangeSubjectHandler = (e, index, name) => {
        this.showLeavePrompt = true;
        if (e instanceof moment) {  //only for year
            let data = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));

            if (data.values.subjects === undefined) {
                data.values.subjects = [];
            }
            if (data.values.subjects[index] === undefined) {
                data.values.subjects[index] = {};
            }

            data.values.subjects[index][name] = e.format('YYYY');
            localStorage.setItem('SODNEDRAZBE.JSData', JSON.stringify(data));
            this.reloadState();
        } else if ((name === 'constructionYear') && (e === null)) {
            let data = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));
            delete data.values.subjects[index][name];
            localStorage.setItem('SODNEDRAZBE.JSData', JSON.stringify(data));
            this.reloadState();
        } else {
            textChangeSubjectHandler(e, index, name);
            this.reloadState();
        }
    }

    datetimeChangeSubjectHandler = (moment, name, index) => {
        this.showLeavePrompt = true;
        datetimeChangeSubjectHandler(moment, name, index, this.reloadState);
    }

    /* files */
    selectChangeFilesHandler = (e, index) => {
        this.showLeavePrompt = true;
        selectChangeFilesHandler(e, index, this.reloadState);
    }

    textChangeFilesHandler = (e, index) => {
        this.showLeavePrompt = true;
        textChangeFilesHandler(e, index);
    }

    askToClearFields = () => {
        /* here is acctualy a bug but it works... if you call with update_id, here it will be old version */
        if (this.hash !== this.props.hash) {

            this.hash = this.props.hash;
            const loc = localStorage.getItem('SODNEDRAZBE.JSData');

            if (loc !== null) {
                const stringInitialState = JSON.stringify(this.initialJSDataState);
                if (loc !== stringInitialState) {
                    const { t } = this.props;
                    this.clearLocalStorage(t, false);
                }
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.id === undefined) {
            this.askToClearFields();
        }

        if ((this.props.initializedGA !== prevProps.initializedGA) && this.props.initializedGA) {
            this.reactGa();
        }

        // Publish draft from drafts list
        if ((this.props.location.state !== undefined) && (this.props.location.state !== null) && (this.props.location.state.publishDraft !== undefined) && (this.props.location.state.publishDraft)) {
            if (!this.state.draftPublished) {
                this.setState({draftPublished: true}, () => {
                    this.save('pending', true);
                });
            }
        }
    }

    setShowLeavePrompt = () => {
        this.showLeavePrompt = true;
    }

    render() {
        const { t } = this.props;

        /* Create head tabs */
        let array = [];
        array.push({ key: 2, title: t('draft.create.tab.Info'), tablink: '#info', errors: countErrors(this.fieldsInTabs.info, this.state.errorFields, 'root')});
        array.push({ key: 3, title: t('draft.create.tab.Debtors'), tablink: '#debtors', errors: countErrors(['entityCheck'], this.state.errorFields, 'root')});
        array.push({ key: 4, title: t('draft.create.tab.SaleInfo'), tablink: '#saleinfo', errors: countErrors(this.fieldsInTabs.saleInfo, this.state.errorFields, 'root')});
        if (this.state.showSaleLocation) {
            array.push({ key: 5, title: t('draft.create.tab.SaleLocation'), tablink: '#saleLocation', errors: countErrors(this.fieldsInTabs.saleLocation, this.state.errorFields, 'root')});
        }
        if (this.state.showNarokTab) {
            array.push({ key: 6, title: t('draft.create.tab.Narok'), tablink: '#narok', errors: countErrors(this.fieldsInTabs.narok, this.state.errorFields, 'root')});
        }
        array.push({ key: 7, title: t('draft.create.tab.Subjects'), tablink: '#subjects', errors: countGroupErrors(this.state.errorFields, 'subjects')});
        array.push({ key: 8, title: t('draft.create.tab.File'), tablink: '#file', errors: countGroupErrors(this.state.errorFields, 'files')});
        if (this.state.showBailTab) {
            array.push({ key: 9, title: t('draft.create.tab.Bail'), tablink: '#bail', errors: countErrors(this.fieldsInTabs.bail, this.state.errorFields, 'root')});
        }
        if (this.state.showSpecialBail) {
            array.push({ key: 10, title: t('draft.create.tab.specialBail'), tablink: '#specialbail', errors: countErrors(this.fieldsInTabs.specialBail, this.state.errorFields, 'root')});
        }
        array.push({ key: 1, title: t('draft.create.tab.Import'), tablink: '#import'});

        let headTabs = '';

        headTabs = array.map((tab, index) => {
            return (
                <Tab
                    class={((tab.key === 2) ? 'active': '')}
                    key={tab.key}
                    title={tab.title}
                    tablink={tab.tablink}
                    errors={tab.errors}
                    ref={tab.tablink === '#info' ? this.infoTabRef : ''}
                />
            );
        });
        /* END: Create head tabs */

        if (isNull(this.state.data) || isNull(this.state.addresses) || isNull(this.state.entities) || isNull(this.state.files)) {
            return (<Loading />);
        } else {
            return (
                <Fragment>
                    <Breadcrumb title={t('draft.title')} />

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <h4 className="card-title mb-5">{t('menu.draft.link.new')}</h4>
                                        </div>
                                        <div className="col-6 text-right">
                                            <h4 className="card-title mb-5"><b>{t('menu.draft.link.taskNumber')}: {this.state.label.join(" / ")}</b></h4>
                                        </div>
                                    </div>
                                    <ul className="nav nav-tabs" role="tablist">{headTabs}</ul>
                                    <div className="tab-content p-3 text-muted">
                                        <Import tabRef={this.infoTabRef} headers={this.headers} reloadState={this.reloadState} />
                                        <Info data={this.state.data} selectChangeRootHandler={this.selectChangeRootHandler} datetimeChangeRootHandler={this.datetimeChangeRootHandler} textChangeRootHandler={this.textChangeRootHandler} />
                                        <SaleInfo data={this.state.data} reloadState={this.reloadState} selectChangeRootHandler={this.selectChangeRootHandler} textChangeRootHandler={this.textChangeRootHandler} datetimeChangeRootHandler={this.datetimeChangeRootHandler} />
                                        <SaleLocation headers={this.headers} data={this.state.data} addressChangeRootHandler={this.addressChangeRootHandler} addresses={this.state.addresses} textChangeRootHandler={this.textChangeRootHandler} />
                                        <Narok headers={this.headers} addressChangeRootHandler={this.addressChangeRootHandler} data={this.state.data} addresses={this.state.addresses} selectChangeRootHandler={this.selectChangeRootHandler} datetimeChangeRootHandler={this.datetimeChangeRootHandler} textChangeRootHandler={this.textChangeRootHandler} />
                                        <Bail data={this.state.data} addresses={this.state.addresses} selectChangeRootHandler={this.selectChangeRootHandler} datetimeChangeRootHandler={this.datetimeChangeRootHandler} textChangeRootHandler={this.textChangeRootHandler} />
                                        <SpecailBail data={this.state.data} addresses={this.state.addresses} addressChangeRootHandler={this.addressChangeRootHandler} textChangeRootHandler={this.textChangeRootHandler} />
                                        <Debtors headers={this.headers} entities={this.state.entities} addresses={this.state.addresses} reloadState={this.reloadState} token={this.props.options.parsedToken} save={() => this.save('draft', false, true)}/>
                                        <SubjectList save={() => this.save('draft', false, true)}
                                                     headers={this.headers}
                                                     addresses={this.state.addresses}
                                                     /*subjectIndexToView={this.state.subjectIndexToView}*/
                                                     subjects={this.state.subjects}
                                                     subjectFiles={this.state.subjectFiles}
                                                     storedFiles={this.state.storedFiles}
                                                     selectChangeSubjectHandler={this.selectChangeSubjectHandler}
                                                     textChangeSubjectHandler={this.textChangeSubjectHandler}
                                                     addressChangeRootHandler={this.addressChangeRootHandler}
                                                     datetimeChangeSubjectHandler={this.datetimeChangeSubjectHandler}
                                                     bailFields={this.fieldsInTabs.bail}
                                                     reloadState={this.reloadState}
                                                     errors={this.state.errorFields}
                                                     saleType={this.state.data.saleType}/>
                                        <File headers={this.headers}
                                              files={this.state.files}
                                              selectChangeFilesHandler={this.selectChangeFilesHandler}
                                              textChangeFilesHandler={this.textChangeFilesHandler}
                                              reloadState={this.reloadState}
                                              storedFiles={this.state.storedFiles}
                                              showLeavePrompt={this.setShowLeavePrompt}/>
                                    </div>
                                    <hr />
                                    <div className="button_wrappers">
                                        <button disabled={this.props.loadingFiles} onClick={() => this.clearLocalStorageAndLeave(t)} className="btn btn-primary">{t('draft.create.btn.LeaveWithoutSave')}</button>
                                        <button disabled={this.props.loadingFiles} onClick={() => this.save('draft', false)} className="btn btn-primary">{t('draft.create.btn.SaveDraft')}</button>
                                        <button disabled={this.props.loadingFiles} onClick={() => this.save('draft', true)} className="btn btn-primary">{t('draft.create.btn.SaveDraftAndLeave')}</button>
                                        <button disabled={this.props.loadingFiles} onClick={() => this.save('pending', true)} className="btn btn-primary">{t('draft.create.btn.Publish')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        }

    }
}

const mapStateToProps = state => {
    return {
        options: state.options,
        initializedGA: state.initializedGA,
        loadingFiles: state.loadingFiles,
    }
}

export default connect(mapStateToProps)(withRouter(withTranslation()(Create2)));
