import React from 'react';
import {getAddress} from "../../../../../izUtils";
import i18n from '../../../../../i18n';

const reformat = (entitie) => {
    let array = {};
    for(let i = 0; i < entitie.length; i++) {
        array[entitie[i].id] = entitie[i].value;
    }

    return array;
}

const debtor = (props) => {
    if (props.entities !== null) {
        let array = props.entities.map((entitie, index) => {
            entitie = reformat(entitie);
            if ((entitie.type === props.type) && (entitie.person === props.person)) {
                const address = getAddress(props.adresses, entitie.addressId);

                if (props.person === "legal") {
                    return (
                        <tr key={"legal_" + index} data-id={index}>
                            <td style={{width: "80px"}}>
                                <i className="mdi mdi-trash-can-outline" onClick={() => props.clickDelete(index)} title={i18n.t('delete')} />&nbsp;
                                <i className="mdi mdi-file-edit-outline" onClick={() => props.clickEdit(index)} title={i18n.t('edit')} />
                            </td>
                            <td onClick={() => props.clickEdit(index)}>{entitie.company}</td>
                            <td onClick={() => props.clickEdit(index)}>{address}</td>
                        </tr>
                    )
                } else {
                    return (
                        <tr key={"natural_" + index} data-id={index}>
                            <td style={{width: "80px"}}>
                                <i className="mdi mdi-trash-can-outline" onClick={() => props.clickDelete(index)} title={i18n.t('delete')} />&nbsp;
                                <i className="mdi mdi-file-edit-outline" onClick={() => props.clickEdit(index)} title={i18n.t('edit')} />
                            </td>
                            <td onClick={() => props.clickEdit(index)}>{entitie.lastName}</td>
                            <td onClick={() => props.clickEdit(index)}>{entitie.firstName}</td>
                            <td onClick={() => props.clickEdit(index)}>{address}</td>
                        </tr>
                    )
                }
            }

            return null;
        });
        return array;
    } else {
        return null;
    }

};

export default debtor;
