export const countries = () => {
    return [
        {value: 'Afganistan', label: 'Afganistan' },
        {value: 'Ålandsko otočje', label: 'Ålandsko otočje'},
        {value: 'Albanija', label: 'Albanija'},
        {value: 'Alžirija', label: 'Alžirija'},
        {value: 'Ameriška Samoa', label: 'Ameriška Samoa'},
        {value: 'Andora', label: 'Andora'},
        {value: 'Angola', label: 'Angola'},
        {value: 'Angvila', label: 'Angvila'},
        {value: 'Antarktika', label: 'Antarktika'},
        {value: 'Antigva in Barbuda', label: 'Antigva in Barbuda'},
        {value: 'Argentina', label: 'Argentina'},
        {value: 'Armenija', label: 'Armenija'},
        {value: 'Aruba', label: 'Aruba'},
        {value: 'Avstralija', label: 'Avstralija'},
        {value: 'Avstrija', label: 'Avstrija'},
        {value: 'Azerbajdžan', label: 'Azerbajdžan'},
        {value: 'Bahami', label: 'Bahami'},
        {value: 'Bahrajn', label: 'Bahrajn'},
        {value: 'Bangladeš', label: 'Bangladeš'},
        {value: 'Barbados', label: 'Barbados'},
        {value: 'Belgija', label: 'Belgija'},
        {value: 'Belize', label: 'Belize'},
        {value: 'Belorusija', label: 'Belorusija'},
        {value: 'Benin', label: 'Benin'},
        {value: 'Bermudi', label: 'Bermudi'},
        {value: 'Bocvana', label: 'Bocvana'},
        {value: 'Bolgarija', label: 'Bolgarija'},
        {value: 'Bolivija', label: 'Bolivija'},
        {value: 'Bosna in Hercegovina', label: 'Bosna in Hercegovina'},
        {value: 'Božični otoki', label: 'Božični otoki'},
        {value: 'Brazilija', label: 'Brazilija'},
        {value: 'Britanski Deviški otoki', label: 'Britanski Deviški otoki'},
        {value: 'Britanski teritorij v Indijskem oceanu', label: 'Britanski teritorij v Indijskem oceanu'},
        {value: 'Brunej', label: 'Brunej'},
        {value: 'Burkina Faso', label: 'Burkina Faso'},
        {value: 'Burundi', label: 'Burundi'},
        {value: 'Butan', label: 'Butan'},
        {value: 'Ciper', label: 'Ciper'},
        {value: 'Cookovo otočje', label: 'Cookovo otočje'},
        {value: 'Čad', label: 'Čad'},
        {value: 'Češka', label: 'Češka'},
        {value: 'Čile', label: 'Čile'},
        {value: 'Danska', label: 'Danska'},
        {value: 'Demokratična republika Kongo', label: 'Demokratična republika Kongo'},
        {value: 'Deviški otoki', label: 'Deviški otoki'},
        {value: 'Dominika', label: 'Dominika'},
        {value: 'Dominikanska republika', label: 'Dominikanska republika'},
        {value: 'Džibuti', label: 'Džibuti'},
        {value: 'Egipt', label: 'Egipt'},
        {value: 'Ekvador', label: 'Ekvador'},
        {value: 'Ekvatorialna Gvineja', label: 'Ekvatorialna Gvineja'},
        {value: 'Eritreja', label: 'Eritreja'},
        {value: 'Estonija', label: 'Estonija'},
        {value: 'Etiopija', label: 'Etiopija'},
        {value: 'Falklandi', label: 'Falklandi'},
        {value: 'Ferski otoki', label: 'Ferski otoki'},
        {value: 'Fidži', label: 'Fidži'},
        {value: 'Filipini', label: 'Filipini'},
        {value: 'Finska', label: 'Finska'},
        {value: 'Francija', label: 'Francija'},
        {value: 'Francoska Gvajana', label: 'Francoska Gvajana'},
        {value: 'Francoska južna ozemlja', label: 'Francoska južna ozemlja'},
        {value: 'Francoska Polinezija', label: 'Francoska Polinezija'},
        {value: 'Gabon', label: 'Gabon'},
        {value: 'Gambija', label: 'Gambija'},
        {value: 'Gana', label: 'Gana'},
        {value: 'Gibraltar', label: 'Gibraltar'},
        {value: 'Grčija', label: 'Grčija'},
        {value: 'Grenada', label: 'Grenada'},
        {value: 'Grenlandija', label: 'Grenlandija'},
        {value: 'Gruzija', label: 'Gruzija'},
        {value: 'Guadeloupe', label: 'Guadeloupe'},
        {value: 'Guam', label: 'Guam'},
        {value: 'Gvajana', label: 'Gvajana'},
        {value: 'Gvatemala', label: 'Gvatemala'},
        {value: 'Gvineja', label: 'Gvineja'},
        {value: 'Gvineja Bissau', label: 'Gvineja Bissau'},
        {value: 'Haiti', label: 'Haiti'},
        {value: 'Honduras', label: 'Honduras'},
        {value: 'Hong Kong', label: 'Hong Kong'},
        {value: 'Hrvaška', label: 'Hrvaška'},
        {value: 'Indija', label: 'Indija'},
        {value: 'Indonezija', label: 'Indonezija'},
        {value: 'Irak', label: 'Irak'},
        {value: 'Iran', label: 'Iran'},
        {value: 'Irska', label: 'Irska'},
        {value: 'Islandija', label: 'Islandija'},
        {value: 'Italija', label: 'Italija'},
        {value: 'Izrael', label: 'Izrael'},
        {value: 'Jamajka', label: 'Jamajka'},
        {value: 'Japonska', label: 'Japonska'},
        {value: 'Jemen', label: 'Jemen'},
        {value: 'Jordanija', label: 'Jordanija'},
        {value: 'Južna Afrika', label: 'Južna Afrika'},
        {value: 'Južna Koreja', label: 'Južna Koreja'},
        {value: 'Kajmanski otoki', label: 'Kajmanski otoki'},
        {value: 'Kambodža', label: 'Kambodža'},
        {value: 'Kamerun', label: 'Kamerun'},
        {value: 'Kanada', label: 'Kanada'},
        {value: 'Katar', label: 'Katar'},
        {value: 'Kazahstan', label: 'Kazahstan'},
        {value: 'Kenija', label: 'Kenija'},
        {value: 'Kirgizistan', label: 'Kirgizistan'},
        {value: 'Kiribati', label: 'Kiribati'},
        {value: 'Kitajska', label: 'Kitajska'},
        {value: 'Kokosovi otoki', label: 'Kokosovi otoki'},
        {value: 'Kolumbija', label: 'Kolumbija'},
        {value: 'Komori', label: 'Komori'},
        {value: 'Kongo', label: 'Kongo'},
        {value: 'Kostarika', label: 'Kostarika'},
        {value: 'Kuba', label: 'Kuba'},
        {value: 'Kuvajt', label: 'Kuvajt'},
        {value: 'Laos', label: 'Laos'},
        {value: 'Latvija', label: 'Latvija'},
        {value: 'Lesoto', label: 'Lesoto'},
        {value: 'Libanon', label: 'Libanon'},
        {value: 'Liberija', label: 'Liberija'},
        {value: 'Libija', label: 'Libija'},
        {value: 'Lihtenštajn', label: 'Lihtenštajn'},
        {value: 'Litva', label: 'Litva'},
        {value: 'Luksemburg', label: 'Luksemburg'},
        {value: 'Macao', label: 'Macao'},
        {value: 'Madagaskar', label: 'Madagaskar'},
        {value: 'Madžarska', label: 'Madžarska'},
        {value: 'Makedonija', label: 'Makedonija'},
        {value: 'Malavi', label: 'Malavi'},
        {value: 'Maldivi', label: 'Maldivi'},
        {value: 'Malezija', label: 'Malezija'},
        {value: 'Mali', label: 'Mali'},
        {value: 'Malta', label: 'Malta'},
        {value: 'Maroko', label: 'Maroko'},
        {value: 'Marshallovi otoki', label: 'Marshallovi otoki'},
        {value: 'Martinique', label: 'Martinique'},
        {value: 'Mauritius', label: 'Mauritius'},
        {value: 'Mavretanija', label: 'Mavretanija'},
        {value: 'Mayotte', label: 'Mayotte'},
        {value: 'Mehika', label: 'Mehika'},
        {value: 'Mikronezija', label: 'Mikronezija'},
        {value: 'Mjanmar', label: 'Mjanmar'},
        {value: 'Moldavija', label: 'Moldavija'},
        {value: 'Monako', label: 'Monako'},
        {value: 'Mongolija', label: 'Mongolija'},
        {value: 'Montserrat', label: 'Montserrat'},
        {value: 'Mozambik', label: 'Mozambik'},
        {value: 'Namibija', label: 'Namibija'},
        {value: 'Nauru', label: 'Nauru'},
        {value: 'Nemčija', label: 'Nemčija'},
        {value: 'Nepal', label: 'Nepal'},
        {value: 'Niger', label: 'Niger'},
        {value: 'Nigerija', label: 'Nigerija'},
        {value: 'Nikaragva', label: 'Nikaragva'},
        {value: 'Niue', label: 'Niue'},
        {value: 'Nizozemska', label: 'Nizozemska'},
        {value: 'Nizozemski Antili', label: 'Nizozemski Antili'},
        {value: 'Norveška', label: 'Norveška'},
        {value: 'Nova Kaledonija', label: 'Nova Kaledonija'},
        {value: 'Nova Zelandija', label: 'Nova Zelandija'},
        {value: 'Oman', label: 'Oman'},
        {value: 'Otok Norfolk', label: 'Otok Norfolk'},
        {value: 'Bouvetovo otočje', label: 'Bouvetovo otočje'},
        {value: 'Heardov otok in McDonaldovo otočje', label: 'Heardov otok in McDonaldovo otočje'},
        {value: 'Južna Georgia in Južni Sandwichevi otoki', label: 'Južna Georgia in Južni Sandwichevi otoki'},
        {value: 'Pakistan', label: 'Pakistan'},
        {value: 'Palau', label: 'Palau'},
        {value: 'Palestina', label: 'Palestina'},
        {value: 'Panama', label: 'Panama'},
        {value: 'Papua Nova Gvineja', label: 'Papua Nova Gvineja'},
        {value: 'Paragvaj', label: 'Paragvaj'},
        {value: 'Peru', label: 'Peru'},
        {value: 'Pitcairnov otok', label: 'Pitcairnov otok'},
        {value: 'Poljska', label: 'Poljska'},
        {value: 'Portoriko', label: 'Portoriko'},
        {value: 'Portugalska', label: 'Portugalska'},
        {value: 'Reunion', label: 'Reunion'},
        {value: 'Romunija', label: 'Romunija'},
        {value: 'Ruanda', label: 'Ruanda'},
        {value: 'Rusija', label: 'Rusija'},
        {value: 'Saint Helena', label: 'Saint Helena'},
        {value: 'Saint Kitts in Nevis', label: 'Saint Kitts in Nevis'},
        {value: 'Saint Vincent in Grenadine', label: 'Saint Vincent in Grenadine'},
        {value: 'Saint Pierre in Miquelon', label: 'Saint Pierre in Miquelon'},
        {value: 'Salomonovi otoki', label: 'Salomonovi otoki'},
        {value: 'Salvador', label: 'Salvador'},
        {value: 'San Marino', label: 'San Marino'},
        {value: 'Sao Tome in Principe', label: 'Sao Tome in Principe'},
        {value: 'Saudova Arabija', label: 'Saudova Arabija'},
        {value: 'Sejšeli', label: 'Sejšeli'},
        {value: 'Senegal', label: 'Senegal'},
        {value: 'Severna Koreja', label: 'Severna Koreja'},
        {value: 'Severni Marianski otoki', label: 'Severni Marianski otoki'},
        {value: 'Sierra Leone', label: 'Sierra Leone'},
        {value: 'Singapur', label: 'Singapur'},
        {value: 'Sirija', label: 'Sirija'},
        {value: 'Slonokoščena obala', label: 'Slonokoščena obala'},
        {value: 'Slovaška', label: 'Slovaška'},
        {value: 'Slovenija', label: 'Slovenija'},
        {value: 'Somalija', label: 'Somalija'},
        {value: 'Srbija', label: 'Srbija'},
        {value: 'Srednjeafriška republika', label: 'Srednjeafriška republika'},
        {value: 'Sudan', label: 'Sudan'},
        {value: 'Surinam', label: 'Surinam'},
        {value: 'Svalbard in Jan Mayen', label: 'Svalbard in Jan Mayen'},
        {value: 'Svazi', label: 'Svazi'},
        {value: 'Sveta Lucija', label: 'Sveta Lucija'},
        {value: 'Španija', label: 'Španija'},
        {value: 'Šrilanka', label: 'Šrilanka'},
        {value: 'Švedska', label: 'Švedska'},
        {value: 'Švica', label: 'Švica'},
        {value: 'Tadžikistan', label: 'Tadžikistan'},
        {value: 'Tajska', label: 'Tajska'},
        {value: 'Tajvan', label: 'Tajvan'},
        {value: 'Tanzanija', label: 'Tanzanija'},
        {value: 'Togo', label: 'Togo'},
        {value: 'Tokelau', label: 'Tokelau'},
        {value: 'Tonga', label: 'Tonga'},
        {value: 'Trinidad in Tobago', label: 'Trinidad in Tobago'},
        {value: 'Tunizija', label: 'Tunizija'},
        {value: 'Turčija', label: 'Turčija'},
        {value: 'Turkmenistan', label: 'Turkmenistan'},
        {value: 'Otočji Turks in Caicos', label: 'Otočji Turks in Caicos'},
        {value: 'Tuvalu', label: 'Tuvalu'},
        {value: 'Uganda', label: 'Uganda'},
        {value: 'Ukrajina', label: 'Ukrajina'},
        {value: 'Urugvaj', label: 'Urugvaj'},
        {value: 'Uzbekistan', label: 'Uzbekistan'},
        {value: 'Vanuatu', label: 'Vanuatu'},
        {value: 'Vatikan', label: 'Vatikan'},
        {value: 'Velika Britanija', label: 'Velika Britanija'},
        {value: 'Venezuela', label: 'Venezuela'},
        {value: 'Vietnam', label: 'Vietnam'},
        {value: 'Vzhodni Timor', label: 'Vzhodni Timor'},
        {value: 'Wallis in Futuna', label: 'Wallis in Futuna'},
        {value: 'Zahodna Sahara', label: 'Zahodna Sahara'},
        {value: 'Zahodna Samoa', label: 'Zahodna Samoa'},
        {value: 'Zambija', label: 'Zambija'},
        {value: 'Združene države Amerike', label: 'Združene države Amerike'},
        {value: 'Združeni arabski emirati', label: 'Združeni arabski emirati'},
        {value: 'Zelenortski otoki', label: 'Zelenortski otoki'},
        {value: 'Zimbabve', label: 'Zimbabve'}
    ]
}

function post() {
    return {
        1000: "Ljubljana",
        1001: "Ljubljana – P.P.",
        1210: "Ljubljana – Šentvid",
        1211: "Ljubljana – Šmartno",
        1215: "Medvode",
        1216: "Smlednik",
        1217: "Vodice",
        1218: "Komenda",
        1219: "Laze v Tuhinju",
        1221: "Motnik",
        1222: "Trojane",
        1223: "Blagovica",
        1225: "Lukovica",
        1230: "Domžale",
        1231: "Ljubljana – Črnuče",
        1233: "Dob",
        1234: "Mengeš",
        1235: "Radomlje",
        1236: "Trzin",
        1241: "Kamnik",
        1242: "Stahovica",
        1251: "Moravče",
        1252: "Vače",
        1260: "Ljubljana – Polje",
        1261: "Ljubljana – Dobrunje",
        1262: "Dol pri Ljubljani",
        1270: "Litija",
        1272: "Polšnik",
        1273: "Dole pri Litiji",
        1274: "Gabrovka",
        1275: "Šmartno pri Litiji",
        1276: "Primskovo",
        1281: "Kresnice",
        1282: "Sava",
        1290: "Grosuplje",
        1291: "Škofljica",
        1292: "Ig",
        1293: "Šmarje – Sap",
        1294: "Višnja Gora",
        1295: "Ivančna Gorica",
        1296: "Šentvid pri Stični",
        1301: "Krka",
        1303: "Zagradec",
        1310: "Ribnica",
        1311: "Turjak",
        1312: "Videm – Dobrepolje",
        1313: "Struge",
        1314: "Rob",
        1315: "Velike Lašče",
        1316: "Ortnek",
        1317: "Sodražica",
        1318: "Loški Potok",
        1319: "Draga",
        1330: "Kočevje",
        1331: "Dolenja vas",
        1332: "Stara Cerkev",
        1336: "Kostel",
        1337: "Osilnica",
        1338: "Kočevska Reka",
        1351: "Brezovica pri Ljubljani",
        1352: "Preserje",
        1353: "Borovnica",
        1354: "Horjul",
        1355: "Polhov Gradec",
        1356: "Dobrova",
        1357: "Notranje Gorice",
        1358: "Log pri Brezovici",
        1360: "Vrhnika",
        1370: "Logatec",
        1371: "Logatec",
        1372: "Hotedršica",
        1373: "Rovte",
        1380: "Cerknica",
        1381: "Rakek",
        1382: "Begunje pri Cerknici",
        1384: "Grahovo",
        1385: "Nova vas",
        1386: "Stari trg pri Ložu",
        1410: "Zagorje ob Savi",
        1411: "Izlake",
        1412: "Kisovec",
        1413: "Čemšenik",
        1414: "Podkum",
        1420: "Trbovlje",
        1423: "Dobovec",
        1430: "Hrastnik",
        1431: "Dol pri Hrastniku",
        1432: "Zidani Most",
        1433: "Radeče",
        1434: "Loka pri Zidanem Mostu",
        2000: "Maribor",
        2001: "Maribor – P.P.",
        2201: "Zgornja Kungota",
        2204: "Miklavž na Dravskem polju",
        2205: "Starše",
        2206: "Marjeta na Dravskem polju",
        2208: "Pohorje",
        2211: "Pesnica pri Mariboru",
        2212: "Šentilj v Slovenskih goricah",
        2213: "Zgornja Velka",
        2214: "Sladki Vrh",
        2215: "Ceršak",
        2221: "Jarenina",
        2222: "Jakobski Dol",
        2223: "Jurovski Dol",
        2229: "Malečnik",
        2230: "Lenart v Slovenskih goricah",
        2231: "Pernica",
        2232: "Voličina",
        2233: "Sv. Ana v Slovenskih goricah",
        2234: "Benedikt",
        2235: "Sv. Trojica v Slovenskih goricah",
        2236: "Cerkvenjak",
        2241: "Spodnji Duplek",
        2242: "Zgornja Korena",
        2250: "Ptuj",
        2252: "Dornava",
        2253: "Destrnik",
        2254: "Trnovska vas",
        2255: "Vitomarci",
        2256: "Juršinc",
        2257: "Polenšak",
        2258: "Sveti Tomaž",
        2259: "Ivanjkovci",
        2270: "Ormož",
        2272: "Gorišnica",
        2273: "Podgorci",
        2274: "Velika Nedelja",
        2275: "Miklavž pri Ormožu",
        2276: "Kog",
        2277: "Središče ob Dravi",
        2281: "Markovci",
        2282: "Cirkulane",
        2283: "Zavrč",
        2284: "Videm pri Ptuju",
        2285: "Zgornji Leskovec",
        2286: "Podlehnik",
        2287: "Žetale",
        2288: "Hajdina",
        2289: "Stoperce",
        2310: "Slovenska Bistrica",
        2311: "Hoče",
        2312: "Orehova vas",
        2313: "Fram",
        2314: "Zgornja Polskava",
        2315: "Šmartno na Pohorju",
        2316: "Zgornja Ložnica",
        2317: "Oplotnica",
        2318: "Laporje",
        2319: "Poljčane",
        2321: "Makole",
        2322: "Majšperk",
        2323: "Ptujska Gora",
        2324: "Lovrenc na Dravskem polju",
        2325: "Kidričevo",
        2326: "Cirkovce",
        2327: "Rače",
        2331: "Pragersko",
        2341: "Limbuš",
        2342: "Ruše",
        2343: "Fala",
        2344: "Lovrenc na Pohorju",
        2345: "Bistrica ob Dravi",
        2351: "Kamnica",
        2352: "Selnica ob Dravi",
        2353: "Sveti Duh na Ostrem Vrhu",
        2354: "Bresternica",
        2360: "Radlje ob Dravi",
        2361: "Ožbalt",
        2362: "Kapla",
        2363: "Podvelka",
        2364: "Ribnica na Pohorju",
        2365: "Vuhred",
        2366: "Muta",
        2367: "Vuzenica",
        2370: "Dravograd",
        2371: "Trbonje",
        2372: "Libeliče",
        2373: "Šentjanž pri Dravogradu",
        2380: "Slovenj Gradec",
        2381: "Podgorje pri Slovenj Gradcu",
        2382: "Mislinja",
        2383: "Šmartno pri Slovenj Gradcu",
        2390: "Ravne na Koroškem",
        2391: "Prevalje",
        2392: "Mežica",
        2393: "Črna na Koroškem",
        2394: "Kotlje",
        3000: "Celje",
        3001: "Celje – P.P.",
        3201: "Šmartno v Rožni dolini",
        3202: "Ljubečna",
        3203: "Nova Cerkev",
        3204: "Dobrna",
        3205: "Vitanje",
        3206: "Stranice",
        3210: "Slovenske Konjice",
        3211: "Škofja vas",
        3212: "Vojnik",
        3213: "Frankolovo",
        3214: "Zreče",
        3215: "Loče",
        3220: "Štore",
        3221: "Teharje",
        3222: "Dramlje",
        3223: "Loka pri Žusmu",
        3224: "Dobje pri Planini",
        3225: "Planina pri Sevnici",
        3230: "Šentjur",
        3231: "Grobelno",
        3232: "Ponikva",
        3233: "Kalobje",
        3240: "Šmarje pri Jelšah",
        3241: "Podplat",
        3250: "Rogaška Slatina",
        3252: "Rogatec",
        3253: "Pristava pri Mestinju",
        3254: "Podčetrtek",
        3255: "Buče",
        3256: "Bistrica ob Sotli",
        3257: "Podsreda",
        3260: "Kozje",
        3261: "Lesično",
        3262: "Prevorje",
        3263: "Gorica pri Slivnici",
        3264: "Sveti Štefan",
        3270: "Laško",
        3271: "Šentrupert",
        3272: "Rimske Toplice",
        3273: "Jurklošter",
        3301: "Petrovče",
        3302: "Griže",
        3303: "Gomilsko",
        3304: "Tabor",
        3305: "Vransko",
        3310: "Žalec",
        3311: "Šempeter v Savinjski dolini",
        3312: "Prebold",
        3313: "Polzela",
        3314: "Braslovče",
        3320: "Velenje",
        3322: "Velenje – P.P.",
        3325: "Šoštanj",
        3326: "Topolšica",
        3327: "Šmartno ob Paki",
        3330: "Mozirje",
        3331: "Nazarje",
        3332: "Rečica ob Savinji",
        3333: "Ljubno ob Savinji",
        3334: "Luče",
        3335: "Solčava",
        3341: "Šmartno ob Dreti",
        3342: "Gornji Grad",
        4000: "Kranj",
        4001: "Kranj – P.P.",
        4201: "Zgornja Besnica",
        4202: "Naklo",
        4203: "Duplje",
        4204: "Golnik",
        4205: "Preddvor",
        4206: "Zgornje Jezersko",
        4207: "Cerklje na Gorenjskem",
        4208: "Šenčur",
        4209: "Žabnica",
        4210: "Brnik – Aerodrom",
        4211: "Mavčiče",
        4212: "Visoko",
        4220: "Škofja Loka",
        4223: "Poljane nad Škofjo Loko",
        4224: "Gorenja vas",
        4225: "Sovodenj",
        4226: "Žiri",
        4227: "Selca",
        4228: "Železniki",
        4229: "Sorica",
        4240: "Radovljica",
        4243: "Brezje",
        4244: "Podnart",
        4245: "Kropa",
        4246: "Kamna Gorica",
        4247: "Zgornje Gorje",
        4248: "Lesce",
        4260: "Bled",
        4263: "Bohinjska Bela",
        4264: "Bohinjska Bistrica",
        4265: "Bohinjsko jezero",
        4267: "Srednja vas v Bohinju",
        4270: "Jesenice",
        4273: "Blejska Dobrava",
        4274: "Žirovnica",
        4275: "Begunje na Gorenjskem",
        4276: "Hrušica",
        4280: "Kranjska Gora",
        4281: "Mojstrana",
        4282: "Gozd Martuljek",
        4283: "Rateče – Planica",
        4290: "Tržič",
        4294: "Križe",
        5000: "Nova Gorica",
        5001: "Nova Gorica – P.P.",
        5210: "Deskle",
        5211: "Kojsko",
        5212: "Dobrovo v Brdih",
        5213: "Kanal",
        5214: "Kal nad Kanalom",
        5215: "Ročinj",
        5216: "Most na Soči",
        5220: "Tolmin",
        5222: "Kobarid",
        5223: "Breginj",
        5224: "Srpenica",
        5230: "Bovec",
        5231: "Log pod Mangartom",
        5232: "Soča",
        5242: "Grahovo ob Bači",
        5243: "Podbrdo",
        5250: "Solkan",
        5251: "Grgar",
        5252: "Trnovo pri Gorici",
        5253: "Čepovan",
        5261: "Šempas",
        5262: "Črniče",
        5263: "Dobravlje",
        5270: "Ajdovščina",
        5271: "Vipava",
        5272: "Podnanos",
        5273: "Col",
        5274: "Črni Vrh nad Idrijo",
        5275: "Godovič",
        5280: "Idrija",
        5281: "Spodnja Idrija",
        5282: "Cerkno",
        5283: "Slap ob Idrijci",
        5290: "Šempeter pri Gorici",
        5291: "Miren",
        5292: "Renče",
        5293: "Volčja Draga",
        5294: "Dornberk",
        5295: "Branik",
        5296: "Kostanjevica na Krasu",
        5297: "Prvačina",
        6000: "Koper",
        6001: "Koper - P.P.",
        6210: "Sežana",
        6215: "Divača",
        6216: "Podgorje",
        6217: "Vremski Britof",
        6219: "Lokev",
        6221: "Dutovlje",
        6222: "Štanjel",
        6223: "Komen",
        6224: "Senožeče",
        6225: "Hruševje",
        6230: "Postojna",
        6232: "Planina",
        6240: "Kozina",
        6242: "Materija",
        6243: "Obrov",
        6244: "Podgrad",
        6250: "Ilirska Bistrica",
        6251: "Ilirska Bistrica-Trnovo",
        6253: "Knežak",
        6254: "Jelšane",
        6255: "Prem",
        6256: "Košana",
        6257: "Pivka",
        6258: "Prestranek",
        6271: "Dekani",
        6272: "Gračišče",
        6273: "Marezige",
        6274: "Šmarje",
        6275: "Črni Kal",
        6276: "Pobegi",
        6280: "Ankaran",
        6281: "Škofije",
        6310: "Izola",
        6320: "Portorož",
        6330: "Piran",
        6333: "Sečovlje",
        8000: "Novo mesto",
        8001: "Novo mesto – P.P.",
        8210: "Trebnje",
        8211: "Dobrnič",
        8212: "Velika Loka",
        8213: "Veliki Gaber",
        8216: "Mirna Peč",
        8220: "Šmarješke Toplice",
        8222: "Otočec",
        8230: "Mokronog",
        8231: "Trebelno",
        8232: "Šentrupert",
        8233: "Mirna",
        8250: "Brežice",
        8251: "Čatež ob Savi",
        8253: "Artiče",
        8254: "Globoko",
        8255: "Pišece",
        8256: "Sromlje",
        8257: "Dobova",
        8258: "Kapele",
        8259: "Bizeljsko",
        8261: "Jesenice na Dolenjskem",
        8262: "Krška vas",
        8263: "Cerklje ob Krki",
        8270: "Krško",
        8272: "Zdole",
        8273: "Leskovec pri Krškem",
        8274: "Raka",
        8275: "Škocjan",
        8276: "Bučka",
        8280: "Brestanica",
        8281: "Senovo",
        8282: "Koprivnica",
        8283: "Blanca",
        8290: "Sevnica",
        8292: "Zabukovje",
        8293: "Studenec",
        8294: "Boštanj",
        8295: "Tržišče",
        8296: "Krmelj",
        8297: "Šentjanž",
        8310: "Šentjernej",
        8311: "Kostanjevica na Krki",
        8312: "Podbočje",
        8321: "Brusnice",
        8322: "Stopiče",
        8323: "Uršna sela",
        8330: "Metlika",
        8331: "Suhor",
        8332: "Gradac",
        8333: "Semič",
        8340: "Črnomelj",
        8341: "Adlešiči",
        8342: "Stari trg ob Kolpi",
        8343: "Dragatuš",
        8344: "Vinica",
        8350: "Dolenjske Toplice",
        8351: "Straža",
        8360: "Žužemberk",
        8361: "Dvor",
        8362: "Hinje",
        9000: "Murska Sobota",
        9001: "Murska Sobota – P.P.",
        9201: "Puconci",
        9202: "Mačkovci",
        9203: "Petrovci",
        9204: "Šalovci",
        9205: "Hodoš/Hodos",
        9206: "Križevci",
        9207: "Prosenjakovci/Partosfalva",
        9208: "Fokovci",
        9220: "Lendava/Lendva",
        9221: "Martjanci",
        9222: "Bogojina",
        9223: "Dobrovnik/Dobronak",
        9224: "Turnišče",
        9225: "Velika Polana",
        9226: "Moravske Toplice",
        9227: "Kobilje",
        9231: "Beltinci",
        9232: "Črenšovci",
        9233: "Odranci",
        9240: "Ljutomer",
        9241: "Veržej",
        9242: "Križevci pri Ljutomeru",
        9243: "Mala Nedelja",
        9244: "Sveti Jurij ob Ščavnici",
        9245: "Spodnji Ivanjci",
        9250: "Gornja Radgona",
        9251: "Tišina",
        9252: "Radenci",
        9253: "Apače",
        9261: "Cankova",
        9262: "Rogašovci",
        9263: "Kuzma",
        9264: "Grad",
        9265: "Bodonci"
    }
}

export const zipNumbers = () => {
    const postNumbers = post();
    let returnValue = [];
    Object.keys(postNumbers).forEach((value) => {
        returnValue.push({value: value, label: value});
    });

    return returnValue;
}

export const cityName = () => {
    const postNumbers = post();
    let returnValue = [];

    // eslint-disable-next-line
    for (const [key, value] of Object.entries(postNumbers)) {
        returnValue.push({value: value, label: value});
    }

    returnValue.sort(function(a, b) {
        /*let nameA = a.value.toUpperCase(); // ignore upper and lowercase
        let nameB = b.value.toUpperCase(); // ignore upper and lowercase*/
        if (a.value < b.value) {
            return -1;
        }
        if (a.value > b.value) {
            return 1;
        }

        // names must be equal
        return 0;
    });

    return returnValue;
}

export const getZip = (city) => {
    const postNumbers = post();
    for (const [key, value] of Object.entries(postNumbers)) {
        if (value === city) {
            return key;
        }
    }
}

export const getCity = (zip) => {
    const postNumbers = post();
    for (const [key, value] of Object.entries(postNumbers)) {
        if (key === zip) {
            return value;
        }
    }
}
