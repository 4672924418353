import React, { Component } from 'react';
import classes from "../../../List/List.module.css";
import "../../../../../hoc/Print/styles.css";
import axios from "axios";
import env from "../../../../../env/src_config";
import "./Import.css";
import {restoreLocalStorage} from "../../Helpers/SaveAndRestore";
import {withTranslation} from 'react-i18next';

class Import extends Component {

    importFromFile = (e, t) => {
        const fData = new FormData();
        fData.append('file', e.target.files[0]);

        axios.post(env.api.private + 'upload', fData, this.props.headers).then((response) => {
            if (response.status === 200) {
                restoreLocalStorage({importFileId: response.data.record.id}, this.props.reloadState, this.props.headers, this.props.tabRef);
            } else {
                alert(t('fileUpload.error'));
            }
        }, (error) => {
            console.log(error);
        });
    }

    render() {
        const {t} = this.props;
        return (
            <div className="tab-pane" id="import" role="tabpanel">
                <div className="row">
                    {/* <div className="col-lg-6 text-center">
                        <button className={classes.Icons+' print_button'}><span><i className="mdi mdi-google-cloud"/>{t('import.importDataSystem')}</span></button>
                    </div> */}
                    <div className="col-12 text-center">
                        <button className={classes.Icons+' print_button fileUploadButton'}>
                            <span><i className="mdi mdi-file-sync-outline"/>{t('import.importFileData')}</span>
                            <input type="file" className="fileImport" name="fileImport" onChange={(e) => this.importFromFile(e, t)} />
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Import);
