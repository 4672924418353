import React, {Component, Fragment} from "react";
import {withTranslation} from'react-i18next';
import {linker} from "../../Helpers/Linker";
import {singleColumn, twoColumns} from "./SubjectHelpers/Display";
import Swal from "sweetalert2";
import moment from "moment";
import FileUpload from "../../../../../hoc/Form/FileUpload/FileUpload";
import {textChangeFilesHandler} from '../../Helpers/ChangeHandlers';

class SingleSubject extends Component {

    state = {
        subjectStoreAddress: false,
        hideStreet: []
    }

    localStorageName = "SODNEDRAZBE.subjectFiles";

    booleanChangeHandler = (e, index, input) => {
        if (e.target !== undefined) {
            const setVal = (e.target.value === "true");

            let data = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));
            data.values.subjects[index][input] = setVal;
            localStorage.setItem('SODNEDRAZBE.JSData', JSON.stringify(data));
            this.props.reloadState();
        }
    }

    textRewrite(e, index, input) {
        if (!(e instanceof moment) && (e !== null)) {
            if (e.target.name === "subjectStore") {
                if (e.target.value.length === 0) {
                    this.setState({subjectStoreAddress: false});
                } else {
                    this.setState({subjectStoreAddress: true});
                }
            }
        }

        this.props.textChangeSubjectHandler(e, index, input);
    }

    mapLinker = (input, mainLinkerFields) => {
        if (input === "subjectStoreAddress") {
            if (this.state.subjectStoreAddress) {
                const {t} = this.props;
                mainLinkerFields.data['subjectStoreAddress'].mandatory = true;
                mainLinkerFields.data['subjectStoreAddress'].error = { code: "mandatory", title: t('mandatory') };
            } else {
                mainLinkerFields.data['subjectStoreAddress'].mandatory = false;
                mainLinkerFields.data['subjectStoreAddress'].error = null;
            }
        }

        const inputlinkerFields = {
            input: input,
            textHandler: (e) => this.textRewrite(e, this.props.index, input),
            datetimeHandler: (moment, input) => this.props.datetimeChangeSubjectHandler(moment, input, this.props.index),
            booleanHandler: (e) => this.booleanChangeHandler(e, this.props.index, input),
        }

        return linker({...mainLinkerFields, ...inputlinkerFields});
    }

    copyAddress(field) {
        const {t} = this.props;
        Swal.fire({
            title: t('single.copyAddress'),
            html: t('single.copyAddressContent'),
            icon: 'question',
            confirmButtonText: t('yes'),
            showCancelButton: true,
            cancelButtonText: t('no')
        }).then(result => {
           if (result.value) {
               let localStorageData = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));
               if ((localStorageData.values.subjects[this.props.index][field] !== undefined) && (localStorageData.values.subjects[this.props.index][field] !== null) && ((localStorageData.values.subjects[this.props.index][field].length !== 0))) {
                   localStorageData.values.subjects[this.props.index]['viewAddress'] = localStorageData.values.subjects[this.props.index][field];
                   localStorage.setItem('SODNEDRAZBE.JSData', JSON.stringify(localStorageData));
                   this.props.reloadState();
               } else {
                   Swal.fire({
                       title: t('single.copyAddress'),
                       html: t('single.copyAddressError'),
                       icon: "error",
                       confirmButtonColor: '#1068ce',
                       confirmButtonText: t('ok')
                   });
               }
           }
        });
    }

    clearViewFields = () => {
        let localStorageData = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));
        if ((localStorageData.values.subjects[this.props.index]['viewAddress'] !== undefined) && (localStorageData.values.subjects[this.props.index]['viewAddress'] !== null)) {
            delete localStorageData.values.subjects[this.props.index]['viewAddress'];
            delete localStorageData.values.subjects[this.props.index]['viewStartAt'];
            delete localStorageData.values.subjects[this.props.index]['viewLeader'];
            localStorage.setItem('SODNEDRAZBE.JSData', JSON.stringify(localStorageData));
            this.props.reloadState();
        }
    }

    textChangeFilesHandler = (e, index) => {
        textChangeFilesHandler(e, index, this.localStorageName + '_' + this.props.index);
        this.props.reloadState();
    }

    hideUploadForm = () => {
        if (this.props.subjects[this.props.index] !== undefined) {
            if ((this.props.subjects[this.props.index].saleSubject.value !== null) && (this.props.subjects[this.props.index].saleSubject.value === "eff0305e-332b-4bbb-9484-43815321ddbb")) {
                return true;
            }
        }
        return false;
    }

    render() {
        const {t} = this.props;
        if (this.props.index === null) {
            return null;
        }

        if ((this.props.subjects !== undefined) && (this.props.subjects !== null)) {

            let hideStreet = [];
            if ((this.props.subjects[this.props.index] !== undefined) && (this.props.subjects[this.props.index]['saleSubject'] !== undefined) && (this.props.subjects[this.props.index]['propertyKind'] !== undefined)) {
                if ((this.props.subjects[this.props.index]['saleSubject'].value === "2ee072ab-7833-48e6-9e83-d9cf8d7b92f0") && (this.props.subjects[this.props.index]['propertyKind'].value === "5b7e6618-332b-46c3-9302-a80641f8e7e0")) {
                    hideStreet = ['address'];
                }
            }

            const mainLinkerFields = {
                data: this.props.subjects[this.props.index],
                selectHandler: (e) => this.props.selectChangeSubjectHandler(e, this.props.index),
                cols: "4/8",
                index: this.props.index,
                addresses: this.props.addresses,
                subjects: this.props.subjects,
                arrayType:  "subjects",
                hideStreet: hideStreet,
                addressChangeRootHandler: this.props.addressChangeRootHandler
            }

            const mainLeft = ['saleSubject', 'shareInTheBasicLegalPosition', 'propertyKind', 'propertyType', 'subjectType', 'vin'].map(input => {
                return this.mapLinker(input, mainLinkerFields);
            });

            const mainRight = ['estimatedPrice', 'additionalAttribute', 'subjectLoad'].map(input => {
                return this.mapLinker(input, mainLinkerFields);
            });

            const mainSubLeft = ['description'].map(input => {
                return this.mapLinker(input, mainLinkerFields);
            });

            const realestateDesc2 = ['cadastralMunicipalityCode', 'cadastralMunicipalityName', 'parcelNumber', 'buildingNumber', 'partNumber', 'hangingBuildingNumber', 'label'].map(input => {
                return this.mapLinker(input, mainLinkerFields);
            });

            const mainSubRight = ['encumbranceOfRealEstateInTheBasicLegalPosition'].map(input => {
                return this.mapLinker(input, mainLinkerFields);
            });

            const realestateDescRight = ['subjectProperties', 'renovated'].map(input => {
                return this.mapLinker(input, mainLinkerFields);
            });

            const realestateDescLeft = ['floor', 'rooms', 'area', 'constructionYear', 'energyCertificate', 'subjectShare', 'isPropertyInRegistry', 'registryId'].map(input => {
                return this.mapLinker(input, mainLinkerFields);
            });

            const securityLeft = ['securityIssuer'].map(input => {
                return this.mapLinker(input, mainLinkerFields);
            });

            const securityRight = ['securityLabel'].map(input => {
                return this.mapLinker(input, mainLinkerFields);
            });

            /* delež družbenika */
            const debtorShare = ['debtorShare', 'registrationNumber', 'debtorShareAddress'].map(input => {
                return this.mapLinker(input, mainLinkerFields);
            });

            /* terjatev do dolžnika */
            const debtorClaim = ['debtorClaim', 'debtorClaimAddress'].map(input => {
                return this.mapLinker(input, mainLinkerFields);
            });

            /* skupni naslov */
            const commonFields = ['commonTitle', 'commonAddress'].map(input => {
                return this.mapLinker(input, mainLinkerFields);
            });

            /* hramba predmeta */
            const subjectStore = ['subjectStore', 'subjectStoreAddress'].map(input => {
                return this.mapLinker(input, mainLinkerFields);
            });

            /* address - only if 01 */
            const address = ['address'].map(input => {
                return this.mapLinker(input, mainLinkerFields);
            });

            /* ogled */
            const viewLeft = ['viewStartAt', 'viewLeader'].map(input => {
                return this.mapLinker(input, mainLinkerFields);
            });

            const viewRight = ['viewAddress'].map(input => {
                return this.mapLinker(input, mainLinkerFields);
            });

            /* bail */
            const bailLeft = ['startingPrice', 'stepPrice'].map(input => {
                return this.mapLinker(input, mainLinkerFields);
            });
            const bailRight = ['securityPrice', 'lowestPrice'].map(input => {
                return this.mapLinker(input, mainLinkerFields);
            });

            const showMainSub = twoColumns(mainSubLeft, mainSubRight);
            const showRealestateDesc = twoColumns(realestateDescLeft, realestateDescRight);
            const showSecurity = twoColumns(securityLeft, securityRight);
            const showDebtorShare = singleColumn(debtorShare);
            const showDrealestateDesc2 = singleColumn(realestateDesc2);
            const showDebtorClaim = singleColumn(debtorClaim);
            const showSubjectStore = singleColumn(subjectStore);
            const showAddress = singleColumn(address);
            const showView = twoColumns(viewLeft, viewRight);
            const showCommonFields = singleColumn(commonFields);
            const showBail = twoColumns(bailLeft, bailRight);

            return(
                <Fragment>
                    <div className="row">
                        <div className="col-xl-6">{mainLeft}</div>
                        <div className="col-xl-6">{mainRight}</div>
                    </div>

                    {showDrealestateDesc2 ?
                        <Fragment>
                            <hr />
                            <div className="row">
                                <div className="col-lg-6">{realestateDesc2}</div>
                                <div className="col-lg-6"/>
                            </div>
                        </Fragment>
                        : ''
                    }

                    {showRealestateDesc ?
                        <Fragment>
                            <hr />
                            <div className="row">
                                <div className="col-lg-6">{realestateDescLeft}</div>
                                <div className="col-lg-6">{realestateDescRight}</div>
                            </div>
                        </Fragment>
                        : ''
                    }

                    {showMainSub ?
                        <Fragment>
                            <hr />
                            <div className="row">
                                <div className="col-lg-6">{mainSubLeft}</div>
                                <div className="col-lg-6">{mainSubRight}</div>
                            </div>
                        </Fragment>
                    : ''}


                    {showSecurity ?
                        <Fragment>
                            <hr />
                            <div className="row">
                                <div className="col-lg-6">{securityLeft}</div>
                                <div className="col-lg-6">{securityRight}</div>
                            </div>
                        </Fragment>
                    : '' }

                    {showDebtorShare ?
                        <Fragment>
                            <hr />
                            <div className="row">
                                <div className="col-lg-12">{debtorShare}</div>
                            </div>
                        </Fragment>
                    : '' }

                    {showDebtorClaim ?
                        <Fragment>
                            <hr />
                            <div className="row">
                                <div className="col-lg-12">{debtorClaim}</div>
                            </div>
                        </Fragment>
                    : ''}

                    {showSubjectStore ?
                        <Fragment>
                            <hr />
                            <h4 className="card-title">{t('draft.create.header.storage')}</h4>
                            <div className="row">
                                <div className="col-lg-12">{subjectStore}</div>
                            </div>
                            <button onClick={() => this.copyAddress('subjectStoreAddress')} className="btn btn-primary">{t('singleSubject.button.copyAddressToView')}</button>
                        </Fragment>
                    : ''}

                    {showAddress ?
                        <Fragment>
                            <hr />
                            <h4 className="card-title">{t('singleSubject.title.address')}</h4>
                            <div className="row">
                                <div className="col-lg-12">{address}</div>
                            </div>
                            <button onClick={() => this.copyAddress('address')} className="btn btn-primary">{t('singleSubject.button.copyAddressToView')}</button>
                        </Fragment>
                        : ''}

                    {showCommonFields ?
                        <Fragment>
                            <div className="row">
                                <div className="col-lg-12">{commonFields}</div>
                            </div>
                        </Fragment>
                        : ''
                    }

                    {showView ?
                        <Fragment>
                            <hr />
                            <h4 className="card-title">{t('singleSubject.title.view')}</h4>
                            <div className="row">
                                <div className="col-lg-6">
                                    {viewLeft}
                                    <button onClick={this.clearViewFields} className="btn btn-primary">{t('draft.create.btn.deleteView')}</button>
                                </div>
                                <div className="col-lg-6">
                                    {viewRight}
                                </div>
                            </div>
                        </Fragment>
                    : ''}

                    {showBail ?
                        <Fragment>
                            <hr />
                            <div className="row">
                                <div className="col-lg-6">{bailLeft}</div>
                                <div className="col-lg-6">
                                    {bailRight}
                                </div>
                            </div>
                        </Fragment>
                        : ''
                    }

                    {!this.hideUploadForm() ?
                        <Fragment>
                            <hr />
                            <h4 className="card-title">{t('singleSubject.button.picturesOfObjects')}</h4>
                            <FileUpload
                                headers={this.props.headers} cols="1/12"
                                files={this.props.subjectFiles}
                                fileAttribs={["description", "sort"]}
                                filter={["png", "jpg", "jpeg"]}
                                storedFiles={this.props.storedFiles}
                                localStorageName={this.localStorageName + "_" + this.props.index}
                                textChangeFilesHandler={this.textChangeFilesHandler}
                                displayImageThumb={true}
                                enableSort={true}
                            />
                        </Fragment> : null}

                    <div className="row">
                        <button onClick={this.props.saveSubject} className="btn btn-primary">{t('singleSubject.button.addItem')}</button>
                        {(this.props.subjects[1] !== undefined) ? <button onClick={this.props.saveSubject} className="btn btn-primary ml-1">{t('singleSubject.button.cancel')}</button> : ''}
                        {this.props.index !== 0 ? <button onClick={() => this.props.deleteSubject(this.props.index)} className="btn btn-primary ml-1">{t('draft.create.btn.deleteSubject')}</button> : null}
                    </div>
                </Fragment>
            );
        } else {
            return null;
        }
    }
}

export default withTranslation()(SingleSubject);
