import React, { Component, Fragment } from 'react';
import {linker} from "../../Helpers/Linker";

class SpecialBail extends Component {

    mapLinker = (input, mainLinkerFields) => {
        const inputlinkerFields = {
            input: input,
            textHandler: (e) => this.props.textChangeRootHandler(e, input)
        }

        return linker({...mainLinkerFields, ...inputlinkerFields});
    }

    render() {
        const mainLinkerFields = {
            data: this.props.data,
            addressChangeRootHandler: this.props.addressChangeRootHandler,
            cols: "4/8",
            addresses: this.props.addresses
        }

        const fields = ['managerTitle', 'managerAddress', 'managerIban'].map(input => {
            return this.mapLinker(input, mainLinkerFields);
        });

        let go = false;
        for(let i = 0; i < fields.length; i++) {
            if (fields[i] !== null) {
                go = true;
                break;
            }
        }

        if (go) {
            return (
                <Fragment>
                    <div className="tab-pane" id="specialbail" role="tabpanel">
                        <div className="row">
                            <div className="col-lg-12">
                                {fields}
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        } else {
            return ('');
        }

    }
}

export default SpecialBail;
