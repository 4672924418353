/*if local file exists load local
else*/
// eslint-disable-next-line
export default {
    'version': (window.X_version || process.env.REACT_APP_version),
    'is_development': (window.X_IS_DEVELOPMENT === "true" || process.env.REACT_APP_IS_DEVELOPMENT === "true" || false),
    'is_public': (window.X_IS_PUBLIC === "true" || process.env.REACT_APP_IS_PUBLIC === "true" || false),
    'defaultLanguage': 'sl-SI',
    'defaultCurrency': 'EUR',
    'gmapsKey': 'AIzaSyDVSt74clEtWe7uEYaz0NCN87mNUKKWQMo',
    'externallinks': {
        'sipass': (window.X_SIPASS_URL || /*'https://sipass.dev.sodnedrazbe.si/shibbolethSP/login/'*/'https://sipass.dev.sodnedrazbe.si/SodneDrazbe/Shibboleth.sso'),
        'vlozisce': (window.X_VLOZISCE_URL || 'https://evlozisce-test.sodisce.si/'),
    },
    'googleAnalytics': (window.X_GA || 'UA-185981324-2'),
    'spa': (window.X_SPA_URL || 'https://dev.g.sodnedrazbe.si'),
    'openMap': (window.X_OPEN_MAP_URL || 'http://open-map.local.izstop.si'),
    'whichMap': (window.X_WHICH_MAP || process.env.REACT_APP_WHICH_MAP),
    'api': {
        'private': (window.X_API_URL || 'https://dev-api.g.sodnedrazbe.si')+'/private/',
        'bidder': (window.X_API_URL || 'https://dev-api.g.sodnedrazbe.si')+'/bidder/',
        'download': (window.X_SPA_URL || 'https://dev.g.sodnedrazbe.si')+'/private/download',
        'downloadPublic': (window.X_SPA_URL || 'https://dev.g.sodnedrazbe.si')+'/public/download',
        'downloadBidder': (window.X_SPA_URL || 'https://dev.g.sodnedrazbe.si')+'/bidder/download',
        'public': (window.X_API_URL || 'https://dev-api.g.sodnedrazbe.si')+'/public/',
        'api': (window.X_API_URL || 'https://dev-api.g.sodnedrazbe.si'),
        'wss': (window.X_WSS_URL || 'wss://dev-api.g.sodnedrazbe.si/ws')
    },
    'tokens': {
        'subscriber': {
            'dev_token_sodisce_A': (window.X_dev_token_sodisce_A || process.env.REACT_APP_dev_token_sodisce_A),
            'dev_token_sodisce_B': (window.X_dev_token_sodisce_B || process.env.REACT_APP_dev_token_sodisce_B),
            'dev_token_stecajni_C': (window.X_dev_token_stecajni_C || process.env.REACT_APP_dev_token_stecajni_C),
            'dev_token_izvrsitelj_D': (window.X_dev_token_izvrsitelj_D || process.env.REACT_APP_dev_token_izvrsitelj_D),
        },
        'bidder': {
            'bidder_1': (window.X_dev_token_bidder_1 || process.env.REACT_APP_bidder_1),
            'bidder_2': (window.X_dev_token_bidder_2 || process.env.REACT_APP_bidder_2),
            'bidder_3': (window.X_dev_token_bidder_3 || process.env.REACT_APP_bidder_3),
        }
    },
    'system_notification_title': (window.X_system_notification_title || process.env.REACT_APP_system_notification_title),
    'system_notification_text': (window.X_system_notification_text || process.env.REACT_APP_system_notification_text)
};
