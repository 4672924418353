import React, { Component, Fragment } from 'react';
import {withTranslation} from'react-i18next';
import SingleSubject from './SingleSubject';
import {isNull} from "../../../../../izUtils";
import "./SubjectList.css";
import {Realestateid} from '../../../../../hoc/RealEstateId/Realestateid';

import Swal from 'sweetalert2';

class SubjectList extends Component {
    state = {
        subjectIndex: null,
        showSubjectList: false
    };

    componentDidMount() {
        let data = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));
        if (this.props.subjects[0] === undefined) {
            data.values.subjects[0] = {};
            localStorage.setItem('SODNEDRAZBE.JSData', JSON.stringify(data));
            this.props.reloadState();
        } else {
            if (this.props.subjects.length > 1) {
                this.setState({showSubjectList: true});
            } else {
                this.setState({subjectIndex: 0});
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        /*if (this.openIndex !== undefined) {
            if (JSON.stringify(prevProps.subjects) !== JSON.stringify(this.props.subjects)) {
                let tmp = this.openIndex;
                this.openIndex = undefined;
                this.subjectClick(tmp);
            }
        }*/
        if (prevProps.subjects.length !== this.props.subjects.length) {
            if (this.props.subjects.length > 1) {
                this.setState({showSubjectList: true});
            } else {
                this.setState({subjectIndex: 0});
            }
        }
    }

    subjectClick = (index) => {
        this.setState({subjectIndex: index});
        this.setState({showSubjectList: false});
    }

    saveSubject = () => {
        //this.props.save();
        this.props.reloadState();
        this.setState({showSubjectList: true});
    }

    addNewSubject = () => {
        let data = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));

        if (data.values.subjects === undefined) {
            this.setState({subjectIndex: 0});
        } else {
            let index = data.values.subjects.length;
            if (this.props.subjects[index] === undefined) {
                let indexToCreate = 0;
                if (this.props.subjects[0] === undefined) {
                    data.values.subjects[0] = {};
                    localStorage.setItem('SODNEDRAZBE.JSData', JSON.stringify(data));
                } else {
                    data.values.subjects[index] = {};
                    localStorage.setItem('SODNEDRAZBE.JSData', JSON.stringify(data));
                    indexToCreate = index;
                    this.openIndex = index;
                }

                const localStorageData = localStorage.getItem("SODNEDRAZBE.subjectFiles_"+indexToCreate);
                if (isNull(localStorageData)) {
                    localStorage.setItem("SODNEDRAZBE.subjectFiles_"+indexToCreate, JSON.stringify({"values":{"files":[{}]}}));
                }

                this.props.reloadState();
            }
        }
    }

    subjectCopy = (index) => {
        let data = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));
        let newSubject = {...data.values.subjects[index]};

        delete newSubject['estimatedPrice'];
        delete newSubject['area'];
        delete newSubject['parcelNumber'];
        delete newSubject['buildingNumber'];
        delete newSubject['hangingBuildingNumber'];

        data.values.subjects.push(newSubject);
        localStorage.setItem('SODNEDRAZBE.JSData', JSON.stringify(data));
        this.props.reloadState();
    }

    deleteSubject = (index) => {
        const {t} = this.props;
        Swal.fire({
            title: t('draft.create.swal.deleteSubject.title'),
            html: t('draft.create.swal.deleteSubject.content'),
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: '#1068ce',
            cancelButtonColor: '#cda73a',
            cancelButtonText: t('no'),
            confirmButtonText: t('yes')
        }).then((result) => {
            if (result.value) {
                let data = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));
                let subject = [];
                for (let i = 0; i < data.values.subjects.length; i++) {
                    if (i !== index) {
                        subject.push(data.values.subjects[i]);
                    } else {
                        localStorage.removeItem("SODNEDRAZBE.subjectFiles_"+index);
                    }

                    if (i > index) {
                        const newIndex = i - 1;
                        localStorage.setItem("SODNEDRAZBE.subjectFiles_"+newIndex, localStorage.getItem("SODNEDRAZBE.subjectFiles_"+i));
                        localStorage.removeItem("SODNEDRAZBE.subjectFiles_"+i);
                    }
                }
                data.values.subjects = subject;

                localStorage.setItem('SODNEDRAZBE.JSData', JSON.stringify(data));
                this.setState({showSubjectList: true});
                this.props.reloadState();
            }
        });
    }

    render() {
        const {t} = this.props;
        if (this.props.subjects !== undefined) {
            const tBody = this.props.subjects.map((input, index) => {
                let subjectTitle = null;
                let subjectType = null;

                if (!isNull(input.saleSubject.value)) {
                    for(let i = 0; i < input.saleSubject.options.length; i++) {
                        if (input.saleSubject.options[i].value === input.saleSubject.value) {
                            subjectTitle = input.saleSubject.options[i].label;
                            break;
                        }
                    }

                    for(let i = 0; i < input.subjectType.options.length; i++) {
                        if (input.subjectType.options[i].value === input.subjectType.value) {
                            subjectType = input.subjectType.options[i].label;
                            break;
                        }
                    }
                }

                let displayParcelNumber = '';

                if ((input.saleSubject.value === "2ee072ab-7833-48e6-9e83-d9cf8d7b92f0") && (input.propertyType !== undefined) && (input.propertyKind !== undefined)) {
                    const realEstateValue = {
                        saleSubject: input.saleSubject.value,
                        propertyType:  input.propertyType.value,
                        propertyKind: input.propertyKind.value,
                        cadastralMunicipalityCode: input.cadastralMunicipalityCode.value,
                        parcelNumber: input.parcelNumber.value,
                        buildingNumber: input.buildingNumber.value,
                        partNumber: input.partNumber.value,
                        hangingBuildingNumber: input.hangingBuildingNumber.value
                    }
                    displayParcelNumber = Realestateid(realEstateValue, t);

                } else {
                    displayParcelNumber = input.registryId.value;
                }

                let errors = 0;
                if (this.props.errors !== null) {
                    if (this.props.errors['subjects'] !== undefined) {
                        if (this.props.errors['subjects'][index] !== undefined) {
                            errors = this.props.errors['subjects'][index].length;
                        }
                    }
                }

                return (<tr key={index} className={errors !== 0 ? 'mandatory' : ''}>
                    <td style={{width: "120px"}}>
                        <i className="mdi mdi-trash-can-outline" onClick={() => this.deleteSubject(index)} title={t('delete')} />
                        <i className="mdi mdi-file-edit-outline ml-1" onClick={() => this.subjectClick(index)} title={t('edit')} />
                        <i className="mdi mdi mdi-content-duplicate ml-1" onClick={() => this.subjectCopy(index)} title={t('copy')} />
                    </td>
                    <td onClick={() => this.subjectClick(index)}>{index+1}</td>
                    <td onClick={() => this.subjectClick(index)}>{isNull(subjectTitle) ? t('draft.subject.notSet') : subjectTitle }</td>
                    <td onClick={() => this.subjectClick(index)}>{isNull(subjectType) ? t('draft.subject.notSet') : subjectType }</td>
                    <td onClick={() => this.subjectClick(index)}>{displayParcelNumber}</td>
                    <td onClick={() => this.subjectClick(index)}>{errors}</td>
                </tr>)
            });

            return (
                <Fragment>
                    <div className="tab-pane" id="subjects" role="tabpanel">
                        {this.state.showSubjectList ?
                            (
                                <Fragment>
                                    <h5 className="cardTitle">{t('subjectList.subject')}*</h5>
                                    <table className="table mb-0 subjectList">
                                        <thead className={"thead-light" + (this.props.subjects.length === 0 ? ' mandatory' : '')}>
                                            <tr>
                                                <th>{t('subjectList.action')}</th>
                                                <th>#</th>
                                                <th>{t('subjectList.subject')}</th>
                                                <th>{t('subjectList.itemType')}</th>
                                                <th>{t('published.list.tableH.registryID')}</th>
                                                <th>{t('errorNr')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>{tBody}</tbody>
                                    </table>
                                    <div className="row mt-3">
                                        <button disabled={this.props.subjects.length >=1 && this.props.saleType.value === "c857cb94-c4d8-4cd0-adb6-1c67d4dc0894" ? true : false} onClick={this.addNewSubject} className="btn btn-primary">{t('subjectList.button.addNewSubject')}</button>
                                    </div>
                                </Fragment>) :
                            (
                                <div id="SingleSubjectWrapper">
                                    <SingleSubject
                                        addresses={this.props.addresses}
                                        index={this.state.subjectIndex}
                                        headers={this.props.headers}
                                        saveSubject={this.saveSubject}
                                        subjects={this.props.subjects}
                                        subjectFiles={this.props.subjectFiles[this.state.subjectIndex]}
                                        storedFiles={this.props.storedFiles}
                                        reloadState={this.props.reloadState}
                                        deleteSubject={this.deleteSubject}
                                        selectChangeSubjectHandler={this.props.selectChangeSubjectHandler}
                                        textChangeSubjectHandler={this.props.textChangeSubjectHandler}
                                        datetimeChangeSubjectHandler={this.props.datetimeChangeSubjectHandler}
                                        addressChangeRootHandler={this.props.addressChangeRootHandler}
                                    />
                                </div>
                            )
                        }
                    </div>
                </Fragment>
            );
        } else {
            return null;
        }
    }
}

export default withTranslation() (SubjectList);
