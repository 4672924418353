import React, {Component, Fragment} from 'react';
import {withTranslation} from 'react-i18next';

class MultiCheckbox extends Component {

    state = {
        val: null,
        checkboxes: null
    }

    disabled = null;
    readOnly = null;

    options = null;

    change(e) {
        this.setState({val: e.target.value});
        this.props.change(e, this.props.index);
    }

    componentDidMount() {
        this.reloadComponent();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        /*if (prevProps.options !== this.props.options) {
            this.reloadComponent();
        } else if ((prevProps.value !== this.props.value) && (this.state.val !== this.props.value)) {
            this.reloadComponent();
        }*/
        if (this.props.values !== prevProps.values) {
            this.reloadComponent();
        }
    }

    reloadComponent(){
        if (this.props.display || (this.props.display === undefined)) {
            let checkboxes = []
            if ((this.props.options === undefined) || (this.props.options === null)) {

            } else {
                checkboxes = this.props.options.map((option, index) => {
                    if(option.value.length !== 0) {
                        let checked = false;
                        if (this.props.values.indexOf(option.value) !== -1) {
                            checked = true;
                        }
                        return (
                            <div className="custom-control custom-checkbox mb-1 d-block" key={this.props.name+'_'+index+'_'+option.value}>
                                <input type="checkbox" className="custom-control-input" checked={checked} id={this.props.name+'_'+option.value} name={this.props.name+'_'+option.value} onChange={(e) => this.change(e)} />
                                <label className="custom-control-label" htmlFor={this.props.name+'_'+option.value}>{option.label}</label>
                            </div>
                        );
                    } else {
                        return null;
                    }
                });
            }
            this.setState({checkboxes: checkboxes});

            this.disabled = false;
            if (this.props.setDisabled !== undefined) {
                this.disabled = true;
            }

            this.readOnly = false;
            if (this.props.setReadOnly !== undefined) {
                this.readOnly = true;
            }
        }
    }

    render() {
        if (this.state.checkboxes !== null) {
            if (this.props.display || (this.props.display === undefined)) {

                let labelAppendix = '';

                if (this.props.mandatory) {
                    labelAppendix = '*';
                }

                return (
                    <Fragment>
                        <div className={"mb-2"}>{this.props.label + labelAppendix}</div>
                        <div className="customScroll">
                            {this.state.checkboxes}
                        </div>
                    </Fragment>
                );
            } else {
                return ('');
            }
        } else {
            return ('');
        }
    }
};

export default withTranslation()(MultiCheckbox);
