import React, { Fragment} from "react";
import {withTranslation} from'react-i18next'
import Breadcrumb from "../Header/Breadcrumbs/Breadcrumb";
import './Loading.css'

const Loading = (props) => {
    const {t} = props;
    return (

        <Fragment>
            <Breadcrumb title="&nbsp;"/>


            <div className="row">
                <div className="col-12">
                    <div className="card" style={{backgroundColor: "#1759B3"}}>
                        <div className="card-body text-center" style={{minHeight: "300px"}}>
                            <h4 className="card-title" style={{color: '#fff'}}>{t('loading')}</h4>


                            <div className="bookshelf_wrapper">
                                <ul className="books_list">
                                    <li className="book_item first"/>
                                    <li className="book_item second"/>
                                    <li className="book_item third"/>
                                    <li className="book_item fourth"/>
                                    <li className="book_item fifth"/>
                                    <li className="book_item sixth"/>
                                </ul>
                                <div className="shelf"/>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default withTranslation()(Loading);
