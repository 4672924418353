export const koNumbers = () => {
    const koNumbers = ko();
    let returnValue = [];
    Object.keys(koNumbers).forEach((value) => {
        returnValue.push({value: value, label: value});
    });

    return returnValue;
}

export const getKoNameByCode = (code) => {
    const koNumbers = ko();
    return koNumbers[code];
}

export const getKoCode = (city) => {
    const koNumbers = ko();
    for (const [key, value] of Object.entries(koNumbers)) {
        if (value === city) {
            return key;
        }
    }
}

export const koName = () => {
    const koNumbers = ko();
    let returnValue = [];

    // eslint-disable-next-line
    for (const [key, value] of Object.entries(koNumbers)) {
        returnValue.push({value: value, label: value});
    }

    returnValue.sort(function(a, b) {
        /*let nameA = a.value.toUpperCase(); // ignore upper and lowercase
        let nameB = b.value.toUpperCase(); // ignore upper and lowercase*/
        if (a.value < b.value) {
            return -1;
        }
        if (a.value > b.value) {
            return 1;
        }

        // names must be equal
        return 0;
    });

    return returnValue;
}

function ko() {
    return {
        1: "HODOŠ",
        2: "SUHI VRH",
        3: "DOLENCI",
        4: "BUDINCI",
        5: "MARKOVCI",
        6: "ČEPINCI",
        7: "LUCOVA",
        8: "NERADNOVCI",
        9: "MARTINJE",
        10: "BOREČA",
        11: "ŽENAVLJE",
        12: "ŠULINCI",
        13: "STANJEVCI",
        14: "GORNJI PETROVCI",
        15: "ADRIJANCI",
        16: "PESKOVCI",
        17: "ŠALOVCI",
        18: "KRPLIVNIK",
        19: "DOMANJŠEVCI",
        20: "KRIŽEVCI",
        21: "PANOVCI",
        22: "KUKEČ",
        23: "TRDKOVA",
        24: "MATJAŠEVCI",
        25: "DOLIČ",
        26: "KUZMA",
        27: "GORNJI SLAVEČI",
        28: "SOTINA",
        29: "SERDICA",
        30: "OCINJE",
        31: "KRAMAROVCI",
        32: "NUSKOVA",
        33: "ROGAŠOVCI",
        34: "SVETI JURIJ",
        35: "FIKŠINCI",
        36: "VEČESLAVCI",
        37: "DOLNJI SLAVEČI",
        38: "GRAD",
        39: "VIDONCI",
        40: "OTOVCI",
        41: "MAČKOVCI",
        42: "PROSEČKA VAS",
        43: "KOVAČEVCI",
        44: "POZNANOVCI",
        45: "RADOVCI",
        46: "KRUPLIVNIK",
        47: "MOTOVILCI",
        48: "PERTOČA",
        49: "ROPOČA",
        50: "GERLINCI",
        51: "KRAŠČI",
        52: "VADARCI",
        53: "BODONCI",
        54: "PEČAROVCI",
        55: "DANKOVCI",
        56: "KUŠTANOVCI",
        57: "KOŠAROVCI",
        58: "DOLINA",
        59: "MOŠČANCI",
        60: "VANEČA",
        61: "ŠALAMENCI",
        62: "ZENKOVCI",
        64: "BEZNOVCI",
        65: "TOPOLOVCI",
        66: "DOMAJINCI",
        67: "GORNJI ČRNCI",
        68: "KOROVCI",
        69: "CANKOVA",
        70: "SKAKOVCI",
        71: "STRUKOVCI",
        72: "PUŽEVCI",
        73: "LEMERJE",
        74: "BREZOVCI",
        75: "PREDANOVCI",
        76: "GORICA",
        77: "PUCONCI",
        78: "SEBEBORCI",
        79: "BOKRAČI",
        80: "KRNCI",
        81: "IVANOVCI",
        82: "KANČEVCI",
        83: "LONČAROVCI",
        84: "RATKOVCI",
        85: "BERKOVCI",
        86: "IVANJŠEVCI",
        87: "SREDIŠČE",
        88: "PROSENJAKOVCI",
        89: "SELO",
        90: "FOKOVCI",
        91: "ANDREJCI",
        92: "MORAVCI",
        93: "TEŠANOVCI",
        94: "VUČJA GOMILA",
        95: "ČIKEČKA VAS",
        96: "PORDAŠINCI",
        97: "MOTVARJEVCI",
        98: "BUKOVNICA",
        99: "FILOVCI",
        100: "BOGOJINA",
        101: "IVANCI",
        102: "MLAJTINCI",
        103: "LUKAČEVCI",
        104: "RAKIČAN",
        105: "MURSKA SOBOTA",
        106: "NORŠINCI",
        107: "MARTJANCI",
        108: "NEMČAVCI",
        109: "MARKIŠAVCI",
        110: "POLANA",
        111: "ČERNELAVCI",
        112: "VEŠČICA",
        113: "KUPŠINCI",
        114: "VANČA VAS",
        115: "RANKOVCI",
        116: "BOREJCI",
        117: "KRAJNA",
        118: "GEDEROVCI",
        119: "SODIŠINCI",
        120: "MURSKI PETROVCI",
        121: "PETANJCI",
        122: "TIŠINA",
        123: "TROPOVCI",
        124: "GRADIŠČE",
        125: "MURSKI ČRNCI",
        126: "SATAHOVCI",
        127: "KROG",
        128: "BAKOVCI",
        129: "GANČANI",
        130: "LIPA",
        131: "BELTINCI",
        132: "BRATONCI",
        133: "LIPOVCI",
        134: "DOKLEŽOVJE",
        135: "IŽAKOVCI",
        136: "MELINCI",
        137: "ODRANCI",
        138: "TRNJE V PREKMURJU",
        139: "ŽIŽKI",
        140: "ČRENŠOVCI",
        141: "GORNJA BISTRICA",
        142: "SREDNJA BISTRICA",
        143: "DOLNJA BISTRICA",
        144: "KOBILJE",
        145: "ŽITKOVCI",
        146: "DOBROVNIK",
        147: "STREHOVCI",
        148: "RENKOVCI",
        149: "TURNIŠČE",
        150: "GOMILICA",
        151: "BREZOVICA V PREKMURJU",
        152: "NEDELICA",
        153: "RADMOŽANCI",
        154: "KAMOVCI",
        155: "GENTEROVCI",
        156: "MOSTJE PRI LENDAVI",
        157: "DOLGA VAS PRI LENDAVI",
        158: "BANUTA",
        159: "MALA POLANA",
        160: "VELIKA POLANA",
        161: "HOTIZA",
        162: "KAPCA",
        163: "KOT PRI MURI",
        164: "GABERJE",
        165: "LAKOŠ",
        166: "LENDAVA",
        167: "ČENTIBA",
        168: "DOLINA PRI LENDAVI",
        169: "PETIŠOVCI",
        170: "PINCE",
        171: "VRATJI VRH",
        172: "VRATJA VAS",
        173: "PODGORJE",
        174: "KONJIŠČE",
        175: "DROBTINCI",
        176: "GRABE",
        177: "NASOVA",
        178: "ŽIBERCI",
        179: "ŽEPOVCI",
        180: "ČRNCI",
        181: "APAČE",
        182: "SEGOVCI",
        183: "LUTVERCI",
        184: "GORNJA RADGONA",
        186: "MELE",
        187: "ČREŠNJEVCI",
        188: "POLICE",
        189: "HERCEGOVŠČAK",
        190: "LOMANOŠE",
        191: "PLITVICA",
        192: "PLITVIČKI VRH",
        193: "SPODNJA ŠČAVNICA",
        194: "LASTOMERCI",
        195: "ZBIGOVCI",
        196: "OREHOVSKI VRH",
        197: "OREHOVCI",
        198: "BORAČEVA",
        199: "ŠRATOVCI",
        200: "RADENCI",
        201: "RIHTAROVCI",
        202: "KAPELSKI VRH",
        203: "OČESLAVCI",
        204: "JANŽEV VRH",
        205: "STAVEŠINCI",
        206: "IVANJŠEVCI",
        207: "RADVENCI",
        208: "NEGOVA",
        209: "KUNOVA",
        210: "GORNJI IVANJCI",
        211: "SPODNJI IVANJCI",
        212: "OKOSLAVCI",
        213: "DRAGOTINCI",
        214: "ROŽIČKI VRH",
        215: "STANETINCI",
        216: "HRAŠENSKI - RAČKI VRH",
        217: "MURŠČAK",
        218: "HRASTJE-MOTA",
        219: "MURSKI VRH",
        220: "SELIŠČI",
        221: "SLAPTINCI",
        222: "JAMNA",
        223: "BLAGUŠ",
        224: "GRABONOŠ",
        225: "KRALJEVCI",
        226: "SOVJAK",
        227: "TERBEGOVCI",
        228: "GALUŠAK",
        229: "KOKOLAJNŠČAK",
        230: "VUČJA VAS",
        231: "BUČEČOVCI",
        232: "STARA NOVA VAS",
        233: "BUNČANI",
        234: "VERŽEJ",
        235: "ILJAŠEVCI",
        236: "KRIŽEVCI",
        237: "GRLAVA",
        238: "KRIŠTANCI",
        239: "KRAPJE",
        240: "MOTA",
        241: "CVEN",
        242: "BABINCI",
        243: "NORŠINCI",
        244: "LUKAVCI",
        245: "KLJUČAROVCI PRI LJUTOMERU",
        246: "BORECI",
        247: "LOGAROVCI",
        248: "PRECETINCI",
        249: "KURŠINCI",
        250: "BERKOVCI",
        251: "BOLEHNEČICI",
        252: "BUČKOVCI",
        253: "DRAKOVCI",
        254: "MORAVCI",
        255: "GODEMARCI",
        256: "RADOSLAVCI",
        257: "BRANOSLAVCI",
        258: "CEZANJEVCI",
        259: "LJUTOMER",
        260: "KAMENŠČAK",
        261: "STARA CESTA",
        262: "DESNJAK",
        263: "MEKOTNJAK",
        264: "RADOMERJE",
        265: "GRESOVŠČAK",
        266: "PLEŠIVICA",
        267: "ILOVCI",
        268: "SLAMNJAK",
        269: "RINČETOVA GRABA",
        270: "NUNSKA GRABA",
        271: "PRESIKA",
        272: "STROČJA VAS",
        273: "PRISTAVA",
        274: "GLOBOKA",
        275: "VEŠČICA",
        276: "RAZKRIŽJE",
        277: "ŠAFARSKO",
        278: "GIBINA",
        279: "TRNOVCI",
        280: "RUCMANCI",
        281: "RAKOVCI",
        282: "SAVCI",
        283: "TOMAŽ",
        284: "MALA VAS",
        285: "BRATONEČICE",
        286: "SENIK PRI TOMAŽU",
        287: "KLJUČAROVCI PRI ORMOŽU",
        288: "KORAČICE",
        289: "PRŠETINCI",
        290: "LAHONCI",
        291: "ŽVAB",
        292: "RUNEČ",
        293: "STANOVNO",
        294: "IVANJKOVCI",
        295: "ŽEROVINCI",
        296: "CEROVEC - STANKA VRAZA",
        297: "VELIČANE",
        298: "MIHALOVCI",
        299: "MALI BREBROVNIK",
        300: "VELIKI BREBROVNIK",
        301: "VINSKI VRH",
        302: "MIKLAVŽ",
        303: "HERMANCI",
        304: "GOMILA",
        305: "KOG",
        306: "VUZMETINCI",
        307: "KAJŽAR",
        308: "ZASAVCI",
        309: "LAČAVES",
        310: "JASTREBCI",
        311: "VODRANCI",
        312: "VITAN",
        313: "ŠALOVCI PRI SREDIŠČU",
        314: "HUM",
        315: "PAVLOVSKI VRH",
        316: "LIBANJA",
        317: "PAVLOVCI",
        318: "HARDEK",
        319: "LITMERK",
        320: "LEŠNICA PRI ORMOŽU",
        321: "ŠARDINJE",
        322: "VIČANCI",
        323: "SENEŠCI",
        324: "SODINCI",
        325: "STRJANCI",
        326: "BRESNICA",
        327: "PODGORCI",
        328: "OSLUŠEVCI",
        329: "CVETKOVCI",
        330: "TRGOVIŠČE",
        331: "VELIKA NEDELJA",
        332: "ORMOŽ",
        333: "PUŠENCI",
        334: "FRANKOVCI",
        335: "LOPERŠICE",
        336: "OBREŽ",
        337: "GRABE PRI SREDIŠČU",
        338: "SREDIŠČE",
        339: "NOVINCI",
        340: "SLAVŠINA",
        341: "RJAVCI",
        342: "HVALETINCI",
        343: "VITOMARCI",
        344: "DRBETINCI",
        345: "TRNOVSKA VAS",
        346: "BIŠ",
        347: "BIŠEČKI VRH",
        348: "TRNOVSKI VRH",
        349: "LOČKI VRH",
        350: "LOČIČ",
        351: "SVETINCI",
        352: "DESENCI",
        353: "GRLINCI",
        354: "ZAGORCI",
        355: "SENČAK",
        356: "SAKUŠAK",
        357: "BODKOVCI",
        358: "JURŠINCI",
        359: "DRAGOVIČ",
        360: "GRADIŠČAK",
        361: "LEVANJCI",
        362: "DOLIČ",
        363: "DESTRNIK",
        364: "VINTAROVCI",
        365: "JIRŠOVCI",
        366: "KRČEVINA PRI VURBERKU",
        367: "GRAJENŠČAK",
        368: "DRSTELJA",
        369: "JANEŽOVCI",
        370: "JANEŽOVSKI VRH",
        371: "ZGORNJI VELOVLEK",
        372: "SPODNJI VELOVLEK",
        373: "MOSTJE",
        374: "KUKAVA",
        375: "ROTMAN",
        376: "HLAPONCI",
        377: "POLENŠAK",
        378: "BRATISLAVCI",
        379: "POLENCI",
        380: "PRERAD",
        381: "TIBOLCI",
        382: "SLOMI",
        383: "MEZGOVCI",
        384: "DORNAVA",
        385: "PODVINCI",
        386: "PACINJE",
        387: "KICAR",
        388: "ROGOZNICA",
        389: "NOVA VAS PRI PTUJU",
        390: "MESTNI VRH",
        391: "GRAJENA",
        392: "KRČEVINA PRI PTUJU",
        393: "SLOVENJA VAS",
        394: "GEREČJA VAS",
        395: "HAJDOŠE",
        396: "SKORBA",
        397: "HAJDINA",
        399: "DRAŽENCI",
        400: "PTUJ",
        401: "BRSTJE",
        402: "SPUHLJA",
        403: "BOROVCI",
        404: "PRVENCI",
        405: "SOBETINCI",
        406: "ZAGOJIČI",
        407: "MOŠKANJCI",
        408: "GORIŠNICA",
        409: "ZAMUŠANI",
        410: "FORMIN",
        411: "GAJEVCI",
        412: "MALA VAS",
        413: "MURETINCI",
        414: "STOJNCI",
        415: "BUKOVCI",
        416: "NOVA VAS PRI MARKOVCIH",
        417: "MARKOVCI",
        418: "ZABOVCI",
        419: "ŠTURMOVCI",
        420: "POBREŽJE",
        421: "LANCOVA VAS",
        422: "SELA",
        423: "TRNOVEC",
        424: "APAČE",
        425: "LOVRENC NA DRAVSKEM POLJU",
        426: "ŽUPEČJA VAS",
        427: "PLETERJE",
        428: "MIHOVCE",
        429: "DRAGONJA VAS",
        430: "CIRKOVCE",
        431: "STAROŠINCE",
        432: "SPODNJE JABLANE",
        433: "ZGORNJE JABLANE",
        434: "PONGRCE",
        435: "ŠIKOLE",
        436: "SESTRŽE",
        437: "PODLOŽE",
        438: "LEŠJE",
        439: "PTUJSKA GORA",
        440: "DOKLECE",
        441: "STOGOVCI",
        442: "SKRBLJE",
        443: "JANŠKI VRH",
        444: "BOLEČKA VAS",
        445: "ZGORNJA PRISTAVA",
        446: "JUROVCI",
        447: "DRAVINJSKI VRH",
        448: "VAREJA",
        449: "LJUBSTAVA",
        450: "MAJSKI VRH",
        451: "GORCA",
        452: "DOLENA",
        453: "RODNI VRH",
        454: "JABLOVEC",
        455: "ZAKL",
        456: "DEŽNO",
        457: "PODLEHNIK",
        458: "SPODNJI LESKOVEC",
        459: "REPIŠČE",
        460: "DRAVCI",
        461: "GRADIŠČA",
        462: "DOLANE",
        463: "VELIKI VRH",
        464: "BELSKI VRH",
        465: "HRASTOVEC",
        466: "ZAVRČ",
        467: "GORIČAK",
        468: "DRENOVEC",
        469: "TURŠKI VRH",
        470: "KORENJAK",
        471: "PESTIKE",
        472: "GORENJSKI VRH",
        473: "BREZOVEC",
        474: "CIRKULANE",
        475: "GRUŠKOVEC",
        476: "MEJE",
        477: "MEDRIBNIK",
        478: "PARADIŽ",
        479: "PRISTAVA",
        480: "POHORJE",
        481: "SLATINA",
        482: "MALI OKIČ",
        483: "VELIKI OKIČ",
        484: "GRADIŠČE",
        485: "SKORIŠNJAK",
        486: "BELAVŠEK",
        487: "ZGORNJI LESKOVEC",
        488: "TRDOBOJCI",
        489: "MALA VARNICA",
        490: "VELIKA VARNICA",
        491: "LOŽINA",
        492: "GRUŠKOVJE",
        493: "SEDLAŠEK",
        494: "STANOŠINA",
        495: "KOZMINCI",
        496: "STRAJNA",
        497: "DOBRINA",
        498: "KOČICE",
        499: "SITEŽ",
        500: "BOLFENK",
        501: "SVEČA",
        502: "STOPERCE",
        503: "KUPČINJI VRH",
        504: "NADOLE",
        505: "ČERMOŽIŠE",
        506: "ŽETALE",
        507: "LOKAVEC",
        508: "ROŽENGRUNT",
        509: "ZGORNJA ŠČAVNICA",
        510: "SPODNJI DRAŽEN VRH",
        511: "ŽITENCE",
        512: "LEDINEK",
        513: "KREMBERK",
        514: "KRIVI VRH",
        515: "ZGORNJA ROČICA",
        516: "DRVANJA",
        517: "IHOVA",
        518: "TRIJE KRALJI",
        519: "TROTKOVA",
        520: "BENEDIKT",
        521: "SPODNJA ROČICA",
        522: "SPODNJI ŽERJAVCI",
        523: "ZGORNJI ŽERJAVCI",
        524: "ŽICE",
        525: "SPODNJI GASTERAJ",
        526: "SREDNJI GASTERAJ",
        527: "ZGORNJI GASTERAJ",
        528: "MALNA",
        529: "JUROVSKI DOL",
        530: "PARTINJE",
        531: "VARDA",
        532: "LENART V SLOVENSKIH GORICAH",
        533: "SPODNJI PORČIČ",
        534: "ZGORNJI PORČIČ",
        535: "STARI PORČIČ",
        536: "GRADIŠČE V SLOVENSKIH GORICAH",
        537: "ZGORNJE VERJANE",
        538: "OSEK",
        539: "BRENGOVA",
        540: "COGETINCI",
        541: "CERKVENJAK",
        542: "ANDRENCI",
        543: "ŽUPETINCI",
        544: "SMOLINCI",
        545: "ČAGONA",
        546: "SPODNJE VERJANE",
        547: "SPODNJA SENARSKA",
        548: "ZGORNJA SENARSKA",
        549: "RADEHOVA",
        550: "ZAMARKOVA",
        551: "MOČNA",
        552: "VINIČKA VAS",
        553: "ZGORNJA VOLIČINA",
        554: "SPODNJA VOLIČINA",
        555: "ŠETAROVA",
        556: "GOČOVA",
        557: "NADBIŠEC",
        558: "ZAVRH",
        559: "ČRMLJENŠAK",
        561: "STRAŽE",
        562: "ROGOZNICA",
        563: "CERŠAK",
        564: "ŠENTILJ V SLOVENSKIH GORICAH",
        565: "SELNICA OB MURI",
        566: "SLADKI VRH",
        567: "ZGORNJA VELKA",
        568: "TRATE",
        569: "SPODNJA VELKA",
        570: "PLODERŠNICA",
        571: "ROČICA",
        572: "SPODNJE HLAPJE",
        573: "ZGORNJE HLAPJE",
        574: "POČENIK",
        575: "ŠOMAT",
        576: "SREBOTJE",
        577: "ZGORNJI JAKOBSKI DOL",
        578: "POLIČKA VAS",
        579: "POLIČKI VRH",
        580: "VAJGEN",
        581: "KANIŽA",
        582: "ŠTRIHOVEC",
        583: "CIRKNICA",
        584: "ZGORNJE DOBRENJE",
        585: "KRESNICA",
        586: "PODIGRAC",
        587: "CIRINGA",
        588: "SVEČINA",
        589: "SLATINA",
        590: "ŠPIČNIK",
        591: "SLATINSKI DOL",
        592: "PLAČ",
        593: "ZGORNJE VRTIČE",
        594: "SPODNJE VRTIČE",
        595: "PLINTOVEC",
        596: "ZGORNJA KUNGOTA",
        597: "JEDLOVNIK",
        598: "GRUŠENA",
        599: "JURSKI VRH",
        600: "PESNICA",
        601: "VRŠNIK",
        602: "GAJ NAD MARIBOROM",
        603: "KOZJAK",
        604: "GRADIŠKA",
        605: "SPODNJE DOBRENJE",
        606: "RANCA",
        607: "JELENČE",
        608: "GAČNIK",
        609: "JARENINSKI DOL",
        610: "JARENINSKI VRH",
        611: "VUKOVSKI VRH",
        612: "VUKOVSKI DOL",
        613: "FLEKUŠEK",
        614: "SPODNJI JAKOBSKI DOL",
        615: "VUKOVJE",
        616: "KUŠERNIK",
        617: "VOSEK",
        618: "PESNIŠKI DVOR",
        619: "DOLNJA POČEHOVA",
        620: "MALI ROŠPOH",
        621: "MORSKI JAREK",
        622: "ŠOBER",
        623: "ZGORNJI SLEMEN",
        624: "VELIKI BOČ",
        625: "GRADIŠČE",
        626: "ZGORNJI BOČ",
        627: "SPODNJI BOČ",
        628: "ZGORNJA SELNICA",
        629: "JANŽEVA GORA",
        630: "GEREČJA VAS",
        631: "SPODNJA SELNICA",
        632: "SPODNJI SLEMEN",
        633: "SREDNJE",
        634: "JELOVEC",
        635: "BRESTRNICA",
        636: "KAMNICA",
        637: "ROŠPOH",
        638: "KRČEVINA",
        639: "POČEHOVA",
        640: "PEKEL",
        641: "DRAGUČOVA",
        642: "PERNICA",
        643: "LOŽANE",
        644: "RUPERČE",
        645: "GRUŠOVA",
        646: "METAVA",
        647: "TRČOVA",
        648: "NEBOVA",
        649: "CELESTRINA",
        650: "MALEČNIK",
        651: "HRENCA",
        652: "VODOLE",
        653: "KOŠAKI",
        654: "OREŠJE",
        655: "MELJE",
        656: "BREZJE",
        657: "MARIBOR GRAD",
        658: "KOROŠKA VRATA",
        659: "TABOR",
        660: "STUDENCI",
        661: "LIMBUŠ",
        662: "LAZNICA",
        663: "BISTRICA PRI LIMBUŠU",
        664: "BISTRICA PRI RUŠAH",
        665: "RUŠE",
        666: "ČINŽAT",
        667: "RUTA",
        668: "RDEČI BREG",
        669: "LOVRENC NA POHORJU",
        670: "RECENJAK",
        671: "KUMEN",
        672: "SMOLNIK",
        673: "LOBNICA",
        674: "ZGORNJI VRHOV DOL",
        675: "HRASTJE",
        676: "PEKRE",
        677: "ZGORNJE RADVANJE",
        678: "SPODNJE RADVANJE",
        679: "RAZVANJE",
        680: "TEZNO",
        681: "POBREŽJE",
        682: "ZRKOVCI",
        683: "DOGOŠE",
        684: "ZGORNJI DUPLEK",
        685: "ZAVRŠKA VAS",
        686: "ZIMICA",
        687: "JABLANCE",
        688: "ZGORNJA KORENA",
        689: "SPODNJA KORENA",
        690: "ŽIKARCE",
        691: "CIGLENCE",
        692: "SPODNJI DUPLEK",
        693: "MIKLAVŽ NA DRAVSKEM POLJU",
        694: "ROGOZA",
        695: "BOHOVA",
        696: "SPODNJE HOČE",
        697: "ZGORNJE HOČE",
        698: "PIVOLA",
        699: "HOČKO POHORJE",
        700: "SLIVNIŠKO POHORJE",
        701: "POLANA",
        702: "RANČE",
        703: "RADIZEL",
        704: "ČRETA",
        705: "SLIVNICA",
        706: "OREHOVA VAS",
        707: "HOTINJA VAS",
        708: "SKOKE",
        709: "LOKA PRI ROŠNJI",
        710: "DVORJANE",
        711: "VURBERK",
        712: "STARŠE",
        713: "ZLATOLIČJE",
        714: "PREPOLJE",
        715: "MARJETA NA DRAVSKEM POLJU",
        716: "RAČE",
        717: "FRAM",
        718: "KOPIVNIK",
        719: "PLANICA",
        720: "LOKA PRI FRAMU",
        721: "MORJE",
        722: "JEŠENCA",
        723: "PODOVA",
        724: "GORICA",
        725: "KOT",
        726: "PLANINA",
        727: "SMREČNO",
        728: "BOJTINA",
        729: "FRAJHAJM",
        730: "ŠMARTNO NA POHORJU",
        731: "OŠELJ",
        732: "URH",
        733: "REP",
        734: "MALO TINJE",
        735: "TINJSKA GORA",
        736: "VISOLE",
        737: "JURIŠNA VAS",
        738: "PREBUKOVJE",
        739: "KALŠE",
        740: "OGLJENŠAK",
        741: "BUKOVEC",
        742: "ZGORNJA POLSKAVA",
        743: "GABERNIK",
        744: "RITOZNOJ",
        745: "KOVAČA VAS",
        746: "ŠENTOVEC",
        747: "POKOŠE",
        748: "SPODNJA POLSKAVA",
        749: "STRAŽGONJCA",
        750: "VRHLOGA",
        751: "ČREŠNJEVEC",
        752: "SPODNJA NOVA VAS",
        753: "SLOVENSKA BISTRICA",
        754: "ZGORNJA BISTRICA",
        755: "SPODNJA LOŽNICA",
        756: "ZGORNJA LOŽNICA",
        757: "GLADOMES",
        758: "OKOŠKA VAS",
        759: "ZLOGONA GORA",
        760: "KORITNO",
        761: "BOŽJE",
        762: "BREZJE PRI OPLOTNICI",
        763: "OPLOTNICA",
        764: "ZGORNJE GRUŠOVJE",
        765: "VRHOLE PRI KONJICAH",
        766: "CIGONCA",
        767: "ŽABLJEK",
        768: "HOŠNICA",
        769: "LAPORJE",
        770: "VRHOLE PRI LAPORJU",
        771: "PRETREŽ",
        772: "ŠTATENBERG",
        773: "PEČKE",
        774: "STOPNO",
        775: "DEŽNO",
        776: "JELOVEC",
        777: "MODRAŽE",
        778: "BREZJE PRI POLJČANAH",
        779: "PEKEL",
        780: "STANOVSKO",
        781: "LUŠEČKA VAS",
        782: "POLJČANE",
        783: "STUDENICE",
        784: "HRASTOVEC",
        785: "STARI GRAD",
        786: "MLAKE",
        787: "PERNICE",
        788: "BRANIK NAD MUTO",
        789: "PODLIPJE",
        790: "BREZOVEC",
        791: "SUHI VRH PRI RADLJAH",
        792: "RADELCA",
        793: "VAS",
        794: "REMŠNIK",
        795: "BREZNI VRH",
        796: "ZGORNJA KAPLA",
        797: "SPODNJA KAPLA",
        798: "ZGORNJI VURMAT",
        799: "OŽBALT",
        800: "JAVNIK",
        801: "BREZNO",
        802: "KOZJI VRH",
        803: "SPODNJA VIŽINGA",
        804: "RADLJE OB DRAVI",
        805: "DOBRAVA",
        806: "ZGORNJA VIŽINGA",
        807: "SPODNJA MUTA",
        808: "ZGORNJA MUTA",
        809: "ZGORNJA GORTINA",
        810: "SPODNJA GORTINA",
        811: "DRAVČE",
        812: "ŠENTJANŽ NAD DRAVČAMI",
        813: "VUZENICA",
        814: "ŠENTVID",
        815: "PRIMOŽ NA POHORJU",
        816: "PLANINA",
        817: "VUHRED",
        818: "ORLICA",
        819: "JANŽEVSKI VRH",
        820: "PODVELKA",
        821: "RDEČI BREG II",
        822: "LEHEN",
        823: "RIBNICA NA POHORJU",
        824: "HUDI KOT",
        825: "LIBELIČE",
        826: "LIBELIŠKA GORA",
        827: "ČRNEŠKA GORA",
        828: "ČRNEČE",
        829: "DRAVOGRAD",
        830: "GRAD",
        831: "VIČ",
        832: "GORIŠKI VRH",
        833: "OJSTRICA",
        834: "DUH NA OJSTRICI",
        835: "VELKA",
        836: "KOZJI VRH",
        837: "VRATA",
        838: "TRBONJE",
        839: "DANIJEL PRI TRBONJAH",
        840: "OTIŠKI VRH I",
        841: "OTIŠKI VRH II",
        842: "DOBROVA",
        843: "SELOVEC",
        844: "ŠENTJANŽ PRI DRAVOGRADU",
        845: "PAMEČE",
        846: "GRADIŠČE",
        847: "VRHE",
        848: "SELE",
        849: "STARI TRG",
        850: "SLOVENJ GRADEC",
        851: "LEGEN",
        852: "GOLAVABUKA",
        853: "ŠMARTNO PRI SLOVENJ GRADCU",
        854: "PODGORJE",
        855: "ZGORNJI RAZBOR",
        856: "SPODNJI RAZBOR",
        857: "VELUNA",
        858: "GRAŠKA GORA",
        859: "VODRIŽ",
        860: "ŠMIKLAVŽ",
        861: "DOBRAVA",
        862: "BRDA",
        863: "ŠENTILJ POD TURJAKOM",
        864: "MISLINJA",
        865: "GORNJI DOLIČ",
        866: "ŠENTVID NAD VALDEKOM",
        867: "ZAVRŠE",
        868: "KOZJAK",
        869: "BELŠAK",
        870: "JAMNICA",
        871: "STROJNA",
        872: "ZELEN BREG",
        873: "SUHI VRH",
        874: "ŠENTANEL",
        875: "DOLGA BRDA",
        876: "BREZNICA",
        877: "STRAŽIŠČE",
        878: "TOLSTI VRH",
        879: "KOROŠKI SELOVEC",
        880: "BRDINJE",
        882: "RAVNE",
        883: "DOBJA VAS",
        884: "FARNA VAS",
        885: "POLJANA",
        886: "LOKOVICA",
        887: "LOM",
        888: "MEŽA TAKRAJ",
        889: "MEŽICA",
        890: "MEŽA ONKRAJ",
        891: "PREVALJE",
        892: "LEŠE",
        893: "ZAGRAD",
        894: "NAVRŠKI VRH",
        895: "PREŠKI VRH",
        896: "KOTLJE",
        897: "PODGORA",
        898: "URŠLJA GORA I",
        899: "URŠLJA GORA II",
        900: "ŽERJAV",
        901: "PLAT",
        902: "PODPECA",
        903: "TOPLA",
        904: "KOPRIVNA",
        905: "BISTRA",
        906: "ČRNA",
        907: "LUDRANSKI VRH",
        908: "JAVORJE",
        909: "LOGARSKA DOLINA",
        910: "SOLČAVA",
        911: "RADUHA",
        912: "KONJSKI VRH",
        913: "PRIMOŽ PRI LJUBNEM",
        914: "TER",
        915: "RADEGUNDA",
        916: "ŠMIHEL",
        917: "LEPA NJIVA",
        918: "LJUBIJA",
        919: "BREZJE",
        920: "MOZIRJE",
        921: "REČICA OB SAVINJI",
        922: "POLJANE",
        923: "LJUBNO",
        924: "SAVINA",
        925: "KRNICA",
        926: "LUČE",
        927: "PODVEŽA",
        928: "PODVOLOVLJEK",
        929: "LENART PRI GORNJEM GRADU",
        930: "FLORJAN PRI GORNJEM GRADU",
        931: "RADMIRJE",
        932: "ŠENTJANŽ",
        933: "HOMEC",
        934: "ZGORNJE POBREŽJE",
        935: "SPODNJA REČICA",
        936: "PRIHOVA",
        937: "LOKE",
        938: "KOKARJE",
        939: "PUSTO POLJE",
        940: "ŠMARTNO OB DRETI",
        941: "BOČNA",
        942: "GORNJI GRAD",
        943: "ŠMIKLAVŽ",
        944: "TIROSEK",
        945: "BELE VODE",
        946: "ŠENTVID PRI ZAVODNJU",
        947: "ZAVODNJE",
        948: "TOPOLŠICA",
        949: "RAVNE",
        950: "PLEŠIVEC",
        951: "HRASTOVEC",
        952: "CIRKOVCE",
        953: "PAKA",
        954: "LIPJE",
        955: "BEVČE",
        957: "ŠKALE",
        958: "GABERKE",
        959: "ŠOŠTANJ",
        960: "FLORJAN PRI ŠOŠTANJU",
        961: "SKORNO PRI ŠOŠTANJU",
        962: "GORENJE",
        963: "LOKOVICA",
        964: "VELENJE",
        965: "LAZE",
        966: "LOŽNICA",
        967: "KAVČE",
        968: "PODKRAJ",
        969: "VELIKI VRH",
        970: "GAVCE",
        971: "PAŠKA VAS",
        972: "ŠMARTNO OB PAKI",
        973: "REČICA OB PAKI",
        974: "PRELSKA",
        975: "VINSKA GORA",
        976: "ČRNOVA",
        977: "ŽELEZNO",
        978: "STUDENCE",
        979: "PONIKVA",
        980: "ANDRAŽ",
        981: "DOBRIČ",
        982: "PODVIN",
        983: "MALE BRASLOVČE",
        984: "LETUŠ",
        985: "DOBROVLJE",
        986: "PODVRH",
        987: "BRASLOVČE",
        988: "SPODNJE GORČE",
        989: "ŠMATEVŽ",
        990: "TRNAVA",
        991: "ORLA VAS",
        992: "POLZELA",
        993: "ZALOŽE",
        994: "ZALOG",
        995: "ŠEMPETER V SAVINJSKI DOLINI",
        996: "ŽALEC",
        997: "GOTOVLJE",
        998: "VELIKA PIREŠICA",
        999: "GORICA",
        1000: "LEVEC",
        1001: "PETROVČE",
        1002: "KASAZE",
        1003: "ZABUKOVICA",
        1004: "GORNJA VAS",
        1005: "PREBOLD",
        1006: "LATKOVA VAS",
        1007: "GRAJSKA VAS",
        1008: "GOMILSKO",
        1009: "OJSTRIŠKA VAS",
        1010: "PREKOPA",
        1011: "TEŠOVA",
        1012: "VRANSKO",
        1013: "JERONIM",
        1014: "LOČICA",
        1015: "ZAPLANINA",
        1016: "ČRNI VRH",
        1017: "MIKLAVŽ",
        1018: "MARIJA REKA",
        1019: "MATKE",
        1020: "PONGRAC",
        1021: "LIBOJE",
        1022: "REČICA",
        1023: "SLIVNO",
        1024: "RIFENGOZD",
        1025: "REKA",
        1026: "LAŠKO",
        1027: "ŠMIHEL",
        1028: "SEDRAŽ",
        1029: "LAHOMŠEK",
        1030: "LAHOMNO",
        1031: "OLEŠČE",
        1032: "ŠENTRUPERT",
        1033: "TROBNI DOL",
        1034: "MRZLO POLJE",
        1035: "JURKLOŠTER",
        1036: "VRH NAD LAŠKIM",
        1037: "LAŽIŠE",
        1038: "PLAZOVJE",
        1039: "RIMSKE TOPLICE",
        1040: "LOŽE",
        1041: "LOKAVEC",
        1042: "PANEČE",
        1043: "MARIJINA VAS",
        1044: "BRDCE NAD DOBRNO",
        1045: "KLANC",
        1046: "ZAVRH",
        1047: "ČREŠKOVA",
        1048: "SOCKA",
        1049: "LIPA",
        1050: "DOL",
        1051: "VERPETE",
        1052: "PODGORJE",
        1053: "LOKA",
        1054: "NOVAKE",
        1055: "HOMEC",
        1056: "DOBRNA",
        1057: "RUPE",
        1058: "ROŽNI VRH",
        1059: "LEMBERG",
        1060: "STRMEC PRI VOJNIKU",
        1061: "VIŠNJA VAS",
        1062: "MALE DOLE",
        1063: "BEZOVICA",
        1064: "TOMAŽ",
        1065: "VOJNIK TRG",
        1066: "VOJNIK OKOLICA",
        1067: "BREZOVA",
        1068: "ŠMARTNO",
        1069: "ŠENTJUNGERT",
        1070: "ARCLIN",
        1071: "ŠKOFJA VAS",
        1072: "ŠMIKLAVŽ",
        1073: "TRNOVLJE",
        1074: "SPODNJA HUDINJA",
        1075: "OSTROŽNO",
        1076: "MEDLOG",
        1077: "CELJE",
        1078: "LISCE",
        1079: "KOŠNICA",
        1080: "TREMERJE",
        1081: "ZAGRAD",
        1082: "TEHARJE",
        1083: "BUKOVŽLAK",
        1084: "PEČOVJE",
        1085: "KOMPOLE",
        1086: "PROŽINSKA VAS",
        1087: "SVETINA",
        1088: "PADEŠKI VRH",
        1089: "RESNIK",
        1090: "SKOMARJE",
        1091: "HUDINJA",
        1092: "PAKA I",
        1093: "SPODNJI DOLIČ",
        1094: "BREZEN",
        1095: "VITANJE",
        1096: "STENICA",
        1097: "LJUBNICA",
        1098: "LOŠKA GORA",
        1099: "GORENJE PRI ZREČAH",
        1100: "ZREČE",
        1101: "RADANA VAS",
        1102: "KRIŽEVEC",
        1103: "STRANICE",
        1104: "PRELOGE",
        1105: "ŠKALCE",
        1106: "BEZINA",
        1107: "TEPANJE",
        1108: "PEROVEC",
        1109: "SPODNJE GRUŠOVJE",
        1110: "LIČENCA",
        1111: "JERNEJ PRI LOČAH",
        1112: "KOBLE",
        1113: "ŽIČE",
        1114: "KONJIŠKA VAS",
        1115: "SLOVENSKE KONJICE",
        1116: "SLEMENE",
        1117: "TOLSTI VRH",
        1118: "LIPOGLAV",
        1119: "LOČE",
        1120: "ZGORNJE LAŽE",
        1121: "ZBELOVSKA GORA",
        1122: "DRAMLJE",
        1123: "PLETOVARJE",
        1124: "SLATINA",
        1125: "OSTROŽNO",
        1126: "DOLGA GORA",
        1127: "PONKVICA",
        1128: "PONIKVA",
        1129: "ZAGAJ",
        1130: "VODULE",
        1131: "MARIJA DOBJE",
        1132: "PRIMOŽ",
        1133: "GORIČICA",
        1134: "ZLATEČE",
        1135: "LOKARJE",
        1136: "KAMENO",
        1137: "GROBELNO",
        1138: "ŠENTJUR PRI CELJU",
        1139: "PODGRAD",
        1140: "KRAJNČICA",
        1141: "RIFNIK",
        1142: "TRATNA",
        1143: "GORICA PRI SLIVNICI",
        1144: "VODRUŽ",
        1145: "PLANINCA",
        1146: "VEZOVJE",
        1147: "SLIVNICA PRI CELJU",
        1148: "JAVORJE",
        1149: "VODUCE",
        1150: "KALOBJE",
        1151: "BREZJE",
        1152: "SUHO",
        1153: "PARIDOL",
        1154: "DOBRINA",
        1155: "LOKA PRI ŽUSMU",
        1156: "LOPACA",
        1157: "STRAŠKA GORCA",
        1158: "PRESEČNO",
        1159: "PLANINSKA VAS",
        1160: "LOKE PRI PLANINI",
        1161: "PLANINA",
        1162: "GOLOBINJEK",
        1163: "ŠENTVID PRI PLANINI",
        1164: "ZGORNJI GABRNIK",
        1165: "DREVENIK",
        1166: "ČAČA VAS",
        1167: "NEGONJE",
        1168: "RATANSKA VAS",
        1169: "CEROVEC",
        1170: "SPODNJE SEČOVO",
        1171: "SVETI FLORJAN",
        1172: "STRMEC",
        1173: "TLAKE",
        1174: "TRŠKA GORCA",
        1175: "DONAČKA GORA",
        1176: "TRLIČNO",
        1177: "DOBOVEC",
        1178: "ROGATEC",
        1179: "TRŽIŠČE",
        1180: "TOPOLE",
        1181: "TEKAČEVO",
        1182: "SPODNJA KOSTRIVNICA",
        1183: "LEMBERG OKOLICA",
        1184: "LEMBERG TRG",
        1185: "PIJOVCI",
        1186: "SLADKA GORA",
        1187: "VRH",
        1188: "DVOR",
        1189: "BOBOVO",
        1190: "PRELOGE",
        1191: "GRLIČE",
        1192: "KAČJI DOL",
        1193: "MALE RODNE",
        1194: "VELIKE RODNE",
        1195: "RJAVICA",
        1196: "BRESTOVEC",
        1197: "CESTE",
        1198: "PLAT",
        1199: "KRISTAN VRH",
        1200: "ŠMARJE PRI JELŠAH",
        1201: "ZADRŽE",
        1202: "SENOVICA",
        1203: "BODREŽ",
        1204: "ZGORNJE SELCE",
        1205: "PLATINOVEC",
        1206: "JEŠOVEC",
        1207: "BRECLJEVO",
        1208: "DOL",
        1209: "KORETNO",
        1210: "PRISTAVA",
        1211: "HAJNSKO",
        1212: "NEZBIŠE",
        1213: "KAMENCE",
        1214: "RAJNKOVEC",
        1215: "NIMNO",
        1216: "VIDOVICA",
        1217: "VONARJE",
        1218: "EMA",
        1219: "SODNA VAS",
        1220: "ROGINSKA GORCA",
        1221: "ZIBIKA",
        1222: "VRŠNA VAS",
        1223: "OREHOVEC",
        1224: "BABNA REKA",
        1225: "GROBELCE",
        1226: "BABNA GORA",
        1227: "TINSKO",
        1228: "SOPOTE",
        1229: "PODČETRTEK",
        1230: "IMENO",
        1231: "VIRŠTANJ",
        1232: "DOBLEŽIČE",
        1233: "VERAČE",
        1234: "BUČE",
        1235: "DRENSKO REBRO",
        1236: "ZAGORJE",
        1237: "PILŠTANJ",
        1238: "ZDOLE",
        1239: "VRENSKA GORCA",
        1240: "SEDLARJEVO",
        1241: "LASTNIČ",
        1242: "KOZJE",
        1243: "VETRNIK",
        1244: "GORJANE",
        1245: "PODSREDA",
        1246: "DEKMANCA",
        1247: "TREBČE",
        1248: "HRASTJE",
        1249: "PLES",
        1250: "KUNŠPERK",
        1251: "ZAGAJ",
        1252: "KRIŽE",
        1253: "OREŠJE",
        1254: "BUKOVJE",
        1255: "DRENOVEC",
        1256: "BREZOVICA",
        1257: "BIZELJSKO",
        1258: "PODGORJE",
        1259: "PAVLOVA VAS",
        1260: "SILOVEC",
        1261: "SROMLJE",
        1262: "ZGORNJA POHANCA",
        1263: "OKLUKOVA GORA",
        1264: "ARNOVO SELO",
        1265: "VOLČJE",
        1266: "CURNOVEC",
        1267: "BLATNO",
        1268: "DEDNJA VAS",
        1269: "PIŠECE",
        1270: "BREZJE",
        1271: "VITNA VAS",
        1272: "STARA VAS",
        1273: "ŽUPELEVEC",
        1274: "BOJSNO",
        1275: "PIRŠENBREG",
        1276: "GLOBOKO",
        1277: "MALI VRH",
        1278: "DEČNO SELO",
        1279: "ARTIČE",
        1280: "ZGORNJI OBREŽ",
        1281: "ŠENTLENART",
        1282: "BREZINA",
        1283: "ČRNC",
        1284: "BUKOŠEK",
        1285: "SELA",
        1286: "SLOGONSKO",
        1287: "VRHJE",
        1288: "JERESLAVEC",
        1289: "RAKOVEC",
        1290: "KAPELE",
        1291: "PODVINJE",
        1292: "GABRJE",
        1293: "VELIKI OBREŽ",
        1294: "RIGONCE",
        1295: "LOČE",
        1296: "MIHALOVEC",
        1297: "MOSTEC",
        1298: "TRNJE",
        1299: "ZAKOT",
        1300: "BREŽICE",
        1301: "KRŠKA VAS",
        1302: "CERKLJE",
        1303: "BUŠEČA VAS",
        1304: "STOJANSKI VRH",
        1305: "GLOBOČICE",
        1306: "ČATEŽ",
        1307: "CERINA",
        1308: "VELIKA DOLINA",
        1309: "KORITNO",
        1310: "NOVA VAS",
        1311: "KOSTANJEK",
        1312: "PLETERJE",
        1313: "ANOVEC",
        1314: "SREMIČ",
        1315: "VIDEM",
        1316: "STARA VAS",
        1317: "STARI GRAD",
        1318: "DOLENJA VAS",
        1319: "PESJE",
        1320: "DRNOVO",
        1321: "LESKOVEC",
        1322: "KRŠKO",
        1323: "VELIKI TRN",
        1324: "RAVNO",
        1325: "SENUŠE",
        1326: "RAKA",
        1327: "POVRŠJE",
        1328: "SMEDNIK",
        1329: "VELIKI PODLOG",
        1330: "VELIKO MRAŠEVO",
        1331: "KOSTANJEVICA",
        1332: "PODBOČJE",
        1333: "PLANINA",
        1334: "ČRNEČA VAS",
        1335: "OŠTRC",
        1336: "OREHOVEC",
        1337: "STRANJE",
        1338: "DOBROVA",
        1339: "REŠTANJ",
        1340: "MRČNA SELA",
        1341: "KOPRIVNICA",
        1342: "VELIKI DOL",
        1343: "VELIKI KAMEN",
        1344: "MALI KAMEN",
        1345: "ŠEDEM",
        1346: "GORENJI LESKOVEC",
        1347: "KALIŠOVEC",
        1348: "BREZJE",
        1349: "DOVŠKO",
        1350: "SENOVO",
        1351: "ARMEŠKO",
        1352: "LOKVE",
        1353: "RAZTEZ",
        1354: "GORICA",
        1355: "ANŽE",
        1356: "STOLOVNIK",
        1357: "BRESTANICA",
        1358: "DOLENJI LESKOVEC",
        1359: "PRESLADOL",
        1360: "ROŽNO",
        1361: "PODGORJE",
        1362: "OKROGLICE",
        1363: "RADEŽ",
        1364: "LOKA PRI ZIDANEM MOSTU",
        1365: "BREG",
        1366: "LEDINA",
        1367: "ZABUKOVJE",
        1368: "POKLEK",
        1369: "TRNOVEC",
        1370: "PODVRH",
        1371: "METNI VRH",
        1372: "ŽURKOV DOL",
        1373: "ŽIGRSKI VRH",
        1374: "KRAJNA BRDA",
        1375: "SELCE",
        1376: "KLADJE",
        1377: "BLANCA",
        1378: "BREZOVO",
        1379: "SEVNICA",
        1380: "ŠMARJE",
        1381: "BOŠTANJ",
        1382: "KOMPOLJE",
        1383: "CEROVEC",
        1384: "ŠENTJANŽ",
        1385: "PODBORŠT",
        1386: "KAL",
        1387: "CIRNIK",
        1388: "PIJAVICE",
        1389: "GOVEJI DOL",
        1390: "VRH",
        1391: "LOG",
        1392: "HUBAJNICA",
        1393: "STUDENEC",
        1394: "BUČKA",
        1395: "TELČE",
        1396: "KRSINJI VRH",
        1397: "TRŽIŠČE",
        1398: "BISTRICA",
        1399: "ŠENTRUPERT",
        1400: "STRAŽA",
        1401: "NOVO ZABUKOVJE",
        1402: "SELO-MIRNA",
        1403: "ČATEŽ",
        1404: "DOLGA NJIVA",
        1405: "MALI VIDEM",
        1406: "ŠKOVEC",
        1407: "ROJE",
        1408: "ŠEVNICA",
        1409: "BREZOVICA",
        1410: "MIRNA",
        1411: "OSTROŽNIK",
        1412: "MOKRONOG",
        1413: "LAKNICE",
        1414: "JELŠEVEC",
        1415: "TREBELNO",
        1416: "STARO ZABUKOVJE",
        1417: "ORNUŠKA VAS",
        1418: "LUKOVEK",
        1419: "PONIKVE",
        1420: "ČEŠNJEVEK",
        1421: "MEDVEDJE SELO",
        1422: "TREBNJE",
        1423: "VRHTREBNJE",
        1424: "ŠTEFAN",
        1425: "VELIKA LOKA",
        1426: "PRAPREČE",
        1427: "VELIKI GABER",
        1428: "ZAGORICA",
        1429: "STEHANJA VAS",
        1430: "KNEŽJA VAS",
        1431: "DOBRNIČ",
        1432: "KORITA",
        1433: "SELA PRI ŠUMBERKU",
        1434: "GORNJI KRIŽ",
        1435: "REBER",
        1436: "ŽUŽEMBERK",
        1437: "ŠMIHEL PRI ŽUŽEMBERKU",
        1438: "ŽVIRČE",
        1439: "HINJE",
        1440: "SELA PRI HINJAH",
        1441: "VELIKO LIPJE",
        1442: "STAVČA VAS",
        1443: "DVOR",
        1444: "AJDOVEC",
        1445: "BREZOVA REBER",
        1446: "GORENJE POLJE",
        1447: "GORENJA STRAŽA",
        1448: "PREČNA",
        1449: "GLOBODOL",
        1450: "GOLOBINJEK",
        1451: "MIRNA PEČ",
        1452: "HMELJČIČ",
        1453: "ZAGORICA",
        1454: "DALJNI VRH",
        1455: "BRŠLJIN",
        1456: "NOVO MESTO",
        1457: "ŽDINJA VAS",
        1458: "ČREŠNJICE",
        1459: "HERINJA VAS",
        1460: "ŠENTPETER",
        1461: "ŽALOVIČE",
        1462: "ZBURE",
        1463: "ZAGRAD",
        1464: "DOLE",
        1465: "STARA VAS",
        1466: "GORENJA VAS",
        1467: "DRUŽINSKA VAS",
        1468: "BELA CERKEV",
        1469: "TOMAŽJA VAS",
        1470: "DOBRAVA",
        1471: "MRŠEČA VAS",
        1472: "OSTROG",
        1473: "GRADIŠČE",
        1474: "POLHOVICA",
        1475: "GORENJA OREHOVICA",
        1476: "ŠENTJERNEJ",
        1477: "VRHPOLJE",
        1478: "GABRJE",
        1479: "BRUSNICE",
        1480: "POTOV VRH",
        1481: "SMOLENJA VAS",
        1482: "RAGOVO",
        1483: "KANDIJA",
        1484: "ŠMIHEL PRI NOVEM MESTU",
        1485: "GOTNA VAS",
        1486: "STOPIČE",
        1487: "ZAJČJI VRH",
        1488: "HRUŠICA",
        1489: "CEROVEC",
        1490: "TEŽKA VODA",
        1491: "LAKOVNICE",
        1492: "STRANSKA VAS",
        1493: "VELIKI PODLJUBEN",
        1494: "JURKA VAS",
        1495: "TOPLICE",
        1496: "PODTURN",
        1497: "PODSTENICE",
        1498: "POLJANE",
        1499: "STARE ŽAGE",
        1500: "DOBINDOL",
        1501: "VINJA VAS",
        1502: "DOLE",
        1503: "SEKULIČI",
        1504: "HRAST PRI JUGORJU",
        1505: "BUŠINJA VAS",
        1506: "LOKVICA",
        1507: "GRABROVEC",
        1508: "BOJANJA VAS",
        1509: "RADOVICA",
        1510: "SLAMNA VAS",
        1511: "DRAŠIČI",
        1512: "BOŽAKOVO",
        1513: "RADOVIČI",
        1514: "ROSALNICE",
        1515: "METLIKA",
        1516: "PRIMOSTEK",
        1517: "DOBRAVICE",
        1518: "GRADAC",
        1519: "PODZEMELJ",
        1520: "ŠTALE",
        1521: "ČRMOŠNJICE",
        1522: "PRIBIŠJE",
        1523: "ŠTREKLJEVEC",
        1524: "SODJI VRH",
        1525: "ČREŠNJEVEC",
        1526: "VINJI VRH",
        1527: "SEMIČ",
        1528: "BREZJE PRI VINJEM VRHU",
        1529: "KOT",
        1530: "BLATNIK",
        1531: "GOLOBINJEK",
        1532: "PLANINA",
        1533: "KLEČ",
        1534: "PETROVA VAS",
        1535: "ČRNOMELJ",
        1536: "TALČJI VRH",
        1537: "BUKOVA GORA",
        1538: "DOLENJA PODGORA",
        1539: "MAVRLEN",
        1540: "DOBLIČE",
        1541: "LOKA",
        1542: "ZASTAVA",
        1543: "KRASINEC",
        1544: "GRIBLJE",
        1545: "BEDENJ",
        1546: "TRIBUČE",
        1547: "BUTORAJ",
        1548: "GOLEK",
        1549: "TANČA GORA",
        1550: "DRAGATUŠ",
        1551: "BELČJI VRH",
        1552: "ADLEŠIČI",
        1553: "BOJANCI",
        1554: "HRAST PRI VINICI",
        1555: "NOVA LIPA",
        1556: "STARA LIPA",
        1557: "STARI TRG OB KOLPI",
        1558: "SODEVCI",
        1559: "RADENCI",
        1560: "SINJI VRH",
        1561: "DAMELJ",
        1562: "UČAKOVCI",
        1563: "VINICA",
        1564: "PRELOKA",
        1565: "MARINDOL",
        1566: "ŽUNIČI",
        1567: "POTISKAVEC",
        1568: "PODTABOR",
        1569: "POLOM",
        1570: "STARI LOG",
        1571: "SMUKA",
        1572: "ROG",
        1573: "MALA GORA",
        1574: "KOBLARJI",
        1575: "STARA CERKEV",
        1576: "MAHOVNIK",
        1577: "KOČEVJE",
        1578: "ŽELJNE",
        1579: "RAJHENAV",
        1580: "ONEK",
        1581: "LIVOLD",
        1582: "KOČE",
        1583: "GOTENICA",
        1584: "DRAGA",
        1585: "TRAVA",
        1586: "ŽURGE",
        1587: "OSILNICA",
        1588: "BOSLJIVA LOKA",
        1589: "BOROVEC",
        1590: "KOČEVSKA REKA",
        1591: "NOVI LAZI",
        1592: "ŠTALCERJI",
        1593: "ČRNI POTOK",
        1594: "MOZELJ",
	    1595: "KUMROVA VAS",
        1596: "BREZJE",
        1597: "KOPRIVNIK",
	    1598: "HRIB",
        1599: "NEMŠKA LOKA",
        1600: "KNEŽJA LIPA",
        1601: "ČEPLJE",
        1602: "PREDGRAD",
        1603: "DOL",
        1604: "SPODNJI LOG",
        1605: "RAJNDOL",
        1606: "ŠKRILJ",
        1607: "SUHOR",
        1608: "BRIGA",
        1609: "BANJA LOKA",
        1610: "VRH",
        1611: "FARA",
        1612: "PIRČE",
        1613: "KUŽELJ",
        1614: "SLEMENA",
        1615: "PODPOLJANE",
        1616: "VELIKE POLJANE",
        1617: "SUŠJE",
        1618: "VINICE",
        1619: "SODRAŽICA",
        1620: "ŽIMARICE",
        1621: "GORA",
        1622: "ZAMOSTEC",
        1623: "JURJEVICA",
        1624: "GORENJA VAS",
        1625: "RIBNICA",
        1626: "GORIČA VAS",
        1627: "PRIGORICA",
        1628: "DANE",
        1629: "DOLENJA VAS",
        1630: "RAKITNICA",
        1631: "GRČARICE",
        1632: "OTOK I",
        1633: "GORENJE JEZERO",
        1634: "DANE",
        1635: "PODCERKEV",
        1636: "LOŽ",
        1637: "STARI TRG PRI LOŽU",
        1638: "KNEŽJA NJIVA",
        1639: "VRHNIKA",
        1640: "RETJE",
        1641: "HRIB",
        1642: "TRAVNIK",
        1643: "POLJANE",
        1644: "IGA VAS",
        1645: "VIŠEVEK",
        1646: "PUDOB",
        1647: "NADLESK",
        1648: "KOZARIŠČE",
        1649: "VRH",
        1650: "BABNA POLICA",
        1651: "BABNO POLJE",
        1652: "RAKITNA",
        1653: "OSREDEK",
        1654: "ŽILCE",
        1655: "KRANJČE",
        1656: "OTAVE",
        1657: "KOŽLJEK",
        1658: "BEZULJAK",
        1659: "RAKEK",
        1660: "UNEC",
        1661: "BEGUNJE PRI CERKNICI",
        1662: "SELŠČEK",
        1663: "CAJNARJE",
        1664: "ŠTRUKLJEVA VAS",
        1665: "JERŠIČE",
        1666: "RAVNE PRI ŽILCAH",
        1667: "HITENO",
        1668: "ZALES",
        1669: "KREMENCA",
        1670: "ULAKA",
        1671: "GRADIŠKO",
        1672: "RAVNIK",
        1673: "VELIKE BLOKE",
        1674: "RADLEK",
        1675: "GRAHOVO",
        1676: "CERKNICA",
        1677: "DOLENJA VAS",
        1678: "OTOK II",
        1679: "LIPSENJ",
        1680: "ŽEROVNICA",
        1681: "BLOČICE",
        1682: "STUDENO",
        1683: "NOVA VAS",
        1684: "VOLČJE",
        1685: "KRAJIČ",
        1686: "STRMCA",
        1687: "VELIKI VRH",
        1688: "RUNARSKO",
        1689: "BENETE",
        1690: "STUDENEC",
        1691: "HUDI VRH",
        1692: "METULJE",
        1693: "TOPOL",
        1694: "RAVNE PRI TOPOLU",
        1695: "KARLOVŠKO PREDMESTJE",
        1696: "RUDNIK",
        1697: "LANIŠČE",
        1698: "PIJAVA GORICA",
        1699: "DOBRAVICA",
        1700: "IG",
        1701: "IŠKA LOKA",
        1702: "TOMIŠELJ",
        1703: "JEZERO",
        1704: "KAMNIK",
        1705: "PRESERJE",
        1706: "VRBLJENE",
        1707: "IŠKA VAS",
        1708: "GOLO",
        1709: "ŽELIMLJE",
        1710: "GRADIŠČE",
        1711: "TURJAK",
        1712: "ZAPOTOK",
        1713: "KRVAVA PEČ",
        1714: "SELO PRI ROBU",
        1715: "OSOLNIK",
        1716: "ULAKA",
        1717: "VELIKE LAŠČE",
        1718: "DVORSKA VAS",
        1719: "LUŽARJI",
        1720: "KRAKOVSKO PREDMESTJE",
        1721: "GRADIŠČE I",
        1722: "TRNOVSKO PREDMESTJE",
        1723: "VIČ",
        1724: "BREZOVICA",
        1725: "AJDOVŠČINA",
        1726: "ŠENTPETER",
        1727: "POLJANSKO PREDMESTJE",
        1728: "LJUBLJANA MESTO",
        1729: "ŠMARTNO OB SAVI",
        1730: "MOSTE",
        1731: "UDMAT",
        1732: "ŠTEPANJA VAS",
        1733: "BIZOVIK",
        1734: "JEŽICA",
        1735: "STOŽICE",
        1736: "BRINJE I",
        1737: "TABOR",
        1738: "DRAVLJE",
        1739: "ZGORNJA ŠIŠKA",
        1740: "SPODNJA ŠIŠKA",
        1741: "VODICE",
        1742: "REPNJE",
        1743: "BUKOVICA",
        1744: "ŠINKOV TURN",
        1745: "VESCA",
        1746: "RAŠICA",
        1747: "POLJE",
        1748: "SKARUČNA",
        1749: "GAMELJNE",
        1750: "ŠMARTNO POD ŠMARNO GORO",
        1751: "TACEN",
        1752: "STANEŽIČE",
        1753: "VIŽMARJE",
        1754: "ŠENTVID NAD LJUBLJANO",
        1755: "GLINCE",
        1756: "ČRNUČE",
        1757: "NADGORICA",
        1758: "DRAGOMELJ",
        1759: "PODGORICA",
        1760: "BERIČEVO",
        1761: "DOL PRI LJUBLJANI",
        1762: "PODGORA",
        1763: "KLEČE",
        1764: "KRIŽEVSKA VAS",
        1765: "VINJE",
        1766: "PETELINJE",
        1767: "DOLSKO",
        1768: "SENOŽETI",
        1769: "LAZE",
        1770: "KAŠELJ",
        1771: "ZADOBROVA",
        1772: "SLAPE",
        1773: "DOBRUNJE",
        1774: "PODMOLNIK",
        1775: "SOSTRO",
        1776: "LIPOGLAV",
        1777: "JAVOR",
        1778: "VOLAVLJE",
        1779: "TREBELJEVO",
        1780: "BLEČJI VRH",
        1781: "POLICA",
        1782: "STARA VAS",
        1783: "GROSUPLJE - NASELJE",
        1784: "STRANSKA VAS",
        1785: "SELA",
        1786: "ŠMARJE",
        1787: "MALI VRH",
        1788: "VINO",
        1789: "PONOVA VAS",
        1790: "SLIVNICA",
        1791: "ŽALNA",
        1792: "LUČE",
        1793: "ILOVA GORA",
        1794: "RAČNA",
        1795: "VELIKE LIPLJENE",
        1796: "CESTA",
        1797: "ZDENSKA VAS",
        1798: "ZAGORICA",
        1799: "VIDEM DOBREPOLJE",
        1800: "PODGORA",
        1801: "KOMPOLJE",
        1802: "LESKOVEC",
        1803: "METNAJ",
        1804: "ČEŠNJICE",
        1805: "BUKOVICA",
        1806: "SOBRAČE",
        1807: "TEMENICA",
        1808: "MALE DOLE",
        1809: "ŠENTVID",
        1810: "STIČNA",
        1811: "DOBRAVA",
        1812: "DEDNI DOL",
        1813: "VIŠNJA GORA",
        1814: "KRIŠKA VAS",
        1815: "DRAGA",
        1816: "HUDO",
        1817: "RADOHOVA VAS",
        1818: "PODBORŠT",
        1819: "VELIKE PECE",
        1820: "GORENJA VAS",
        1821: "VRHE",
        1822: "MULJAVA",
        1823: "DOB",
        1824: "VALIČNA VAS",
        1825: "ZAGRADEC",
        1826: "SUŠICA",
        1827: "KRKA",
        1828: "PODBUKOVJE",
        1829: "VELIKO GLOBOKO",
        1830: "AMBRUS",
        1831: "VIŠNJE",
        1832: "VAČE",
        1833: "SAVA PRI LITIJI",
        1834: "KONJ",
        1835: "HOTIČ",
        1836: "KRESNICE",
        1837: "KRESNIŠKI VRH",
        1838: "LITIJA",
        1839: "JABLANICA",
        1840: "POLŠNIK",
        1841: "VELIKA GOBA",
        1842: "DOLE PRI LITIJI",
        1843: "PRELESJE",
        1844: "VODICE",
        1845: "MORAVČE",
        1846: "LIBERGA",
        1847: "ŠMARTNO",
        1848: "ŠTANGA",
        1849: "VINTARJEVEC",
        1850: "JEŽNI VRH",
        1851: "GRADIŠČE",
        1852: "POLJANE",
        1853: "OKROG",
        1854: "TIHABOJ",
        1855: "HRASTNIK MESTO",
        1856: "DOL PRI HRASTNIKU",
        1857: "MARNO",
        1858: "TURJE",
        1859: "GORE",
        1860: "ŠIRJE",
        1861: "OBREŽJE",
        1862: "RADEČE",
        1863: "PODKRAJ",
        1864: "SVIBNO",
        1865: "NJIVICE",
        1866: "HOTEMEŽ",
        1867: "VRHOVO",
        1868: "KNEZDOL",
        1869: "ČEČE",
        1870: "OJSTRO",
        1871: "TRBOVLJE",
        1872: "DOBOVEC",
        1873: "HRASTNIK PRI TROJANAH",
        1874: "BREZJE",
        1875: "ČEMŠENIK",
        1876: "JESENOVO",
        1877: "VRHE I",
        1878: "KOTREDEŽ",
        1879: "RŽIŠE",
        1880: "KOLOVRAT",
        1881: "KANDRŠE",
        1882: "ZABAVA",
        1883: "ŠEMNIK",
        1884: "LOKE PRI ZAGORJU",
        1885: "POTOŠKA VAS",
        1886: "ZAGORJE MESTO",
        1887: "ŠENTLAMBERT",
        1888: "KONJŠICA",
        1889: "PODKUM",
        1890: "ČRNA",
        1891: "ŽUPANJE NJIVE",
        1892: "BISTRIČICA",
        1893: "STRANJE",
        1894: "GODIČ",
        1895: "GOZD",
        1896: "TUČNA",
        1897: "NEVLJE",
        1898: "MEKINJE",
        1899: "KOŠIŠE",
        1900: "TUNJICE",
        1901: "MLAKA",
        1902: "KLANEC",
        1903: "NASOVČE",
        1904: "KAPLJA VAS",
        1905: "MOSTE",
        1906: "SUHADOLE",
        1907: "KRIŽ",
        1908: "PODGORJE",
        1909: "ŠMARCA",
        1910: "VOLČJI POTOK",
        1911: "KAMNIK",
        1912: "PALOVČE",
        1913: "PODHRUŠKA",
        1914: "ZNOJILE",
        1915: "HRUŠEVKA",
        1916: "LOKE",
        1917: "ŠMARTNO V TUHINJU",
        1918: "PŠAJNOVICA",
        1919: "ZGORNJI TUHINJ",
        1920: "HRIBI",
        1921: "ŠPITALIČ",
        1922: "ZGORNJI MOTNIK",
        1923: "MOTNIK",
        1924: "TROJANE",
        1925: "UČAK",
        1926: "ŠENTOŽBOLT",
        1927: "BLAGOVICA",
        1928: "ČEŠNJICE",
        1929: "KORENO",
        1930: "ŽIROVŠE",
        1931: "KRAŠNJA",
        1932: "ZLATO POLJE",
        1933: "LUKOVICA",
        1934: "RAFOLČE",
        1935: "ROVA",
        1936: "RADOMLJE",
        1937: "HOMEC",
        1938: "MENGEŠ",
        1939: "DOBENO",
        1940: "LOKA",
        1943: "DOB",
        1944: "PREVOJE",
        1945: "KRTINA",
        1946: "STUDENEC",
        1947: "SPODNJE KOSEZE",
        1948: "KRAŠCE",
        1949: "NEGASTRN",
        1950: "LIMBARSKA GORA",
        1951: "ZGORNJE KOSEZE",
        1952: "PEČE",
        1953: "DRTIJA",
        1954: "VELIKA VAS",
        1955: "MORAVČE",
        1956: "VRHPOLJE",
        1957: "TROJICA",
        1958: "BREZOVICA",
        1959: "DOMŽALE",
        1961: "TRZIN",
        1962: "DEPALA VAS",
        1963: "ŠTUDA",
        1964: "IHAN",
        1965: "BRDO",
        1966: "SELO",
        1967: "MOŠE",
        1968: "HRAŠE",
        1969: "ZAPOGE",
        1970: "SMLEDNIK",
        1971: "ZBILJE",
        1972: "SENICA",
        1973: "MEDVODE",
        1974: "ZGORNJE PIRNIČE",
        1975: "SPODNJE PIRNIČE",
        1976: "PRESKA",
        1977: "SORA",
        1978: "STUDENČICE",
        1979: "ŽLEBE",
        1980: "TOPOL",
        1981: "GOLO BRDO",
        1982: "ŠUJICA",
        1983: "BABNA GORA",
        1984: "SELO NAD POLHOVIM GRADCEM",
        1985: "ČRNI VRH",
        1986: "POLHOV GRADEC",
        1987: "SETNIK",
        1988: "BUTAJNOVA",
        1989: "ŠENTJOŠT",
        1990: "ŽAŽAR",
        1991: "VRZDENEC",
        1992: "HORJUL",
        1993: "ZAKLANEC",
        1994: "DOBROVA",
        1995: "PODSMREKA",
        1996: "LOG",
        1997: "BLATNA BREZOVICA",
        1998: "VELIKA LIGOJNA",
        1999: "PODLIPA",
        2000: "ZAPLANA",
        2001: "STARA VRHNIKA",
        2002: "VRHNIKA",
        2003: "VERD",
        2004: "BOROVNICA",
        2005: "BREG",
        2006: "ZABOČEVO",
        2007: "VRH",
        2008: "ROVTE",
        2009: "PETKOVEC",
        2010: "ŽIBRŠE",
        2011: "MEDVEDJE BRDO",
        2012: "NOVI SVET",
        2013: "HOTEDRŠICA",
        2014: "RAVNIK",
        2015: "GORENJI LOGATEC",
        2016: "BLEKOVA VAS",
        2017: "DOLENJI LOGATEC",
        2018: "LAZE",
        2019: "GRČAREVEC",
        2020: "LEDINICA",
        2021: "DOBRAČEVA",
        2022: "ŽIROVSKI VRH",
        2023: "ŽIRI",
        2024: "VRSNIK II",
        2025: "OPALE",
        2026: "PEVNO",
        2027: "STARA LOKA",
        2028: "DORFARJE",
        2029: "STARI DVOR",
        2030: "SUHA",
        2031: "GODEŠIČ",
        2032: "RETEČE",
        2033: "DRAGA",
        2034: "PUŠTAL",
        2035: "ŠKOFJA LOKA",
        2036: "SOPOTNICA",
        2037: "PODVRH",
        2038: "DOLENČICE",
        2039: "GORENJA RAVAN",
        2040: "PODOBENO",
        2041: "VISOKO",
        2042: "ZMINEC",
        2043: "BARBARA",
        2044: "OŽBOLT",
        2045: "STANIŠE",
        2046: "KOVSKI VRH",
        2047: "DOBJE",
        2048: "DOLENJE BRDO",
        2049: "HOTAVLJE",
        2050: "LESKOVICA",
        2051: "PODJELOVO BRDO",
        2052: "LANIŠE",
        2053: "JAVORJEV DOL",
        2054: "KOPRIVNIK",
        2055: "STARA OSELICA",
        2056: "TREBIJA",
        2057: "GORENJA VAS",
        2058: "DOLENJA DOBRAVA",
        2059: "LUČINE",
        2060: "DRAŽGOŠE",
        2061: "PODLONK",
        2062: "STUDENO",
        2063: "KALIŠE",
        2064: "SELCA",
        2065: "BUKOVŠČICA",
        2066: "DOLENJA VAS",
        2067: "BUKOVICA",
        2068: "ZGORNJA LUŠA",
        2069: "LENART",
        2070: "MARTINJ VRH",
        2071: "ŽELEZNIKI",
        2072: "ZALI LOG",
        2073: "DANJE",
        2074: "SORICA",
        2075: "DAVČA",
        2076: "ZGORNJE JEZERSKO",
        2077: "SPODNJE JEZERSKO",
        2078: "KOKRA",
        2079: "ŠENTURŠKA GORA",
        2080: "ŠTEFANJA GORA",
        2081: "OLŠEVEK",
        2082: "TUPALIČE",
        2083: "BREG OB KOKRI",
        2084: "BELA",
        2085: "BABNI VRT",
        2086: "SREDNJA VAS",
        2087: "GOLNIK",
        2088: "GORIČE",
        2089: "TENETIŠE",
        2090: "VOJVODIN BORŠT I",
        2091: "VOJVODIN BORŠT II",
        2092: "DUPLJE",
        2093: "PODBREZJE",
        2094: "ŽEJE",
        2095: "STRAHINJ",
        2096: "NAKLO",
        2097: "OKROGLO",
        2098: "STRUŽEVO",
        2099: "PIVKA",
        2100: "KRANJ",
        2101: "RUPA",
        2102: "KOKRICA",
        2103: "PREDOSLJE",
        2104: "SUHA",
        2105: "BRITOF",
        2106: "VISOKO",
        2107: "LUŽE",
        2108: "VELESOVO",
        2109: "ČEŠNJEVEK",
        2110: "GRAD",
        2111: "PŠATA",
        2112: "ŠMARTNO",
        2113: "ZALOG",
        2114: "DOBRAVA",
        2115: "LAHOVČE",
        2116: "SPODNJI BRNIK",
        2117: "ZGORNJI BRNIK",
        2118: "CERKLJE",
        2119: "ŠENČUR",
        2120: "PRIMSKOVO",
        2121: "KLANEC",
        2122: "HUJE",
        2123: "ČIRČE",
        2124: "HRASTJE",
        2125: "VOGLJE",
        2126: "TRBOJE",
        2127: "NEMILJE",
        2128: "ZGORNJA BESNICA",
        2129: "SPODNJA BESNICA",
        2130: "PŠEVO",
        2131: "STRAŽIŠČE",
        2132: "BITNJE",
        2133: "KRIŽNA GORA",
        2134: "ŽABNICA",
        2135: "DRULOVKA",
        2136: "BREG OB SAVI",
        2137: "JAMA",
        2138: "PRAŠE",
        2139: "MAVČIČE",
        2140: "PODREČA",
        2141: "PODLJUBELJ",
        2142: "LOM POD STORŽIČEM",
        2143: "TRŽIČ",
        2144: "BISTRICA",
        2145: "LEŠE",
        2146: "KOVOR",
        2147: "KRIŽE",
        2148: "SENIČNO",
        2149: "ŽIGANJA VAS",
        2150: "ZVIRČE",
        2151: "BEGUNJE",
        2152: "SREDNJA VAS",
        2153: "OTOK",
        2154: "NOVA VAS",
        2155: "HRAŠE",
        2156: "RADOVLJICA",
        2157: "PREDTRG",
        2158: "MOŠNJE",
        2159: "BREZJE",
        2160: "LJUBNO",
        2161: "ZALOŠE",
        2162: "DOBRAVA PRI KROPI",
        2163: "KAMNA GORICA",
        2164: "LANCOVO",
        2165: "KROPA",
        2166: "ČEŠNJICA PRI KROPI",
        2167: "RATEČE",
        2168: "PODKOREN",
        2169: "KRANJSKA GORA",
        2170: "GOZD",
        2171: "DOVJE",
        2172: "HRUŠICA",
        2173: "PLAVŠKI ROVT",
        2174: "PLANINA",
        2175: "JESENICE",
        2176: "BLEJSKA DOBRAVA",
        2177: "JAVORNIŠKI ROVT",
        2178: "KOROŠKA BELA",
        2179: "POTOKI",
        2180: "ŽIROVNICA",
        2181: "ZABREZNICA",
        2182: "DOSLOVČE",
        2183: "ZASIP",
        2184: "PODHOM",
        2185: "SPODNJE GORJE",
        2186: "VIŠELNICA I",
        2187: "ZGORNJE GORJE",
        2188: "POLJŠICA",
        2189: "REČICA",
        2190: "BLED",
        2191: "ŽELEČE",
        2192: "RIBNO",
        2193: "SELO PRI BLEDU",
        2194: "BOHINJSKA BELA",
        2195: "GORJUŠE",
        2196: "BOHINJSKA ČEŠNJICA",
        2197: "BOHINJSKA SREDNJA VAS",
        2198: "STUDOR",
        2199: "SAVICA",
        2200: "BOHINJSKA BISTRICA",
        2201: "NOMENJ",
        2202: "NEMŠKI ROVT",
        2203: "TRENTA LEVA",
        2204: "TRENTA DESNA",
        2205: "STRMEC",
        2206: "LOG POD MANGARTOM",
        2207: "BOVEC",
        2208: "KORITNICA",
        2209: "SOČA DESNA",
        2210: "SOČA LEVA",
        2211: "ČEZSOČA",
        2212: "ŽAGA",
        2213: "SRPENICA",
        2214: "BREGINJ",
        2215: "ROBIDIŠČE",
        2216: "LOGJE",
        2217: "SEDLO",
        2218: "BORJANA",
        2219: "KRED",
        2220: "STARO SELO",
        2221: "SUŽID",
        2222: "SVINO",
        2223: "KOBARID",
        2224: "TRNOVO",
        2225: "DREŽNICA",
        2226: "VRSNO",
        2227: "SMAST",
        2228: "LADRA",
        2229: "IDRSKO",
        2230: "LIVEK",
        2231: "KAMNO",
        2232: "VOLARJE",
        2233: "DOLJE",
        2234: "ZATOLMIN",
        2235: "ČADRG",
        2236: "ŽABČE",
        2237: "LJUBINJ",
        2238: "PODMELEC",
        2239: "KNEŽA",
        2240: "RUT",
        2241: "STRŽIŠČE",
        2242: "PODBRDO",
        2243: "OBLOKE",
        2244: "GRAHOVO",
        2245: "PONIKVE",
        2246: "MOST NA SOČI",
        2247: "POLJUBINJ",
        2248: "TOLMIN",
        2249: "VOLČE",
        2250: "MODREJCE",
        2251: "KOZARŠČE",
        2252: "ČIGINJ",
        2253: "RUTE",
        2254: "SELA",
        2255: "LOM",
        2256: "IDRIJA PRI BAČI",
        2257: "SLAP",
        2258: "PEČINE",
        2259: "ŠENTVIŠKA GORA",
        2260: "PRAPETNO BRDO",
        2261: "GORENJA TREBUŠA",
        2262: "KAL NAD KANALOM",
        2263: "AVČE",
        2264: "DOBLAR",
        2265: "ROČINJ",
        2266: "AJBA",
        2267: "BODREŽ",
        2268: "VRH KANALSKI",
        2269: "KANAL",
        2270: "MORSKO",
        2271: "GORENJA VAS",
        2272: "IDRIJA NAD KANALOM",
        2273: "UKANJE",
        2274: "ANHOVO",
        2275: "PLAVE",
        2276: "DESKLE",
        2277: "MIRNIK",
        2278: "KOŽBANA",
        2279: "KRASNO",
        2280: "VRHOVLJE",
        2281: "ŠMARTNO",
        2282: "VEDRIJAN",
        2283: "VIŠNJEVIK",
        2284: "NEBLO",
        2285: "BILJANA",
        2286: "MEDANA",
        2287: "VIPOLŽE",
        2288: "KOZANA",
        2289: "CEROVO",
        2290: "KOJSKO",
        2291: "PODSABOTIN",
        2292: "ŠMAVER",
        2293: "GRGAR",
        2294: "BATE",
        2295: "BANJŠICE",
        2296: "LOKOVEC",
        2297: "ČEPOVAN",
        2298: "LAZNA",
        2299: "LOKVE",
        2300: "TRNOVO",
        2301: "RAVNICA",
        2302: "KROMBERK",
        2303: "SOLKAN",
        2304: "NOVA GORICA",
        2306: "ROŽNA DOLINA",
        2307: "STARA GORA",
        2308: "LOKE",
        2309: "ŠMIHEL",
        2310: "OZELJAN",
        2311: "VITOVLJE",
        2312: "OSEK",
        2313: "ŠEMPAS",
        2314: "VOGRSKO",
        2315: "ŠEMPETER",
        2316: "VRTOJBA",
        2318: "BILJE",
        2319: "BUKOVICA",
        2320: "PRVAČINA",
        2321: "GRADIŠČE",
        2322: "RENČE",
        2323: "VRTOČE",
        2324: "OREHOVLJE",
        2325: "MIREN",
        2326: "RUPA",
        2327: "GABRJE OB VIPAVI",
        2328: "OPATJE SELO",
        2329: "NOVA VAS",
        2330: "SELA NA KRASU",
        2331: "VOJŠČICA",
        2332: "KOSTANJEVICA NA KRASU",
        2333: "TEMNICA",
        2334: "LIPA",
        2335: "DORNBERK",
        2336: "BRANIK",
        2337: "BUKOVO",
        2338: "JESENICA",
        2339: "GORJE",
        2340: "LABINJE",
        2341: "DOLENJI NOVAKI",
        2342: "GORENJI NOVAKI",
        2343: "PLANINA",
        2344: "CERKNO",
        2345: "ZAKRIŽ",
        2346: "OREHEK",
        2347: "POLICE",
        2348: "REKA - RAVNE",
        2349: "ŠEBRELJE",
        2350: "OTALEŽ",
        2351: "IDRIJSKE KRNICE",
        2352: "SPODNJA KANOMLJA",
        2353: "SREDNJA KANOMLJA",
        2354: "GORENJA KANOMLJA",
        2355: "VOJSKO",
        2356: "ČEKOVNIK",
        2357: "IDRIJA - MESTO",
        2358: "SPODNJA IDRIJA",
        2359: "LEDINE",
        2360: "VRSNIK I",
        2361: "DOLE",
        2362: "JELIČNI VRH",
        2363: "IDRIJSKI LOG",
        2364: "GODOVIČ",
        2365: "ČRNI VRH",
        2366: "ZADLOG",
        2367: "KANJI DOL",
        2368: "LOME",
        2369: "JAVORNIK",
        2370: "DOL-OTLICA",
        2371: "KOVK",
        2372: "KRIŽNA GORA",
        2373: "COL",
        2374: "VODICE",
        2375: "PODKRAJ",
        2376: "VIŠNJE",
        2377: "SANABOR",
        2378: "VRHPOLJE",
        2379: "BUDANJE",
        2380: "ŠTURJE",
        2381: "LOKAVEC",
        2382: "STOMAŽ",
        2383: "VRTOVIN",
        2384: "ČRNIČE",
        2385: "GOJAČE",
        2386: "BATUJE",
        2387: "SELO",
        2388: "KAMNJE",
        2389: "SKRILJE",
        2390: "DOBRAVLJE",
        2391: "VIPAVSKI KRIŽ",
        2392: "AJDOVŠČINA",
        2393: "USTJE",
        2394: "VELIKE ŽABLJE",
        2395: "BRJE",
        2396: "ŠMARJE",
        2397: "GABERJE",
        2398: "ERZELJ",
        2399: "PLANINA",
        2400: "SLAP",
        2401: "VIPAVA",
        2402: "LOŽE",
        2403: "GOČE",
        2404: "PODRAGA",
        2405: "PODNANOS",
        2406: "NANOS",
        2407: "LOZICE",
        2408: "BRESTOVICA",
        2409: "IVANJI GRAD",
        2410: "SVETO",
        2411: "ŠKRBINA",
        2412: "KOMEN",
        2413: "MALI DOL",
        2414: "TOMAČEVICA",
        2415: "KOBJEGLAVA",
        2416: "ŠTANJEL",
        2417: "HRUŠEVICA",
        2418: "KOBDILJ",
        2419: "KOBOLI",
        2420: "ŠTJAK",
        2421: "AVBER",
        2422: "KOPRIVA",
        2423: "GABROVICA",
        2424: "VOLČJI GRAD",
        2425: "GORJANSKO",
        2426: "BRJE",
        2427: "VELIKI DOL",
        2428: "SALEŽ",
        2429: "PLISKOVICA",
        2430: "KRAJNA VAS",
        2431: "SKOPO",
        2432: "DUTOVLJE",
        2433: "VELIKI REPEN",
        2434: "VOGLJE",
        2435: "KRIŽ",
        2436: "TOMAJ",
        2437: "UTOVLJE",
        2438: "KAZLJE",
        2439: "GRIŽE",
        2440: "VELIKO POLJE",
        2441: "DOLENJA VAS",
        2442: "ŠTORJE",
        2443: "SENADOLE",
        2444: "GABRČE",
        2445: "POTOČE",
        2446: "LAŽE",
        2447: "SENOŽEČE",
        2448: "GORNJE VREME",
        2449: "VREMSKI BRITOF",
        2450: "FAMLJE",
        2451: "DOLNJE LEŽEČE",
        2452: "DIVAČA",
        2453: "POVIR",
        2454: "MERČE",
        2455: "SEŽANA",
        2456: "TREBČE",
        2457: "GROPADA",
        2458: "BAZOVICA",
        2459: "LOKEV",
        2460: "NAKLO",
        2461: "DANE",
        2462: "PODGRAD",
        2463: "ŠKOFLJE",
        2464: "BARKA",
        2465: "VAREJE",
        2466: "MISLIČE",
        2467: "VATOVLJE",
        2468: "KOZJANE",
        2469: "DOLENJA PLANINA",
        2470: "GORENJA PLANINA",
        2471: "KAČJA VAS",
        2472: "STRMICA",
        2473: "STUDENO",
        2474: "BUKOVJE",
        2475: "ŠMIHEL POD NANOSOM",
        2476: "LANDOL",
        2477: "ZAGON",
        2478: "HRAŠČE",
        2479: "STUDENEC",
        2480: "HRENOVICE",
        2481: "VELIKA BRDA",
        2482: "STRANE",
        2483: "RAZDRTO",
        2484: "HRUŠEVJE",
        2485: "RAKULIK",
        2486: "OREHEK",
        2487: "RAKITNIK",
        2488: "ZALOG",
        2489: "STARA VAS",
        2490: "POSTOJNA",
        2491: "MATENJA VAS",
        2492: "SLAVINA",
        2493: "VOLČE",
        2494: "KOŠANA",
        2495: "SUHORJE",
        2496: "STARA SUŠICA",
        2497: "NADANJE SELO",
        2498: "NARIN",
        2499: "KAL",
        2500: "SELCE",
        2501: "PETELINJE",
        2502: "RADOHOVA VAS",
        2503: "PARJE",
        2504: "ZAGORJE",
        2505: "JURIŠČE",
        2506: "PALČJE",
        2507: "TRNJE",
        2508: "SNEŽNIK",
        2509: "BAČ",
        2510: "KORITNICE",
        2511: "KNEŽAK",
        2512: "ŠEMBIJE",
        2513: "PODSTENJE",
        2514: "MEREČE",
        2515: "RATEČEVO BRDO",
        2516: "KILOVČE",
        2517: "PREM",
        2518: "JANEŽEVO BRDO",
        2519: "OSTROŽNO BRDO",
        2520: "PRELOŽE",
        2521: "ČELJE",
        2522: "SMRJE",
        2523: "TOPOLC",
        2524: "TRNOVO",
        2525: "ILIRSKA BISTRICA",
        2526: "VRBOVO",
        2527: "JASEN",
        2528: "KOSEZE",
        2529: "ZAREČICA",
        2530: "DOBROPOLJE",
        2531: "ZAREČJE",
        2532: "BRCE",
        2533: "ZAJELŠJE",
        2534: "TOMINJE",
        2535: "HARIJE",
        2536: "SABONJE",
        2537: "PAVLICA",
        2538: "STUDENA GORA",
        2539: "VELIKA BUKOVICA",
        2540: "MALA BUKOVICA",
        2541: "DOLNJI ZEMON",
        2542: "GORNJI ZEMON",
        2543: "JABLANICA",
        2544: "TRPČANE",
        2545: "PODGRAJE",
        2546: "ZABIČE",
        2547: "SUŠAK",
        2548: "NOVOKRAČINE",
        2549: "JELŠANE",
        2550: "DOLENJE",
        2551: "VELIKO BRDO",
        2552: "STAROD",
        2553: "GROČANA",
        2554: "RODIK",
        2555: "DRAGA",
        2557: "OCIZLA",
        2558: "PREŠNICA",
        2559: "MATERIJA",
        2560: "HRPELJE",
        2561: "BREZOVICA",
        2562: "ARTVIŽE",
        2563: "TATRE",
        2564: "RJAVČE",
        2565: "PREGARJE",
        2566: "GABERK",
        2567: "HUJE",
        2568: "MALE LOČE",
        2569: "JAVORJE",
        2570: "RITOMEČE",
        2571: "KOVČICE",
        2572: "HOTIČNA",
        2573: "SLIVJE",
        2574: "MARKOVŠČINA",
        2575: "GRADIŠČE",
        2576: "OBROV",
        2577: "HRUŠICA",
        2578: "PODBEŽE",
        2579: "PODGRAD",
        2580: "RAČICE",
        2581: "POLJANE",
        2582: "GOLAC",
        2583: "PODGORJE",
        2584: "ZAZID",
        2585: "RAKITOVEC",
        2589: "PLAVJE",
        2590: "HRIBI",
        2592: "JERNEJ",
        2593: "OLTRA",
        2594: "ANKARAN",
        2595: "ŠKOFIJE",
        2596: "TINJAN",
        2597: "OSP",
        2598: "SOCERB",
        2599: "ČRNOTIČE",
        2600: "ČRNI KAL",
        2601: "GABROVICA",
        2602: "ROŽAR",
        2603: "DEKANI",
        2604: "BERTOKI",
        2605: "KOPER",
        2606: "SEMEDELA",
        2607: "GAŽON",
        2608: "ŠMARJE",
        2609: "POMJAN",
        2610: "VANGANEL",
        2611: "MAREZIGE",
        2612: "SVETI ANTON",
        2613: "TRUŠKE",
        2614: "KUBED",
        2615: "LOKA",
        2616: "PODPEČ",
        2617: "HRASTOVLJE",
        2618: "MOVRAŽ",
        2619: "SOČERGA",
        2620: "PREGARA",
        2621: "GRADIN",
        2622: "TOPOLOVEC",
        2623: "BORŠT",
        2624: "KOŠTABONA",
        2625: "KRKAVČE",
	    2626: "IZOLA",
        2628: "MALIJA",
        2629: "DVORI NAD IZOLO",
        2630: "PIRAN",
        2631: "PORTOROŽ",
        2632: "SEČOVLJE",
        2633: "RAVEN",
        2634: "NOVA VAS",
        2635: "ROGAŠKA SLATINA",
        2636: "BEŽIGRAD",
        2637: "PRIHODI",
        2638: "PODMEŽAKLJA",
        2639: "ZVODNO",
        2640: "HRASTNIK",
        2641: "ZAGORJE",
        2642: "GROSUPLJE",
        2643: "ZASADI",
        2644: "VIŠELNICA II",
        2645: "PAKA II",
        2646: "SREDNJI DOLIČ",
        2647: "BEZOVJE",
        2648: "PERAČICA",
        2649: "IZLAKE",
        2650: "DEBRO",
        2651: "POŽNICA",
        2652: "OGOREVC",
        2653: "PŠATA",
        2654: "GOZD REKA",
        2655: "RAČICA",
        2656: "RIBČE",
        2657: "PEČICE",
        2658: "OSREDEK",
        2659: "SPODNJE SELCE",
        2660: "SPODNJA PONKVICA",
        2661: "GAJ",
        2662: "MALKOVEC",
        2663: "MRAVLJEVI",
        2664: "SPODNJA BRANICA",
        2665: "ZGORNJI DRAŽEN VRH",
        2666: "SELCE",
        2667: "SPODNJI VURMAT",
        2668: "GOJŠKA PLANINA",
        2669: "PAŠKI KOZJAK",
        2670: "LAŽIŠE",
        2671: "PODPEČ",
        2672: "PRAPRETNO",
        2673: "SPODNJA POHANCA",
        2674: "ŽAVCERJEV VRH",
        2675: "VRANOVIČI",
        2676: "CERKVIŠČE",
        2677: "PRULE",
        2678: "GOLOVEC",
        2679: "GRADIŠČE II",
        2680: "NOVE JARŠE",
        2681: "BRINJE II",
        2682: "BRDO",
        2683: "GRIČ",
        2684: "DRENIK",
        2685: "ŠAVNA PEČ",
        2686: "ZAVRATE",
        2687: "BOBEN",
        2688: "STUDENCE",
        2689: "ČEBINE",
        2690: "PRAPREČE",
        2691: "RAKITOVEC",
        2692: "SMREČJE",
        2693: "LOG",
        2695: "PLANICA",
        2696: "VOJŠČICA",
        2697: "KANALSKI LOM",
        2698: "BUKOVSKI VRH",
        2699: "UTRE",
        2700: "RAKOV ŠKOCJAN",
        2701: "DOLANCI",
        2702: "KODRETI",
        2703: "HRUŠICA",
        2704: "JURJEVA DOLINA",
        2705: "LESKOVA DOLINA",
        2706: "ZELENA JAMA",
        2707: "BEVŠKO",
        2708: "RODEŽ",
        2709: "JAVORJE",
        2710: "GLAŽUTA",
        2711: "SPODNJI VRHOV DOL",
        2712: "DOBRAVA",
        2713: "OB ŽELEZNICI",
        2714: "VRHE II",
	    2715: "CETORE",
        2716: "MORJE"
    }
}
