import React, {Component} from 'react';
import $ from 'jquery';
import 'dm-file-uploader';
import {isNull} from '../../../izUtils';
import env from '../../../env/src_config';
import {withTranslation} from "react-i18next";
import Swal from 'sweetalert2';
import {connect} from 'react-redux';

import 'dm-file-uploader/dist/css/jquery.dm-uploader.min.css';
import './FormUpload.css';
import TextArea from "../TextArea/TextArea";
import InputSelect from "../Select/Select";
import InputText from "../Input/Text";

class FileUpload extends Component {
    state = {
        filesField: [],
        rerender: false
    }

    localStorageName = null;

    getFieldsState = () => {
        return this.state.filesField;
    }

    setFieldsState = (fields) => {
        this.setState({filesField: fields});
    }

    setOneFileState = (file, index) => {
        let files = this.state.filesField;
        files[index] = file;
        this.setState({filesField: files});
    }

    getFileIndex = (fileId) => {
        for(let i = 0; i < this.state.filesField.length; i++) {
            if (this.state.filesField[i].id === fileId) {
                return i;
            }
        }

        return null;
    }

    getFileName = (fileId) => {
        for(let i = 0; i < this.props.storedFiles.length; i++) {
            if (this.props.storedFiles[i].id === fileId) {
                return this.props.storedFiles[i].safeName;
            }
        }

        return null;
    }

    getUrlQuery = (fileId) => {
        for(let i = 0; i < this.props.storedFiles.length; i++) {
            if (this.props.storedFiles[i].id === fileId){
                return this.props.storedFiles[i].urlQuery;
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.localStorageName !== this.props.localStorageName) {
            this.reloadComponent();
        } else if (JSON.stringify(this.props.files) !== JSON.stringify(prevProps.files)) {
            this.reloadComponent();
        }
    }

    componentDidMount() {
        this.reloadComponent();
    }

    reloadComponent = () => {
        let localStorageName = this.props.localStorageName;
        if (localStorageName === undefined) {
            localStorageName = 'SODNEDRAZBE.JSData';
        }

        let localStorageData = localStorage.getItem(localStorageName);
        if (isNull(localStorageData)) {
            localStorage.setItem(localStorageName, JSON.stringify({"values":{"files":[{}]}}));
        }

        const props = this.props;
        const {t} = this.props;
        this.localStorageName = localStorageName;
        // let mainState = this.state;
        const setStateFunction = this.setFieldsState;
        const getFieldsState = this.getFieldsState;
        const getFileindex = this.getFileIndex;
        const setOneFileState = this.setOneFileState;

        if (this.props.files.length) {
            let filesField = /*mainState.filesField*/[];
            for (let i = 0; i < this.props.files.length; i++) {
                if (this.getFileName(this.props.files[i].fileId.value) !== null) {
                    filesField.push({file: {name: this.getFileName(this.props.files[i].fileId.value), urlQuery: this.getUrlQuery(this.props.files[i].fileId.value), id: this.props.files[i].fileId.value}, percent: 100, active: false, statusMsg: "Datoteka uspešno naložena", statusClass: "status text-success", barClass: 'bg-success' });
                }
            }
            this.setState({filesField: filesField});
        }

        $('#drag-and-drop-zone').dmUploader({
            url: env.api.private + 'upload',
            headers: this.props.headers.headers,
            maxFileSize: 30000000, // 30 Megs
            extFilter: (this.props.filter !== undefined) ? this.props.filter : null,
            onDragEnter: function () {
                this.addClass('active');
            },
            onDragLeave: function () {
                this.removeClass('active');
            },
            onInit: function () {
                props.setLoadingFiles(false);
            },
            onComplete: function () {
                props.setLoadingFiles(false);
            },
            onNewFile: function (id, file) {
                props.setLoadingFiles(true);
                //let filesField = mainState.filesField;
                let filesField = getFieldsState();
                filesField.push({id, file, percent: 0, active: false, statusMsg: "client_ask_ftem_waiting", statusClass: "text-muted", barClass: '' });

                /* open new field in local storage */
                let localData = JSON.parse(localStorage.getItem(localStorageName));
                if (localData.values.files === undefined) {
                    localData.values.files = [];
                }

                if (localData.values.files[filesField.length - 1] === undefined) {
                    localData.values.files[filesField.length - 1] = {};
                }
                localStorage.setItem(localStorageName, JSON.stringify(localData));
                /* END: open new field in local storage */

                setStateFunction(filesField);
            },
            onBeforeUpload: function (id) {
                const index = getFileindex(id);
                if (!isNull(index)) {
                    const files = getFieldsState();
                    let file = files[index];
                    file.active = true;
                    file.statusClass = 'status text-uploading';
                    file.statusMsg = t('fileUpload.uploading');
                    setOneFileState(file, index);
                }
            },
            onUploadProgress: function (id, percent) {
                const index = getFileindex(id);
                if (!isNull(index)) {
                    const files = getFieldsState();
                    let file = files[index];
                    file.percent = percent;
                    setOneFileState(file, index);
                }
            },
            onUploadSuccess: function (id, data) {
                const index = getFileindex(id);
                if (data.valid) {
                    let localData = JSON.parse(localStorage.getItem(localStorageName));
                    localData.values.files[index]['fileId'] = data.record.id;
                    localStorage.setItem(localStorageName, JSON.stringify(localData));
                }
                if (!isNull(index)) {
                    const files = getFieldsState();
                    let file = files[index];
                    file.file.id = data.record.id;
                    file.file.urlQuery = data.record.urlQuery;
                    file.active = false;
                    file.statusClass = 'status text-success';
                    file.statusMsg = t('fileUpload.uploaded');
                    file.barClass = 'bg-success';
                    setOneFileState(file, index);
                }
            },
            onUploadError: function (id, xhr, status, message) {
                alert('upload error');
                console.log('onUploadError');
            },
            onFallbackMode: function () {
                Swal.fire({
                    title: t('fileUpload.swal.browserError.title'),
                    html: t('fileUpload.swal.browserError.content'),
                    icon: 'error',
                    confirmButtonText: t('ok')
                });
            },
            onFileExtError: function (file) {
                Swal.fire({
                    title: t('fileUpload.swal.typeError.title'),
                    html: t('fileUpload.swal.typeError.content')+': '+props.filter.join(", "),
                    icon: 'warning',
                    confirmButtonText: t('ok')
                });
            },
            onFileSizeError: function (file) {
                Swal.fire({
                    title: t('fileUpload.swal.sizeError.title'),
                    html: t('fileUpload.swal.sizeError.content').replace('[filename]', file.name),
                    icon: 'warning',
                    confirmButtonText: t('ok')
                });
            }
        });
    }

    deleteFile = (index) => {
        const {t} = this.props;
        Swal.fire({
            title: t('fileUpload.swal.deleteFileQuestion.title'),
            html: t('fileUpload.swal.deleteFileQuestion.content'),
            icon:"question",
            showCancelButton: true,
            confirmButtonColor: '#1068ce',
            cancelButtonColor: '#cda73a',
            cancelButtonText: t('no'),
            confirmButtonText: t('yes')
        }).then((result) => {
            if (result.value) {
                let files = [], localDataFiles = [];
                let localData = JSON.parse(localStorage.getItem(this.localStorageName));

                for (let i = 0; i < this.state.filesField.length; i++) {
                    if (i !== index) {
                        files.push(this.state.filesField[i]);
                    }
                }

                for (let i = 0; i < files.length; i++) {
                    for(let j = 0; j < localData.values.files.length; j++) {
                        if (files[i].file.id === localData.values.files[j].fileId) {
                            localDataFiles.push(localData.values.files[j]);
                        }
                    }
                }

                localData.values.files = localDataFiles;
                localStorage.setItem(this.localStorageName, JSON.stringify(localData));

                if (this.props.showLeavePrompt !== undefined) {
                    this.props.showLeavePrompt();
                }

                this.setState({filesField: files});
            }
        });
    }

    reSort = (index, direction) => {
        let localData = JSON.parse(localStorage.getItem(this.localStorageName));

        if (direction === "left") {
            let elementBeforeSort = parseInt(localData.values.files[index-1].sort);

            localData.values.files[index-1].sort = parseInt(localData.values.files[index].sort);
            localData.values.files[index].sort = elementBeforeSort;
        } else if (direction === "right") {
            let elementNextSort = parseInt(localData.values.files[index+1].sort);

            localData.values.files[index+1].sort = parseInt(localData.values.files[index].sort);
            localData.values.files[index].sort = elementNextSort;
        }

        localStorage.setItem(this.localStorageName, JSON.stringify(localData));
        this.setState({rerender: !this.state.rerender});
    }

    setMaxNumbers = (localStorageName) => {
        let localData = localStorage.getItem(localStorageName);

        if (localData !== null) {
            localData = JSON.parse(localData);

            let max = 0;
            let thereIsZero = false;
            for(let i = 0; i < localData.values.files.length; i++) {
                if (max < parseInt(localData.values.files[i].sort)) {
                    max = parseInt(localData.values.files[i].sort);
                }
                if (!parseInt(localData.values.files[i].sort)) {
                    thereIsZero = true;
                }
            }

            if (thereIsZero) {
                for(let i = 0; i < localData.values.files.length; i++) {
                    if (max < parseInt(localData.values.files[i].sort)) {
                        max = parseInt(localData.values.files[i].sort);
                    }
                    if (!parseInt(localData.values.files[i].sort)) {
                        max += 1;
                        localData.values.files[i].sort = max;
                    }
                }
            }

            localStorage.setItem(localStorageName, JSON.stringify(localData));

            return localData;
        }

        return null;
    }

    getAttributeValues = (localData) => {
        let filesCnt = 0;
        let data = this.props.files[0];
        let attcDescFields = [];
        const {t} = this.props;
        for (let i = 0; i < this.state.filesField.length; i++) {
            if (!isNull(this.state.filesField[i].file.name)) {
                filesCnt++;
                let fileAttribs = ['attachmentType', 'description'];
                if (this.props.fileAttribs !== undefined) {
                    fileAttribs = this.props.fileAttribs;
                }

                // eslint-disable-next-line
                attcDescFields[i] = fileAttribs.map(input => {
                    data[input].value = '';
                    if (localData.values !== null) {
                        if (localData.values.files[i] !== undefined) {
                            if (localData.values.files[i][input] !== undefined) {
                                data[input].value = localData.values.files[i][input];
                            }
                        }

                        switch (data[input].type) {
                            case "textarea":
                                return (<TextArea key={input+'_'+i} data={data} type="text"
                                                  translations={data[input].translation} cols="4/8" name={input} index={i}
                                                  label={data[input].label} display={data[input].display}
                                                  localData={this.localStorageName} arrayType="files" secondUpdate={localData.values.files[i] !== undefined ? localData.values.files[i].fileId : null}
                                                  value={isNull(data[input].value) ? '' : data[input].value} change={(e) => this.props.textChangeFilesHandler(e, i)}/>)
                            case "select":
                                return (<InputSelect key={input+'_'+i} error={{ code: "mandatory", title: t('mandatory') }} cols="4/8" name={input} index={i}
                                                     label={data[input].label} options={data[input].options} display={data[input].display} mandatory={true}
                                                     value={(data[input].value === null) ? "0" : data[input].value} arrayType="files"
                                                     change={(e) => this.props.selectChangeFilesHandler(e, i)}/>);
                            case "integer":
                                if (this.props.enableSort) {
                                    return (<InputText key={input+'_'+i} type="integer" cols="4/8" name={input} index={i}
                                                       label={data[input].label} display={data[input].display}
                                                       arrayType={"files"} mainClass="displayNone"
                                                       value={(data[input].value === null) ? "0" : data[input].value} change={(e) => this.props.textChangeFilesHandler(e, i)}/>);
                                } else {
                                    return null;
                                }
                            default: return null;
                        }
                    }
                });
            }
        }

        return {filesCnt: filesCnt, attcDescFields: attcDescFields};
    }

    render()
    {
        const {t} = this.props;
        let files = '';
        let attcDescFields = [];
        let filesCnt = 0;

        let localData = this.setMaxNumbers(this.localStorageName);

        if (this.state.filesField.length) {
            let filesList;
            if (this.props.enableSort) {
                filesList = JSON.parse(JSON.stringify(this.state.filesField));
                let filesSortLC = localData.values.files.sort((a,b) => (parseInt(a.sort) > parseInt(b.sort)) ? 1 : ((parseInt(b.sort) > parseInt(a.sort)) ? -1 : 0));
                filesList = [];

                localData.values.files = filesSortLC;
                localStorage.setItem(this.localStorageName, JSON.stringify(localData));

                for(let i = 0; i < filesSortLC.length; i++) {
                    for(let j = 0; j < this.state.filesField.length; j++) {
                        if (filesSortLC[i].fileId === this.state.filesField[j].file.id) {
                            filesList.push(this.state.filesField[j]);
                        }
                    }
                }
            } else {
                filesList = this.state.filesField;
            }

            const attrAndCnt = this.getAttributeValues(localData);
            filesCnt = attrAndCnt.filesCnt;
            attcDescFields = attrAndCnt.attcDescFields;
            files = filesList.map((fileField, index) => {

                if (this.state.filesField[index] !== undefined && !isNull(this.state.filesField[index].file.name)) {
                    let barClass = fileField.barClass;
                    if (fileField.active) {
                        barClass = " progress-bar-striped progress-bar-animated";
                    }

                    let image = '';
                    if ((this.props.displayImageThumb !== undefined) && (this.props.displayImageThumb === true)) {
                        if (fileField.file.id !== undefined) {
                            image = <img src={env.api.downloadPublic+fileField.file.urlQuery+'&operation=fill&width=460&height=250'} style={{width: "100%"}} alt="" />;
                        }
                    }

                    return (
                        <div key={index} className="media col-4" id={"uploaderFile"+fileField.id} data-index={fileField.id}>
                            <div className="media-body mb-1">
                                {image}
                                <p className="mb-2">
                                    <strong><a href={env.api.downloadPublic+fileField.file.urlQuery} target="_blank" rel="noopener noreferrer">{fileField.file.name}</a></strong> - {t('fileUpload.status')}: <span className={fileField.statusClass}> {fileField.statusMsg} </span><br/>
                                    {barClass === 'bg-success' ? <span onClick={() => this.deleteFile(index)} className="cursor">{t('fileUpload.removeFile')} </span> : '' }
                                </p>
                                <div className="progress mb-2">
                                    <div className={"progress-bar bg-primary "+barClass}
                                         role="progressbar"
                                         style={{width: fileField.percent+"%"}}
                                         aria-valuenow={fileField.percent} aria-valuemin="0" aria-valuemax="100">
                                        {fileField.percent > 0 ? fileField.percent+'%' : ''}
                                    </div>
                                </div>
                                {attcDescFields[index]}
                                {this.props.enableSort ?
                                    <div className="row">
                                        {index !== 0 ? <button onClick={() => this.reSort(index, 'left')} className="btn btn-sm btn-primary" style={{lineHeight: 1.0}}><i className="mdi mdi-arrow-left-bold"/></button> : ''}
                                        {index !== (filesList.length-1) ? <button onClick={() => this.reSort(index, 'right')} className="btn btn-sm btn-primary ml-2" style={{lineHeight: 1.0}}><i className="mdi mdi-arrow-right-bold"/></button> : ''}
                                    </div>
                                    : ''}
                            </div>
                        </div>
                    );
                }
                return null;
            });
        } else {
            files = <i className="empty">{t('fileUpload.noFileUploaded')}</i>;
        }

        return (
            <div className="form-group row">
                <div className="col-md-12">
                    <div id="file-upload" className="section">
                        <div className="row">
                            <div className="col-12">
                                <div id="drag-and-drop-zone" className={"dm-uploader p-5 text-center" + ((filesCnt) ? '' : (this.props.isMandatory ? " mandatory" : "")) }>
                                    <h3 className="mb-5 mt-5 text-muted">{t('fileUpload.dragDropFile')}{this.props.isMandatory ? '*' : ''}</h3>

                                    <div className="btn btn-primary btn-block mb-5">
                                        <span>{t('fileUpload.addFileBySelection')}</span>
                                        <input type="file" title="client_ask_files_add" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {filesCnt ?
                            <div>
                                <div className="col-12">
                                    <div className="h-100">
                                        <h4 className="card-title mt-5">{t('fileUpload.uploadedFiles')}</h4>
                                        <div className="p-2 d-flex row" id="files">
                                            {files}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        : ''}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loadingFiles: state.loadingFiles,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoadingFiles: (array) => dispatch({type: 'SETLOADINGFILES', value: array}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FileUpload));
