import React, {Component} from 'react';
import {isNull, parseJwt, time_reformat_toMoment} from "../../../izUtils";
import moment from "moment";
import './Datetime.css';
import { withCookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import { DatePicker } from 'antd';

import 'moment/locale/sl';
import slSI from 'antd/es/date-picker/locale/sl_SI';

import 'moment/locale/hu';
import huHU from 'antd/es/date-picker/locale/hu_HU';

import 'moment/locale/de';
import deDE from 'antd/es/date-picker/locale/de_DE';

import 'moment/locale/it';
import itIT from 'antd/es/date-picker/locale/it_IT';

import 'moment/locale/en-gb';
import enGB from 'antd/es/date-picker/locale/en_GB';

class InputDatetime extends Component {
    state = {
        comparison: "EQ"
    }
    locales = {
        slSI: slSI,
        huHU: huHU,
        deDE: deDE,
        itIT: itIT,
        enGB: enGB,
    }

    comparisons = [
        {value: "EQ", label: "="},
        {value: "NEQ", label: "≠"},
        {value: "LT", label: "<"},
        {value: "LTE", label: "<="},
        {value: "GT", label: ">"},
        {value: "GTE", label: ">="},
    ]

    getDaysInc = () => {
        /* this peace is described in EDS-347 */
        let inc = {amount: 30, type: 'day'};
        const JSData = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));
        const { cookies } = this.props;
        const kuki = cookies.get('token');
        if ((kuki !== null) && (kuki !== undefined)) {
            const parsed = parseJwt(kuki);
            if (parsed.vashRoleId !== undefined) {
                let role = parseInt(parsed.vashRoleId);
                if ((role === 482) || (role === 303) || (role === 304)) {
                    inc = {amount: 8, type: 'day'};
                }
                
                if (role === 304) {
                    if (JSData.values.root.startingPrice !== undefined) {
                        const startingPrice = parseFloat(JSData.values.root.startingPrice);
                        if (startingPrice > 50000) {
                            inc = {amount: 1, type: 'month'};
                        }
                    } 
                }

                if (JSData.values.root.saleMethod !== undefined) {
                    // if saleMethod is 04 OR 05
                    if (JSData.values.root.saleMethod === '59e94998-030b-4df0-b587-93c294399f20' || JSData.values.root.saleMethod === 'eab1f5b9-a8be-4385-a394-5e66b951686b') {
                        inc = {amount: 0, type: 'day'};
                    }
                }
            }
        }

        return inc;
    }

    disabledDatePast = (current, name) => {
        const { cookies } = this.props;
        const kuki = cookies.get('token');

        if (name === "saleEndAt") {
            const JSData = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));
            if (JSData.values.root.saleStartAt !== undefined) {
                let tPlus30 = time_reformat_toMoment(JSData.values.root.saleStartAt).format('yyyy-MM-DD');
                // if role is 304 AND saleMethod is 04 OR 05
                if ((kuki !== null) && (kuki !== undefined)) {
                    const parsed = parseJwt(kuki);
                    if (parsed.vashRoleId !== undefined) {
                        if (parseInt(parsed.vashRoleId) === 304) {
                            if (JSData.values.root.saleMethod !== undefined) {
                                if (JSData.values.root.saleMethod === '59e94998-030b-4df0-b587-93c294399f20' || JSData.values.root.saleMethod === 'eab1f5b9-a8be-4385-a394-5e66b951686b') {
                                    let inc = {amount: 1, type: 'month'};
                                    if (JSData.values.root.startingPrice !== undefined) {
                                        if (parseFloat(JSData.values.root.startingPrice) > 100000) {
                                            inc = {amount: 2, type: 'month'};
                                        }
                                    }
                                    
                                    tPlus30 = moment(JSData.values.root.saleStartAt).add(inc.amount, inc.type).format('yyyy-MM-DD');
                                }
                            }
                        }
                    }
                }

                const calendarCurrent = current.format('yyyy-MM-DD');
                return current && moment(tPlus30).isAfter(calendarCurrent);
            } else {
                const inc = this.getDaysInc();
                const tPlus30 = moment().add(inc.amount, inc.type).format('yyyy-MM-DD');
                const calendarCurrent = current.format('yyyy-MM-DD');

                return current && moment(tPlus30).isAfter(calendarCurrent);
            }
        } else if (name === "notifyAt") {
            const JSData = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));
            if (JSData.values.root.saleEndAt !== undefined) {
                return current && current < time_reformat_toMoment(JSData.values.root.saleEndAt);
            } else {
                const inc = this.getDaysInc();
                const tPlus30 = moment().add(inc.amount, inc.type).format('yyyy-MM-DD');
                const calendarCurrent = current.format('yyyy-MM-DD');

                return current && moment(tPlus30).isAfter(calendarCurrent);
            }
        } else if (name === "saleStartAt") {
            const inc = this.getDaysInc();
            const tPlus30 = moment().add(inc.amount, inc.type).format('yyyy-MM-DD');
            const calendarCurrent = current.format('yyyy-MM-DD');

            return current && moment(tPlus30).isAfter(calendarCurrent);
        } else if (name === "openBidAt") {
            const JSData = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));
            if (JSData.values.root.saleEndAt !== undefined) {
                const tPlus30 = time_reformat_toMoment(JSData.values.root.saleEndAt).format('yyyy-MM-DD');
                const calendarCurrent = current.format('yyyy-MM-DD');

                return current && moment(tPlus30).isAfter(calendarCurrent);
            }
        }

        // Can not select days before today and today
        return current && current < moment().endOf('day');
    }

    disabledDatePastSearch = (current) => {
        // Can not select days before today
        return current < moment().startOf('day');
    }

    disabledDateFuture = (current) => {
        // Can not select days after today and today
        return current && current > moment().endOf('day');
    }

    changeComparison = (e) => {
        this.setState({comparison: e.target.value});
        this.props.change(this.currentDateValue, this.props.name, e.target.value);
    }

    currentDateValue = null;
    dateChanged = (value) => {
        this.currentDateValue = value;
        this.props.change(value, this.props.name, this.state.comparison);
    }

    render() {
        const { cookies } = this.props;
        const kuki = cookies.get('token');

        if (this.props.display || (this.props.display === undefined)) {
            const {t} = this.props;
            let cols = this.props.cols;
            if (cols !== undefined) {
                cols = cols.split('/');
                cols = ['col-' + cols[0], 'col-' + cols[1]];
            } else {
                cols = ['col-md-2', 'col-md-10'];
            }

            let inputClass = [];
            let labelAppendix = '';

            if ((this.props.error !== undefined) && !isNull(this.props.error)) {
                inputClass.push('mandatory');
            }

            if (this.props.mandatory) {
                labelAppendix = '*';
            }

            let dateValue = undefined;
            if (this.props.value !== undefined) {
                if (this.props.value instanceof moment) {
                    if (this.props.value.isValid()) {
                        dateValue = this.props.value;
                    }
                }
            }

            let show = this.props.showFields;
            if (this.props.showFields === undefined) {
                show = ['date', 'time'];
            }

            let dateLimiter = {};
            if ((this.props.name === 'saleStartAt') || (this.props.name === 'saleEndAt') || (this.props.name === 'viewStartAt') || (this.props.name === 'dynamicExtensionEndAt') || (this.props.name === 'notifyAt') || (this.props.name === 'openBidAt'))  {
                dateLimiter = {
                    disabledDate: (current) => this.disabledDatePast(current, this.props.name)
                }
            } else if (this.props.name === 'birthDate') {
                dateLimiter = {
                    disabledDate: this.disabledDateFuture
                }
            }

            if ((this.props.name === 'saleEndAt_selectedFrom') || (this.props.name === 'saleEndAt_selectedTo')) {
                dateLimiter = {
                    disabledDate: (current) => this.disabledDatePastSearch(current, this.props.name)
                }
            }

            let defaultOpenCalendar = moment().add('1', 'month');
            if(this.props.name === "saleEndAt") {
                const JSData = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));
                if (JSData.values.root.saleStartAt !== undefined) {
                    let inc = {amount: 0, type: 'day'};

                    // if role is 304 AND saleMethod is 04 OR 05
                    if ((kuki !== null) && (kuki !== undefined)) {
                        const parsed = parseJwt(kuki);
                        if (parsed.vashRoleId !== undefined) {
                            if (parseInt(parsed.vashRoleId) === 304) {
                                if (JSData.values.root.saleMethod !== undefined) {
                                    if (JSData.values.root.saleMethod === '59e94998-030b-4df0-b587-93c294399f20' || JSData.values.root.saleMethod === 'eab1f5b9-a8be-4385-a394-5e66b951686b') {
                                        inc = {amount: 1, type: 'month'};
                                        if (JSData.values.root.startingPrice !== undefined) {
                                            if (parseFloat(JSData.values.root.startingPrice) > 100000) {
                                                inc = {amount: 2, type: 'month'};
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    defaultOpenCalendar = moment(JSData.values.root.saleStartAt).add(inc.amount, inc.type);
                }
            }

            if (this.props.name === 'viewStartAt' || this.props.name === 'from' || this.props.name === 'to') {
                defaultOpenCalendar = null;
            }

            const style = {width: "100%"};

            let format = 'DD.MM.yyyy';
            let title = t('date');
            let timer = {};
            if (show.indexOf('time') !== -1 ) {
                format = 'DD.MM.yyyy HH:mm';
                title = t('datetime');
                timer = {
                    showTime: { defaultValue: moment('00:00', 'HH:mm') }
                }
            }

            let locale = 'slSI';
            if (!isNull(localStorage.getItem('SODNEDRAZBE.selectedLanguage'))) {
                locale = localStorage.getItem('SODNEDRAZBE.selectedLanguage').replace('-', '');
            }

            const dateElement = <DatePicker style={style}
                                            locale={this.locales[locale]}
                                            format={format}
                                            {...timer}
                                            defaultPickerValue={defaultOpenCalendar}
                                            placeholder={title}
                                            className={inputClass.join(' ')}
                                            value={dateValue}
                                            {...dateLimiter}
                                            onChange={value => this.dateChanged(value)}/>

            let comparisonOptions = null;
            if (this.props.comparison) {
                comparisonOptions = this.comparisons.map((option, key) => {
                    return (
                        <option
                            key={this.props.name + '_' + option.value + '_' + key}
                            value={option.value}>
                            {option.label}
                        </option>
                    );
                });
            }

            return (
                <div className="form-group row dateTimeWrapper" >
                    <label htmlFor={this.props.name}
                           className={cols[0] + " col-form-label" + (this.props.labelRight !== undefined ? " text-right" : "")}>{this.props.label + labelAppendix}</label>
                    <div className={cols[1]}>
                        {this.props.comparison ?
                            <div className="row">
                                <div className="col-3">
                                    <select
                                        className={"custom-select " + inputClass.join(' ')}
                                        value={this.state.comparison}
                                        name={this.props.name+"_comparison"}
                                        onChange={(e) => this.changeComparison(e)}>
                                        {comparisonOptions}
                                    </select>

                                </div>
                                <div className="col-9">{dateElement}</div>
                            </div>
                            :
                            dateElement
                        }
                    </div>
                </div>
            )
        } else {
            return ('');
        }
    }
};
export default withCookies(withTranslation()(InputDatetime));
