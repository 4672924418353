import React, { Component, Suspense, Fragment } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import {connect} from 'react-redux';
import i18n from './i18n';
import ReactGA from 'react-ga4';

/* import HOC */
import Header from './hoc/Header/Header';
import Leftside from './hoc/Leftside/Leftside';
import Footer from './hoc/Footer/Footer';
import CookieConsent from './hoc/CookieConstent/CookieConsent';

import { Popover } from 'react-tiny-popover'

/* import css */
import './assets/css/main/bootstrap.css';
import './assets/css/main/icons.css';
import './assets/css/main/custom.css';
import './assets/css/main/app.css';
import './assets/css/main/app_dark.css';

/* import contents */
import Create2 from './Subscriber/Drafts/Create2/Create2';
import SubscriberDashboard from './Subscriber/Dashboard/Dashboard';

import Loading from './hoc/Loading/Loading';

import { isNull, findGetParameter } from "./izUtils";
import env from "./env/src_config";
import Swal from "sweetalert2";
import axios from "axios";
import {isIE} from 'react-device-detect';

// import images for IE
import ie from './ie.jpg';
import otherBrowsers from './safari-chrome-firefox-edge.jpg';
import {Log} from "./hoc/Log/Log";

/* import pages (Lazy Loading) */
const DraftList = React.lazy(() => import('./Subscriber/Drafts/List/List'));

const PublishedList = React.lazy(() => import('./Subscriber/Published/List/List'));
const ArchiveList = React.lazy(() => import('./Subscriber/Archive/List/List'));
const FinishedList = React.lazy(() => import('./Subscriber/Finished/List/List'));

const PublishedCancel = React.lazy(() => import('./Subscriber/Published/Cancel/Cancel'));
const PublishedUpdate = React.lazy(() => import('./Subscriber/Published/Update/Update'));
const PublishedSingle = React.lazy(() => import('./Subscriber/Published/Single/Single'));
const SubscriberProfile = React.lazy(() => import('./Subscriber/Profile/Profile'));
const PublishedSingleBidder = React.lazy(() => import('./Subscriber/Published/Single/Parts/Bidders/Bidder/Bidder'));
const PublishedBiddersList = React.lazy(() => import('./Subscriber/Published/Bidders/List/List'));
const PublishedBidders = React.lazy(() => import('./Subscriber/Published/Bidders/Single/Single'));
const FinishedEnterData = React.lazy(() => import('./Subscriber/Finished/EnterData/EnterData'));

const InternalStat =  React.lazy(() => import('./Internal/Form/Form'));

/* bidder jernej */
const BidderList = React.lazy(() => import('./Bidder2/List/List'));
const BidderSingle = React.lazy(() => import('./Bidder2/Single/Single'));
const ActiveList =  React.lazy(() => import('./Bidder2/ActiveList/ActiveList'));
const CancelList =  React.lazy(() => import('./Bidder2/CancelList/CancelList'));

const Apply = React.lazy(() => import('./Bidder2/Apply/Apply'));
const ApplyFix = React.lazy(() => import('./Bidder2/Apply/Fix/Fix'));
const Search = React.lazy(() => import('./Bidder2/Search/Search'));
const Profile = React.lazy(() => import('./Bidder2/Profile/Profile'));
const SignedList = React.lazy(() => import('./Bidder2/List/Signed/List'));
const SingleSigned = React.lazy(() => import('./Bidder2/List/Signed/Single/Single'));
const FavouritesList = React.lazy(() => import('./Bidder2/Profile/FavouritesList/FavouritesList'));
const Newsletter = React.lazy(() => import('./Bidder2/Profile/Newsletter'));
const CookiesContent = React.lazy(()=> import('./hoc/CookieConstent/CookieContent'));

const PublicStats = React.lazy(()=> import('./hoc/Stats/PublicStats'));
const SelfDiag = React.lazy(() => new Promise((resolve, reject) => {
    import('./hoc/SelfDiag/SelfDiag')
      .then(result => resolve(result.default ? result : { default: result }))
      .catch(reject);
}));

/* other */
const Content = React.lazy(() => import('./hoc/Content/Content'));
const PrivacyStatement = React.lazy(() => import('./Page/PrivacyStatement'));
const IndividualNotice = React.lazy(() => import('./Page/IndividualNotice'));

window.ws = null;

class App extends Component {
    state = {
        jwt_token: null,
        bidderList: null,
        bidderListRefresh: false,
        listParams: null,
        isPopoverOpen: false,
        isPopoverOpenNotification: false
    }

    gaIsInitialized = false;

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    reloadBidderList = (list) => {
        this.setState({bidderList: list, bidderListRefresh: !this.state.bidderListRefresh});
    }

    reloadPagination = (params, toDefault) => {
        let tmpPrms = {...this.state.listParams};

        // Reset params to default values (if switching from pagination to scroll view or vice versa)
        if (toDefault) {
            tmpPrms.pagination = {
                "offset": 0,
                "limit": 25
            };
            tmpPrms.sort = {
                "by": "publicationAt",
                "direction": "desc"
            }
        } else {
            tmpPrms.pagination = params.pagination;
        }

        this.setState({listParams: tmpPrms});
    }

    reloadSort = (sort) => {
        let tmpPrms = {...this.state.listParams};
        tmpPrms.sort = sort.sort;
        this.setState({listParams: tmpPrms});
    }

    sign = (type, action) => {
        const { cookies } = this.props;
        if (action === "logout") {
            const tmp = this.props.options.parsedToken;
            if (type === "reasign") {
                Swal.fire({
                    title: i18n.t('sign.swal.title'),
                    html: i18n.t('sign.swal.content'),
                    icon: "info",
                    confirmButtonColor: '#1068ce',
                    confirmButtonText: i18n.t('sign.swal.btn')
                }).then((result) => {
                    if (tmp.bidderId) {
                        window.location.href = env.externallinks.sipass;
                    } else {
                        window.location.href = env.externallinks.vlozisce;
                    }
                })
            } else {
                this.props.history.push({pathname: '/'})
            }

            localStorage.removeItem('SODNEDRAZBE.filterDisplay');
            this.props.setJwtToken('');
            cookies.remove('token', { path: '/' });
            this.setState({jwt_token: '', bidderListRefresh: !this.state.bidderListRefresh});
        } else if ((type === "subscriber") && (action === "login")) {
            if (this.state.jwt_token.length === 0) {
                Swal.fire({
                    title: 'Kdo sem jaz?',
                    icon: "question",
                    input: 'select',
                    inputOptions: {
                        '2': 'Sodnik A - sodišče',
                        '3': 'Sodnik B - sodišče',
                        '4': 'Stečajni upravitelj C - 304',
                        '5': 'Izvršitelj D - 303',
                        '7': 'Sodišče - Evložišče test',
                    },
                    showCancelButton: false,
                    confirmButtonColor: '#1068ce',

                }).then((result) => {
                    if (result.value) {
                        let token = '';
                        if (result.value === "2") {
                            token = env.tokens.subscriber.dev_token_sodisce_A;
                        } else if (result.value === "3") {
                            token = env.tokens.subscriber.dev_token_sodisce_B;
                        } else if (result.value === "4") {
                            token = env.tokens.subscriber.dev_token_stecajni_C;
                        } else if (result.value === "5") {
                            token = env.tokens.subscriber.dev_token_izvrsitelj_D;
                        } else if (result.value === "7") {
                            window.location.href = env.externallinks.vlozisce;
                        }

                        if (result.value !== 7) {
                            this.props.setJwtToken('Bearer '+token);
                            cookies.set('token', token, { path: '/' });
                            this.setState({jwt_token: 'Bearer '+token});
                        }
                    }
                });
            }
        } else if ((type === "bidder") && (action === "login")) {
            if (this.state.jwt_token.length === 0) {
                if (env.is_public) {
                    window.location.href = env.externallinks.sipass;
                } else {
                    Swal.fire({
                        title: 'Kdo sem jaz?',
                        icon: "question",
                        input: 'select',
                        inputOptions: {
                            '7': 'Dražitelj 1',
                            '8': 'Dražitelj 2',
                            '9': 'Dražitelj 3',
                            '11': 'Sipass'
                        },
                        showCancelButton: false,
                        confirmButtonColor: '#1068ce'
                    }).then((result) => {
                        if (result.value) {
                            let jsToken = '';
                            if (result.value === "7") {
                                jsToken = env.tokens.bidder.bidder_1;
                            } else if (result.value === "8") {
                                jsToken = env.tokens.bidder.bidder_2;
                            } else if (result.value === "9") {
                                jsToken = env.tokens.bidder.bidder_3;
                            } else if (result.value === "11") {
                                window.location.href = env.externallinks.sipass;
                            }

                            if (result.value !== 11) {
                                this.props.setJwtToken('Bearer '+jsToken);
                                cookies.set('token', jsToken, { path: '/' });
                                this.setState({jwt_token: 'Bearer '+jsToken});
                            }
                        }
                    });
                }
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.cookiesConsent.indexOf('ga') !== -1) {
            if (!this.props.initializedGA) {
                ReactGA.initialize([{trackingId: env.googleAnalytics}]);
                this.props.initializeGA();
            }
        }
    }

    componentDidMount() {
        if (isNull(localStorage.getItem('SODNEDRAZBE.selectedLanguage'))) {
            localStorage.setItem('SODNEDRAZBE.selectedLanguage', "sl-SI");
        }
        const currentLanguage = localStorage.getItem('SODNEDRAZBE.selectedLanguage');

        const headers = {
            "Accept": 'application/json',
            "Accept-Language": currentLanguage
        }
        axios.post(env.api.api + '/time', {}, {headers: headers}).then(res => { if (res.status === 200) { this.props.setInitialTime(res.data.unix); } });


        const { cookies } = this.props;
        this.props.setAcceptLanguage(localStorage.getItem('SODNEDRAZBE.selectedLanguage'));
        axios.get(env.api.public + 'property?t=gv&locale='+localStorage.getItem('SODNEDRAZBE.selectedLanguage')).then(res => {
            if (res.status === 200) {
                this.props.setDictionary(res.data);
            }
        })

        let key = findGetParameter("key");
        if (key) {
            this.props.setJwtToken('Bearer '+key);
            cookies.set('token', key, { path: '/' });
            this.setState({jwt_token: 'Bearer '+key});
        } else {
            const cookie = cookies.get('token');
            if (cookie !== undefined) {
                this.props.setJwtToken('Bearer ' + cookie)
                this.setState({jwt_token: 'Bearer ' + cookie});
            } else {
                this.props.setJwtToken('');
                this.setState({jwt_token: ''});
            }
        }

        this.initializeWS();

        // Bidder list scroll type
        if (isNull(localStorage.getItem('SODNEDRAZBE.selectedScrollType'))) {
            localStorage.setItem('SODNEDRAZBE.selectedScrollType', "scroll");
        }
        this.props.setScrollType(localStorage.getItem('SODNEDRAZBE.selectedScrollType'));
    }

    wsInterval = null;
    wsCheckConnectionInterval = null;
    lastRecievedWsMsg = 0;

    reconnectWebsocket = () => {
        if (window.ws === null) {
            try {
                //Log("Connecting to websocket");
                window.ws = new WebSocket(env.api.wss);
            } catch (exception_var) {
                //Log('Can\'t connect to websocket');
            } finally {
                //Log('Websocket finally');
                if (window.ws !== null) {
                    window.ws.onmessage = (evt) => {
                        const message = JSON.parse(evt.data);
                        this.lastRecievedWsMsg = new Date().getTime();
                        this.props.setWsMsg(message);
                    }

                    window.ws.onclose = (evt) => {
                        window.ws = null;
                        this.props.setWsActivity(false);
                        //Log('onclose');
                        //Log(evt);
                    }

                    window.ws.onoffline = (evt) => {
                        //Log('onoffline');
                        //Log(evt);
                    }

                    window.ws.onopen = (evt) => {
                        //Log('onopen');
                        this.props.setWsActivity(true);
                        //Log(evt);
                    }
                    window.ws.onerror = (evt) => {
                        window.ws = null;
                        this.props.setWsActivity(false);
                        //Log('onerror');
                        //Log(evt);
                    }

                    window.ws.onpagehide = (evt) => {
                        Log('onpagehide');
                        Log(evt);
                    }

                    window.ws.onpageshow = (evt) => {
                        Log('onpageshow');
                        Log(evt);
                    }

                    window.ws.onunload = (evt) => {
                        Log('onunload');
                        Log(evt);
                    }

                    window.addEventListener("beforeunload", function () {
                        if (window.ws !== null) {
                            Log("Close web socket");
                            window.ws.close();
                        }
                    });
                }
            }
        }
    }

    checkWebsocket = () => {
        if (window.ws !== null) {
            if (window.ws.readyState === 1) {
                var current = new Date().getTime();
                var result = current - this.lastRecievedWsMsg;
                if (result > 10000) {
                    window.ws.close();
                }
            }
        }
    }

    initializeWS = () => {
        this.reconnectWebsocket();
        this.wsInterval = setInterval(this.reconnectWebsocket, 35000);
        this.wsCheckConnectionInterval = setInterval(this.checkWebsocket, 5000);
    }

    setIsPopoverOpen = (value, text) => {
        if(text === 'posts') {
            this.setState({isPopoverOpen: value});
        } else {
            this.setState({isPopoverOpenNotification: value});
        }
    }

    render() {
        const IEStyle = {
            position: 'absolute',
            top: '0',
            left: '0',
            minHeight: '100%',
            right: '0',
            color: 'black',
            fontSize: "1rem",
            backgroundColor: 'white',
            padding: '5px 20px 20px 20px',
        }

        const ieImg = {
            height: '5.5rem',
        }

        const otherImg = {
            height: '10rem',
        }

        // For Internet Explorer
        if(isIE) {
            return (<div style={IEStyle}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img style={ieImg} src={ie} alt="IE" />
                            <img style={otherImg} src={otherBrowsers} alt="Other browsers" />
                        </div>
                        <p>
                        <b>SLO: </b><br/>
                        Informacijski sistem SodneDrazbe.si v tem brskalniku ne deluje. Uporabite enega izmed modernih brskalnikov, kot je aktualna verzija Microsoft Edge, Google Chrome, Mozilla Firefox, aktualno verzijo Apple Safari ali podobno.<br/>
                        Informacijski sistem ne deluje v nepodprtih brskalnikih Microsoft IE (Internet Explorer) ali drugih starejših brskalnikih.
                        <br/>
                        <br/>
                        <b>ENG: </b><br/>
                        Information System SodneDrazbe.si system does not work in this browser. Use one of the modern browsers, such as the current version of Microsoft Edge, Google Chrome, Mozilla Firefox, the current version of Apple Safari or similar.<br/>
                        The information system does not work on unsupported Microsoft IE browsers (Internet Explorer) or other older browsers.
                        <br/>
                        <br/>
                        <b>NEM: </b><br/>
                        Das Informationssystem SodneDrazbe.si funktioniert in diesem Browser nicht. Verwenden Sie einen der modernen Browser, z. B. die aktuelle Version von Microsoft Edge, Google Chrome, Mozilla Firefox, die aktuelle Version von Apple Safari oder ähnliches.<br/>
                        Das Informationssystem funktioniert nicht mit nicht unterstützten Microsoft IE-Browsern (Internet Explorer) oder anderen älteren Browsern.
                        <br/>
                        <br/>
                        <b>HUN: </b><br/>
                        A SodneDrazbe.si információs rendszer nem működik ebben a böngészőben. Használja a modern böngészők egyikét, például a Microsoft Edge, a Google Chrome, a Mozilla Firefox, az Apple Safari aktuális verzióját vagy hasonlót.<br/>
                        Az információs rendszer nem működik nem támogatott Microsoft IE böngészőkön (Internet Explorer) vagy más régebbi böngészőkön.
                        <br/>
                        <br/>
                        <b>ITA: </b><br/>
                        Il sistema informativo SodneDrazbe.si non funziona in questo browser. Utilizza uno dei browser moderni, come la versione corrente di Microsoft Edge, Google Chrome, Mozilla Firefox, la versione corrente di Apple Safari o simili.<br/>
                        Il sistema informativo non funziona su browser Microsoft IE (Internet Explorer) non supportati o altri browser meno recenti.
                        </p>
                        <br/>
                        <br/>
                    </div>);
        }

        if (this.state.jwt_token === null) {
            return (<Loading />);
        }
        if (this.state.jwt_token.length !== 0) {
            if (this.props.options.parsedToken.vashRoleId !== undefined) {
                return (
                    <Fragment>
                        <CookieConsent />
                        <div className="container-fluid">
                            <div id="layout-wrapper">
                                <Header sign={this.sign} />
                                <Leftside
                                    listParams={null}
                                    type="court"
                                    sign={this.sign}
                                    reloadPagination={this.reloadPagination}
                                />
                                <div className="main-content">
                                    <div className="page-content">
                                        <Switch>
                                            <Route path="/subscriber" exact render={() => <SubscriberDashboard />} />

                                            <Route path="/loading" exact render={() => <Loading />} />

                                            <Route path="/subscriber/stats" exact render={(env) => <Suspense fallback={<Loading />}><InternalStat hash={Date.now()} /></Suspense>} />
                                            <Route path="/subscriber/drafts/my-list" exact render={(env) => <Suspense fallback={<Loading />}><DraftList hash={Date.now()} /></Suspense>} />
                                            <Route path="/subscriber/drafts/all-list" exact render={() => <Suspense fallback={<Loading />}><DraftList hash={Date.now()} /></Suspense>} />

                                            <Route path="/subscriber/drafts/create2/:id?" component={() => <Create2 hash={Date.now()} />} />

                                            <Route path="/subscriber/published/my-list" exact render={(env) => <Suspense fallback={<Loading />}><PublishedList options={this.props.options} hash={Date.now()} /></Suspense>} />
                                            <Route path="/subscriber/published/all-list" exact render={() => <Suspense fallback={<Loading />}><PublishedList options={this.props.options} hash={Date.now()} /></Suspense>} />
                                            <Route path="/subscriber/published/update/:id" exact render={(props) => <Suspense fallback={<Loading />}><PublishedUpdate {...props} /></Suspense>}/>

                                            <Route path="/subscriber/published/single/bidder/:id" exact render={(props) => <Suspense fallback={<Loading />}><PublishedSingleBidder {...props} /></Suspense>} />
                                            <Route path="/subscriber/published/single/:id" exact render={(props) => <Suspense fallback={<Loading />}><PublishedSingle {...props} /></Suspense>} />

                                            <Route path="/subscriber/finished/my-list/" exact render={(props) => <Suspense fallback={<Loading />}><FinishedList hash={Date.now()} {...props} /></Suspense>} />
                                            <Route path="/subscriber/finished/all-list/" exact render={(props) => <Suspense fallback={<Loading />}><FinishedList hash={Date.now()} {...props} /></Suspense>} />
                                            <Route path="/subscriber/finished/enterData/:id?" exact render={(props) => <Suspense fallback={<Loading />}><FinishedEnterData {...props} /></Suspense>} />

                                            <Route path="/subscriber/published/cancel/:id/:status" exact render={(props) => <Suspense fallback={<Loading />}><PublishedCancel {...props} /></Suspense>} />

                                            <Route path="/subscriber/archive/my-list/" exact render={(props) => <Suspense fallback={<Loading />}><ArchiveList hash={Date.now()} {...props} /></Suspense>} />
                                            <Route path="/subscriber/archive/all-list/" exact render={(props) => <Suspense fallback={<Loading />}><ArchiveList hash={Date.now()} {...props} /></Suspense>} />

                                            <Route path="/subscriber/published/bidders/my-list" exact render={(props) => <Suspense fallback={<Loading />}><PublishedBiddersList options={this.props.options} {...props} hash={Date.now()} /></Suspense>} />
                                            <Route path="/subscriber/published/bidders/all-list" exact render={(props) => <Suspense fallback={<Loading />}><PublishedBiddersList options={this.props.options} {...props} hash={Date.now()} /></Suspense>} />
                                            <Route path="/subscriber/published/bidders/single/:id" exact render={(props) => <Suspense fallback={<Loading />}><PublishedBidders options={this.props.options} {...props} /></Suspense>} />
                                            <Route path="/subscriber/profile" exact render={(props) => <Suspense fallback={<Loading />}><SubscriberProfile {...props} /></Suspense>} />
                                            <Route path="/help" exact render={(props) => <Suspense fallback={<Loading />}><Content type="signed" /></Suspense>} />
                                            <Route path="/privacystatement" exact render={(props) => <Suspense fallback={<Loading />}><PrivacyStatement /></Suspense>} />
                                            <Route path="/individualnotice" exact render={(props) => <Suspense fallback={<Loading />}><IndividualNotice /></Suspense>} />

                                            <Route path="/cookies" exact render={(props) => <Suspense fallback={<Loading />}><CookiesContent /></Suspense>} />
                                            <Route path="/selfdiag" exact render={(props) => <Suspense fallback={<Loading />}><SelfDiag {...props}/></Suspense>} />

                                            <Redirect to='/subscriber' />
                                        </Switch>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </Fragment>
                );
            } else if (this.props.options.parsedToken.bidderId !== undefined) {
                return (
                    <Fragment>
                        <CookieConsent />
                        <div className="container-fluid">
                            <div id="layout-wrapper">
                                <Header sign={this.sign} />
                                <Leftside
                                    type="bidder"
                                    reloadBidderList={this.reloadBidderList}
                                    listParams={this.state.listParams}
                                    sign={this.sign}
                                    reloadPagination={this.reloadPagination}
                                />
                                <div className="main-content">
                                    <div className="page-content">
                                        <Switch>
                                            <Route path="/bidder" exact render={() => <span>Namizje</span>} />
                                            <Route path="/search" exact render={(props) => <Suspense fallback={<Loading />}><Search reloadBidderList={this.reloadBidderList} {...props} bidderList={this.state.bidderList} /></Suspense>} />
                                            <Route path="/bidder/profile" exact render={(props) => <Suspense fallback={<Loading />}><Profile {...props} /></Suspense>} />
                                            <Route path="/bidder/newsletter" exact render={(props) => <Suspense fallback={<Loading />}><Newsletter {...props} /></Suspense>} />
                                            <Route path="/bidder/published/list" exact render={(props) => <Suspense fallback={<Loading />}><BidderList {...props} bidderList={this.state.bidderList} reloadPagination={this.reloadPagination} reloadSort={this.reloadSort} bidderListRefresh={this.state.bidderListRefresh} /></Suspense>} />
                                            <Route path="/bidder/published/single/:id/:subjectId?" exact render={(props) => <Suspense fallback={<Loading />}><BidderSingle {...props} /></Suspense>} />
                                            <Route path="/bidder/published/apply/:id" exact render={(props) => <Suspense fallback={<Loading />}><Apply {...props} /></Suspense>} />
                                            <Route path="/bidder/published/apply/fix/:id/showbail/:showbail" exact render={(props) => <Suspense fallback={<Loading />}><ApplyFix {...props} /></Suspense>} />
                                            <Route path="/bidder/signed" exact render={(props) => <Suspense fallback={<Loading />}><SignedList {...props} /></Suspense>} />
                                            <Route path="/bidder/signedended" exact render={(props) => <Suspense fallback={<Loading />}><SignedList options={this.props.options} {...props} /></Suspense>} />
                                            <Route path="/bidder/singlesigned/:id" exact render={(props) => <Suspense fallback={<Loading />}><SingleSigned {...props} /></Suspense>} />
                                            <Route path="/activelist" exact render={(props) => <Suspense fallback={<Loading />}><ActiveList {...props} /></Suspense>} />
                                            <Route path="/activemylist" exact render={(props) => <Suspense fallback={<Loading />}><ActiveList {...props} /></Suspense>} />
                                            <Route path="/cancellist" exact render={(props) => <Suspense fallback={<Loading />}><CancelList {...props} /></Suspense>} />
                                            <Route path="/favouriteslist" exact render={(props) => <Suspense fallback={<Loading />}><FavouritesList {...props} /></Suspense>} />
                                            <Route path="/cookies" exact render={(props) => <Suspense fallback={<Loading />}><CookiesContent /></Suspense>} />
                                            <Route path="/help" exact render={(props) => <Suspense fallback={<Loading />}><Content /></Suspense>} />
                                            <Route path="/privacystatement" exact render={(props) => <Suspense fallback={<Loading />}><PrivacyStatement /></Suspense>} />
                                            <Route path="/individualnotice" exact render={(props) => <Suspense fallback={<Loading />}><IndividualNotice /></Suspense>} />
                                            <Route path="/selfdiag" exact render={(props) => <Suspense fallback={<Loading />}><SelfDiag {...props}/></Suspense>} />

                                            <Redirect from="/single/:id/:subjectId?" to="/bidder/published/single/:id/:subjectId?" />
                                            <Redirect to='/bidder/published/list' />
                                        </Switch>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </Fragment>
                );
            }
        } else {
            return (
                <Fragment>
                    <CookieConsent />
                    {
                        // eslint-disable-next-line
                        env.system_notification_title !== '${X_system_notification_title}' ?
                        <Popover
                            isOpen={this.state.isPopoverOpenNotification}
                            positions={['right']} // preferred positions by priority
                            containerStyle={{"zIndex": "6000"}}
                            content={
                                <div style={{"padding": "10px 20px", "background": "red"}}>
                                    {env.system_notification_text}
                                    <br/>
                                    <button className="btn btn-sm mt-2 popover-close-btn" onClick={() => this.setIsPopoverOpen(!this.state.isPopoverOpenNotification, 'notification')}>{i18n.t('close')}</button>
                                </div>
                            }
                        >
                            <div style={{"position": "absolute", "left": "0px", "top": "150px", "zIndex": "3000", "transform": "rotate(90deg)","transformOrigin": "left bottom", "background": "red", "padding": "10px 20px", "color": "#fff", "borderTopRightRadius": "10px", "borderTopLeftRadius": "10px", "cursor": "pointer"}} onClick={() => this.setIsPopoverOpen(!this.state.isPopoverOpenNotification, 'notification')}>
                                {env.system_notification_title}
                            </div>
                        </Popover>
                        :
                        ""
                    }
                    <div className="container-fluid">
                        <div id="layout-wrapper">
                            <Header sign={this.sign} />
                            <Leftside
                                type="public"
                                reloadBidderList={this.reloadBidderList}
                                listParams={this.state.listParams}
                                reloadPagination={this.reloadPagination}
                            />
                            <div className="main-content">
                                <div className="page-content">

                                    <Switch>
                                        <Route path="/" exact render={(props) => <Suspense fallback={<Loading />}><BidderList {...props} bidderList={this.state.bidderList} reloadPagination={this.reloadPagination} reloadSort={this.reloadSort} bidderListRefresh={this.state.bidderListRefresh} /></Suspense>} />
                                        <Route path="/search" exact render={(props) => <Suspense fallback={<Loading />}><Search options={this.props.options} {...props} reloadBidderList={this.reloadBidderList} /></Suspense>} />
                                        <Route path="/single/:id/:subjectId?" exact render={(props) => <Suspense fallback={<Loading />}><BidderSingle {...props} /></Suspense>} />
                                        <Route path="/activelist" exact render={(props) => <Suspense fallback={<Loading />}><ActiveList {...props} /></Suspense>} />
                                        <Route path="/cancellist" exact render={(props) => <Suspense fallback={<Loading />}><CancelList {...props} /></Suspense>} />
                                        <Route path="/help" exact render={(props) => <Suspense fallback={<Loading />}><Content /></Suspense>} />
                                        <Route path="/privacystatement" exact render={(props) => <Suspense fallback={<Loading />}><PrivacyStatement /></Suspense>} />
                                        <Route path="/individualnotice" exact render={(props) => <Suspense fallback={<Loading />}><IndividualNotice /></Suspense>} />
                                        <Route path="/cookies" exact render={(props) => <Suspense fallback={<Loading />}><CookiesContent /></Suspense>} />
                                        <Route path="/selfdiag" exact render={(props) => <Suspense fallback={<Loading />}><SelfDiag {...props}/></Suspense>} />
                                        <Route path="/stats" exact render={(props) => <Suspense fallback={<Loading />}><PublicStats displayLeftMenu={false}/></Suspense>} />

                                        <Redirect from="/bidder/published/single/:id/:subjectId?" to="/single/:id/:subjectId?" />
                                        <Redirect from="/bidder/singlesigned/:id" to="/single/:id/:subjectId?" />
                                        <Redirect to='/' />
                                    </Switch>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </Fragment>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        options: state.options,
        cookiesConsent: state.cookiesConsent,
        initializedGA: state.initializedGA
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAcceptLanguage: (lng) => dispatch({type: 'SETACCEPTLANUAGE', value: lng}),
        setJwtToken: (token) => dispatch({type: 'SETJWTTOKEN', value: token}),
        initializeGA: () => dispatch({type: 'INITIALIZEGA'}),
        setWsMsg: (message) => dispatch({type: 'SETWSMSG', value: message}),
        setWsActivity: (message) => dispatch({type: 'SETWSACTIVITY', value: message}),
        setInitialTime: (message) => dispatch({type: 'SETTIME', value: message}),
        setDictionary: (dictionary) => dispatch({type: 'SETDICTIONARY', value: dictionary}),
        setScrollType: (type) => dispatch({type: 'SETSCROLLTYPE', value: type}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(withRouter(App)));