import React, {Component, Fragment} from 'react';
import {isNull} from "../../../izUtils";

class TextArea extends Component {
    state = {
        flags: [false, false, false, false],
        translations: {},
        value: this.props.value
    }

    arrayType = 'root';

    localStorageName = 'SODNEDRAZBE.JSData';

    flags = [
        {id: 'de-DE', title: 'German'},
        {id: 'it-IT', title: 'Italian'},
        {id: 'en-GB', title: 'English'},
        {id: 'hu-HU', title: 'Hungarian'}
    ];

    componentDidMount() {
        this.remount();
    }

    onChange = (e) => {
        this.setState({value: e.target.value});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.props.value !== prevProps.value) && (this.props.value !== this.state.value)) {
            this.remount();
        } else {
            /* second update param is used for file sorting - if files have the same description  */
            if (this.props.secondUpdate !== undefined) {
                if (this.props.secondUpdate !== prevProps.secondUpdate) {
                    this.remount();
                }
            }
        }
    }

    remount = () => {
        if (this.props.localData !== undefined) {
            this.localStorageName = this.props.localData;
        }

        let state = {
            value: this.props.value
        };

        this.arrayType = this.props.arrayType;

        /* if any of translations is filled open it */
        if (this.props.translations) {
            const localData = JSON.parse(localStorage.getItem(this.localStorageName));

            let flags = [false, false, false, false];
            let translations = {};
            for(let index = 0; index < this.flags.length; index++) {
                if (this.props.index !== undefined) {
                    if (localData['values'][this.arrayType][this.props.index] !== undefined) {
                        if (localData['values'][this.arrayType][this.props.index][this.props.name + '|' + this.flags[index].id] !== undefined) {
                            if (localData['values'][this.arrayType][this.props.index][this.props.name + '|' + this.flags[index].id].length !== 0) {
                                flags[index] = true;
                            }
                        }
                    }
                } else {
                    if (localData['values'][this.arrayType][this.props.name + '|' + this.flags[index].id] !== undefined) {
                        if (localData['values'][this.arrayType][this.props.name + '|' + this.flags[index].id].length !== 0) {
                            flags[index] = true;
                        }
                    }
                }

                /* values */
                if ((this.props.index !== undefined) && (this.arrayType !== undefined)) {
                    if (localData['values'][this.arrayType][this.props.index] !== undefined) { //why?!
                        if (localData['values'][this.arrayType][this.props.index][this.props.name + '|' + this.flags[index].id] !== undefined) {
                            translations[this.props.name + '|' + this.flags[index].id] = localData['values'][this.arrayType][this.props.index][this.props.name + '|' + this.flags[index].id];
                        }
                    }
                } else {
                    translations[this.props.name + '|' + this.flags[index].id] = localData['values'][this.arrayType][this.props.name + '|' + this.flags[index].id];
                }
            }
            state.translations = translations;
            state.flags = flags;
        }
        this.setState(state);
    }

    translationFieldsChange = (e, name) => {
        let stateTranslations = this.state.translations;
        stateTranslations[name] = e.target.value;
        this.setState({translations: stateTranslations});
        this.props.change(e);
    }

    onKeyUpHandler = (e) => {
        if (e.target.value.length) {
            e.target.classList.remove('mandatory');
        }
    }

    showLanguage = (index) => {
        let flags = this.state.flags;
        flags[index] = true;
        this.setState({flags: flags});
    }

    render() {
        if (this.props.display || (this.props.display === undefined)) {
            let cols = this.props.cols;
            if (cols !== undefined) {
                cols = cols.split('/');
                cols = ['col-' + cols[0], 'col-' + cols[1]];
            } else {
                cols = ['col-md-2', 'col-md-10'];
            }

            let disabled = false;
            if (this.props.setDisabled !== undefined) {
                disabled = true;
            }

            let readOnly = false;
            if (this.props.setReadOnly !== undefined) {
                readOnly = true;
            }

            let labelAppendix = '';
            let inputClass = [];
            if ((this.props.error !== undefined) && !isNull(this.props.error)) {
                if ((this.props.error.code === 'mandatory') && (this.props.value.length === 0)) {
                    inputClass.push(this.props.error.code);
                }
            }

            if (this.props.mandatory) {
                labelAppendix = '*';
            }

            let buttons, fields = undefined;
            if (this.props.translations) {
                buttons = this.flags.map((flag, index) => {
                    return (
                        <img className="flag_button" key={flag.id} onClick={() => this.showLanguage(index)}
                             src={"/assets/images/flags/" + flag.id + ".png"} alt={flag.id} />
                    );
                });

                fields = this.flags.map((flag, index) => {
                    if (this.state.flags[index]) {
                        return (
                            <div className="form-group row" key={"input_" + flag.id}>
                                <label htmlFor={this.props.name + '|' + flag.id}
                                       className={cols[0] + " col-form-label" + (this.props.labelRight !== undefined ? " text-right" : "")}>
                                    <img className="label_flag" src={"/assets/images/flags/" + flag.id + ".png"} alt={flag.id}/>
                                    {this.props.label + ' (' + flag.title + ')'}
                                </label>
                                <div className={cols[1]}>
                                    <textarea
                                        className={this.props.type + " form-control " + this.props.name + '|' + flag.id}
                                        rows="3"
                                        value={this.state.translations[this.props.name + '|' + flag.id]}
                                        onChange={(e) => this.translationFieldsChange(e, this.props.name + '|' + flag.id)}//(e) => this.props.change(e)
                                        id={this.props.name + '|' + flag.id}
                                        name={this.props.name + '|' + flag.id}
                                    />
                                </div>
                            </div>
                        );
                    } else {
                        return null;
                    }
                });
            }

            return (
                <Fragment>
                    <div className="form-group row">
                        <label htmlFor={this.props.name}
                               className={cols[0] + " col-form-label" + (this.props.labelRight !== undefined ? " text-right" : "")}>{this.props.label + labelAppendix}</label>
                        <div className={cols[1] + (this.props.translations ? ' translations' : '')}>
                            <textarea id={this.props.name}
                                      name={this.props.name}
                                      className={this.props.type + " form-control " + inputClass.join(' ')}
                                      rows="3"
                                      onKeyUp={this.onKeyUpHandler}
                                      placeholder={this.props.placeholder}
                                      value={this.state.value} //IN NEEDS TO BE VALUE - NOT DEFAULTVALUE - REMOVE FILE WON'T WORK...
                                      disabled={disabled}
                                      onChange={(e) => this.onChange(e)}
                                      onBlur={this.props.change !== undefined ? (e) => this.props.change(e) : null}
                                      readOnly={readOnly} />
                            {this.props.translations ? (
                                <div className="translation_flags">
                                    {buttons}
                                </div>
                            ) : ''}
                        </div>
                    </div>
                    {fields}
                </Fragment>
            );
        } else {
            return ('');
        }
    }
}

export default TextArea;
