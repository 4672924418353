export const SETACCEPTLANUAGE = 'SETACCEPTLANUAGE';
export const SETJWTTOKEN = 'SETJWTTOKEN';
export const SETDICTIONARY = 'SETDICTIONARY';
export const SETCOOKIECONSENT = 'SETCOOKIECONSENT';
export const INITIALIZEGA = 'INITIALIZEGA';
export const SETWSMSG = 'SETWSMSG';
export const SETTIME = 'SETTIME';
export const SETWSACTIVITY = 'SETWSACTIVITY';
export const SETSCROLLTYPE = 'SETSCROLLTYPE';
export const SETAPPENDITEMS = 'SETAPPENDITEMS';
export const SETSHOWPREVPROPS = 'SETSHOWPREVPROPS';
export const SETBIDDERIDS = 'SETBIDDERIDS';
export const SETLOADINGFILES = 'SETLOADINGFILES';


