import InputSelect from "../../../../hoc/Form/Select/Select";
import InputMulti from "../../../../hoc/Form/MultiCheckbox/MultiCheckbox";
import InputSelect2 from "../../../../hoc/Form/Select/Select2";
import InputText from "../../../../hoc/Form/Input/Text";
import TextArea from "../../../../hoc/Form/TextArea/TextArea";
import {isNull, time_reformat_toMoment} from "../../../../izUtils";
import React from "react";
import InputDatetime from "../../../../hoc/Form/Datetime/Datetime";
import InputYear from "../../../../hoc/Form/Year/Year";
import InputBoolean from "../../../../hoc/Form/Input/Boolean";
import InputAddress from "../../../../hoc/Form/Input/InputAddress";
import {Log} from "../../../../hoc/Log/Log";

export const linker = (input, data, selectHandler, textHandler, datetimeHandler, cols, index, booleanHandler) => {

    let fieldName;
    if (data === undefined) {
        data = input.data;
        selectHandler = input.selectHandler;
        textHandler = input.textHandler;
        datetimeHandler = input.datetimeHandler;
        cols = input.cols;
        index = input.index;
        booleanHandler = input.booleanHandler;
        fieldName = input.input;
    } else {
        fieldName = input;
    }

    let arrayType = 'root';
    if (input.arrayType !== undefined) {
        arrayType = input.arrayType;
    }

    if (data[fieldName] !== undefined) {
        if (data[fieldName].display || (data[fieldName].display === undefined)) {
            switch (data[fieldName].type) {
                case "select":
                    return (<InputSelect key={fieldName} error={data[fieldName].error} cols={cols} name={fieldName} index={index}
                                         label={data[fieldName].label} options={data[fieldName].options} display={data[fieldName].display}
                                         value={(data[fieldName].value === null) ? "0" : data[fieldName].value}
                                         mandatory={data[fieldName].mandatory}
                                         change={selectHandler}/>)
                case "multi":
                    return (<InputMulti key={fieldName} error={data[fieldName].error} cols={cols} name={fieldName} index={index}
                                         label={data[fieldName].label} options={data[fieldName].options} display={data[fieldName].display}
                                         values={(data[fieldName].values === null) ? [] : data[fieldName].values}
                                         mandatory={data[fieldName].mandatory}
                                         change={selectHandler}/>)
                case "float":
                    return (<InputText key={fieldName} type="float" error={data[fieldName].error} cols={cols} name={fieldName} index={index}
                                       label={data[fieldName].label} display={data[fieldName].display}
                                       mandatory={data[fieldName].mandatory}
                                       labelBold={input.labelBold}
                                       setReadOnly={input.setReadOnly} arrayType={arrayType}
                                       value={isNull(data[fieldName].value) ? '' : data[fieldName].value} change={textHandler}/>)
                case "integer":
                    return (
                        <InputText key={fieldName} type="integer" error={data[fieldName].error} cols={cols} name={fieldName} index={index}
                                   label={data[fieldName].label} display={data[fieldName].display}
                                   mandatory={data[fieldName].mandatory} arrayType={arrayType}
                                   value={isNull(data[fieldName].value) ? '' : data[fieldName].value} change={textHandler}/>)
                case "year":
                    return (
                        <InputYear key={fieldName} type="integer" error={data[fieldName].error} cols={cols} name={fieldName} index={index}
                                   label={data[fieldName].label} display={data[fieldName].display}
                                   mandatory={data[fieldName].mandatory}
                                   value={isNull(data[fieldName].value) ? '' : data[fieldName].value} change={textHandler}/>)
                case "date":
                    return (<InputDatetime key={fieldName} showFields={['date']} error={data[fieldName].error} cols={cols} name={fieldName}
                                           index={index} label={data[fieldName].label} display={data[fieldName].display}
                                           value={isNull(data[fieldName].value) ? '' : time_reformat_toMoment(data[fieldName].value)}
                                           mandatory={data[fieldName].mandatory}
                                           change={datetimeHandler} />)
                case "datetime":
                    return (<InputDatetime key={fieldName} error={data[fieldName].error} cols={cols} name={fieldName} index={index}
                                           label={data[fieldName].label} display={data[fieldName].display} comparison={data[fieldName].comparison}
                                           value={isNull(data[fieldName].value) ? '' : time_reformat_toMoment(data[fieldName].value)}
                                           mandatory={data[fieldName].mandatory}
                                           change={datetimeHandler} />)
                case "textarea":
                    return (<TextArea key={fieldName} data={data} type="text" error={data[fieldName].error}
                                      translations={data[fieldName].translation} cols={cols} name={fieldName} index={index}
                                      label={data[fieldName].label} display={data[fieldName].display}
                                      mandatory={data[fieldName].mandatory} arrayType={arrayType}
                                      value={isNull(data[fieldName].value) ? '' : data[fieldName].value} change={textHandler}/>)
                case "boolean":
                    return (<InputBoolean key={fieldName} name={fieldName} cols={cols} label={data[fieldName].label}
                                          mandatory={data[fieldName].mandatory} index={index}
                                          display={data[fieldName].display} checked={isNull(data[fieldName].value) ? null : data[fieldName].value}
                                          change={booleanHandler} />)
                case "relation_address":
                    if (input.index === undefined) {
                        return (<InputAddress mandatory={data[fieldName].mandatory} addresses={input.addresses} error={data[fieldName].error} key={fieldName} name={fieldName} hr={false} label="" cols="4/8" display={data[fieldName].display} value={data[fieldName].value} mainCol="12" change={input.addressChangeRootHandler} />)
                    } else {
                        //return (<InputAddress addresses={input.addresses} hideAddress={input.hideStreet} mandatory={input.subjects[input.index][fieldName].mandatory} error={input.subjects[input.index][fieldName].error} key={fieldName + "_" + input.index} name={fieldName} index={input.index} hr={false} label="" cols="4/8" display={input.subjects[input.index][fieldName].display} value={input.subjects[input.index][fieldName].value} mainCol="12" change={input.addressChangeRootHandler} />)
                        return (<InputAddress addresses={input.addresses} mandatory={input.subjects[input.index][fieldName].mandatory} error={input.subjects[input.index][fieldName].error} key={fieldName + "_" + input.index} name={fieldName} index={input.index} hr={false} label="" cols="4/8" display={input.subjects[input.index][fieldName].display} value={input.subjects[input.index][fieldName].value} mainCol="12" change={input.addressChangeRootHandler} />)
                    }
                case "select2":
                    return (<InputSelect2 key={fieldName} error={data[fieldName].error} cols={cols} name={fieldName} index={index}
                                         label={data[fieldName].label} options={data[fieldName].options} display={data[fieldName].display}
                                         value={(data[fieldName].value === null) ? "0" : data[fieldName].value}
                                         mandatory={data[fieldName].mandatory}
                                         change={selectHandler}/>)
                case "text":
                default:
                    return (<InputText key={fieldName} data={data} type="text" error={data[fieldName].error}
                                       translations={data[fieldName].translation} cols={cols} name={fieldName} index={index}
                                       label={data[fieldName].label} display={data[fieldName].display}
                                       mandatory={data[fieldName].mandatory} arrayType={arrayType}
                                       setReadOnly={data[fieldName].readOnly}
                                       value={isNull(data[fieldName].value) ? '' : data[fieldName].value} change={textHandler}/>)
            }
        } else {
            return null;
        }
    } else {
        Log('LINKER undefined: '+fieldName);
        return null;
    }
}
