import {isNull} from "../../../../izUtils";
import i18n from '../../../../i18n';
import env from '../../../../env/src_config'

export const transformer = (backendArray, index) => {

    let data = {};
    for (let i = 0; i < backendArray.length; i++) {
        if ((backendArray[i].type === "text") ||
            (backendArray[i].type === "numeric") ||
            (backendArray[i].type === "integer") ||
            (backendArray[i].type === "float") ||
            (backendArray[i].type === "relation_file") ||
            (backendArray[i].type === "year") ||
            (backendArray[i].type === "date") ||
            (backendArray[i].type === "datetime") ||
            (backendArray[i].type === "boolean") ||
            (backendArray[i].type === "textarea")) {

            data[backendArray[i].id] = {
                "label": backendArray[i].title,
                "type": backendArray[i].type,
                "value": backendArray[i].value,
                "error": backendArray[i].error,
                "display": !backendArray[i].disabled,
                "mandatory": backendArray[i].mandatory
            }
        } else if ((backendArray[i].type === "select") || (backendArray[i].type === "select2")) {
                if ((backendArray[i].type === "select") && (backendArray[i].options !== null)) {
                    backendArray[i].options.unshift({id: "", title: i18n.t('placeholders.select'), code: ""});
                }

                data[backendArray[i].id] = {
                    "label": backendArray[i].title,
                    "display": !backendArray[i].disabled,
                    "type": backendArray[i].type,
                    "value": backendArray[i].value,
                    "values": backendArray[i].values,
                    "error": backendArray[i].error,
                    "mandatory": backendArray[i].mandatory,
                }

                let JSData = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));
                let itExtists = false;

                if (backendArray[i].options !== null) {
                    data[backendArray[i].id]['options'] =
                        backendArray[i].options.map(option => {
                            if (!isNull(JSData) && (JSData.values.root[backendArray[i].id] !== undefined) && !itExtists && (option.id === JSData.values.root[backendArray[i].id])) {
                                itExtists = true;
                            }

                            return {
                                value: option.id,
                                label: env.is_development ? option.code + ' - ' + option.title : option.title,
                                code: option.code
                            }
                        });
                }

                /* this is to reset selectbox if selected field does not exists */
                if (!isNull(JSData) && (JSData.values.root[backendArray[i].id] !== undefined) && !itExtists) {
                    delete JSData.values.root[backendArray[i].id]
                    localStorage.setItem('SODNEDRAZBE.JSData', JSON.stringify(JSData));
                    data[backendArray[i].id]['value'] = null;
                }
                if (!isNull(JSData) && (JSData.values.subjects !== undefined) && (JSData.values.subjects.length !== 0) && (JSData.values.subjects[index] !== undefined) && (JSData.values.subjects[index][backendArray[i].id] !== undefined) && backendArray[i].options !== null && (index !== undefined)) {
                    let deleteIt = true;
                    for (let j = 0; j < data[backendArray[i].id].options.length; j++) {
                        if (data[backendArray[i].id].options[j].value === JSData.values.subjects[index][backendArray[i].id]) {
                            deleteIt = false;
                            break;
                        }
                    }
                    if (deleteIt) {
                        delete JSData.values.subjects[index][backendArray[i].id]
                        localStorage.setItem('SODNEDRAZBE.JSData', JSON.stringify(JSData));
                        data[backendArray[i].id]['value'] = null;
                    }
                }
        } else if (backendArray[i].type === "relation_address") {
            data[backendArray[i].id] = {
                "label": backendArray[i].title,
                "type": backendArray[i].type,
                "value": backendArray[i].value,
                "error": backendArray[i].error,
                "display": !backendArray[i].disabled,
                "mandatory": backendArray[i].mandatory,
            }
        } else if ((backendArray[i].type === "translation_text") ||
                    (backendArray[i].type === "translation_textarea")){
            const split = backendArray[i].id.split('|');
            data[split[0]].translation = true;
        } else {
            alert("Error: 4756, new Type: " + backendArray[i].type);
        }
    }

    return data;
}

export const emptySubject = (subject) => {

    Object.keys(subject).map(function(key) {
        subject[key].value = null;
        return null;
    });

    return subject;
}

