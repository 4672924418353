import React, {Component} from 'react';
import { withTranslation  } from 'react-i18next'
import {connect} from "react-redux";

class LanguageMenu extends Component {

    changeLanguage = (lang) => {
        const {i18n} = this.props;
        i18n.changeLanguage(lang);
        localStorage.setItem('SODNEDRAZBE.selectedLanguage', lang);
        this.props.setAcceptLanguage(lang);
        window.location.reload(false);
    }

    render() {
        const flags = [
            {id: 'sl-SI', title: 'Slovene'},
            {id: 'de-DE', title: 'German'},
            {id: 'it-IT', title: 'Italian'},
            {id: 'en-GB', title: 'English'},
            {id: 'hu-HU', title: 'Hungarian'}
        ];
        const buttons = flags.map(flag => {
            return (
                <button key={flag.id} className="dropdown-item notify-item" onClick={() => this.changeLanguage(flag.id)}>
                    <img src={"/assets/images/flags/" + flag.id + ".png"} alt="english flag" className="mr-1"
                         height="12"/> <span className="align-middle">{flag.title}</span>
                </button>
            );
        });

        return (
            <div className="dropdown-menu dropdown-menu-right">
                {buttons}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        options: state.options,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAcceptLanguage: (lng) => dispatch({type: 'SETACCEPTLANUAGE', value: lng}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LanguageMenu));
