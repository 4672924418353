import React, { Component, Fragment } from 'react';
import {Link, withRouter} from 'react-router-dom';
import $ from 'jquery';
import LanguageMenu from './LanguageMenu/LanguageMenu';
import CurrentTime from './CurrentTime/CurrentTime';

import {ReactComponent as MainLogo} from '../../assets/img/logo/sodnedrazbe_logo.svg';
import {ReactComponent as SmallLogo} from '../../assets/img/logo/E.svg';
import {ReactComponent as SecondLogo} from '../../assets/img/logo/Sodstvo_RS_mono_inverse.svg';
import env from '../../env/src_config'
import './Header.css';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

class Header extends Component {
    toggleSideMenuBar = () => {
        const body = $("body");
        body.toggleClass("sidebar-enable");
        const closeFilter = document.getElementById('close-filter')
        closeFilter.classList.toggle("close-sidebar");
        if ( 992 <= $(window).width()) {
            body.toggleClass("vertical-collpsed");
        } else {
            body.removeClass("vertical-collpsed")
        }
    }

    componentDidMount() {
        document.addEventListener('click', (e) => {
            if(e.target.id === "close-filter" ) {
                const body = $("body");
                body.removeClass("sidebar-enable");
                e.target.classList.remove("close-sidebar");
            }
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.options) !== JSON.stringify(this.props.options)) {
            this.render();
        }
    }

    rss = () => {
        window.open(env.api.api + '/public/publication/feed?type=rss','_blank');
    }

    render() {
        // let elem = document.body;
        // if (elem.requestFullscreen) {
        //     elem.requestFullscreen();
        // } else if (elem.webkitRequestFullscreen) { /* Safari */
        //     elem.webkitRequestFullscreen();
        // } else if (elem.msRequestFullscreen) { /* IE11 */
        //     elem.msRequestFullscreen();
        // }
        const {t} = this.props;
        let userBtn = '';
        let headerMenu = '';
        if (this.props.options.parsedToken !== undefined) {
            if (this.props.options.parsedToken.bidderId !== undefined) {
                headerMenu =
                    <div className="dropdown d-inline-block">
                        <button type="button" className="btn header-item waves-effect biggerIcon"
                                id="page-header-user-dropdown" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                            <span className="d-inline-block ml-1 user_name" />
                            <i className="mdi mdi-human-greeting d-inline-block" title={t('header.user')} />
                        </button>
                        <div className="dropdown-menu dropdown-menu-right">
                            <Link className="dropdown-item d-block" to="/bidder/profile">
                                <i className="mdi mdi-human-male-female mr-1" />
                                {t('header.myProfile')}
                            </Link>
                            <Link className="dropdown-item d-block" to="/bidder/signed">
                                <i className="mdi mdi-file-document-box-check mr-1" />
                                {t('apply.mytitle')}
                            </Link>
                            <Link className="dropdown-item d-block" to="/bidder/newsletter">
                                <i className="mdi mdi-email-newsletter mr-1" />
                                {t('leftSide.bidder.newsletter')}
                            </Link>
                            <span className="dropdown-item d-block" onClick={() => this.props.sign(null, "logout")} style={{cursor: "pointer"}}>
                                <i className="mdi mdi-exit-run mr-1" />
                                {t('header.logout')}
                            </span>
                        </div>
                    </div>;
            } else {
                headerMenu =
                    <div className="dropdown d-inline-block">
                        <button type="button" className="btn header-item waves-effect biggerIcon"
                                id="page-header-user-dropdown" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                            <i className="mdi mdi-human-greeting d-inline-block" title={t('header.user')} />
                        </button>
                        <div className="dropdown-menu dropdown-menu-right">
                            {
                               /* (this.props.options.parsedToken.vashRoleId === 303) || (this.props.options.parsedToken.vashRoleId === 304) ?
                                    <Link className="dropdown-item d-block" to="/subscriber/profile">
                                        <i className="mdi mdi-human-male-female mr-1" />
                                        Moj profil
                                    </Link>
                                    : "" */
                            }
                            <span className="dropdown-item d-block" onClick={() => this.props.sign(null, "logout")} style={{cursor: "pointer"}}>
                                <i className="mdi mdi-exit-run mr-1" />
                                {t('header.logout')}
                            </span>
                        </div>
                    </div>;
            }

        } else {
            if (this.props.cookiesConsent.indexOf('token') !== -1) {
                userBtn =
                    <Fragment>
                        <div className="d-none d-lg-inline-block">
                            {!env.is_public ? <button className="btn btn-login mr-2" onClick={() => this.props.sign("subscriber", "login")}><i className="mdi mdi-login"></i> {t('header.subscriber')}</button> : null}
                            <button className="btn btn-login mr-2" onClick={() => this.props.sign("bidder", "login")}><i className="mdi mdi-login"></i> {t('header.bidder')}</button>
                        </div>
                        <div className="d-inline-block d-lg-none d-xl-none">
                            <button type="button" className="btn header-item noti-icon waves-effect" onClick={() => this.props.sign("bidder", "login")}>
                                <i className="mdi mdi-account-key-outline" title={t('header.bidder')} />
                            </button>
                        </div>
                    </Fragment>;
            }
        }

        return (
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="container-fluid">

                        <div className="header-flex">

                            <div>
                                <div className="navbar-brand-box">
                                    <Link to={"/" + window.location.search} className="logo logo-light">
                                        <span className="logo-sm">
                                            <SmallLogo className="edrazbeLogo" />
                                        </span>
                                        <span className="logo-lg">
                                            <MainLogo className="edrazbeLogo" />
                                        </span>
                                    </Link>
                                </div>

                                <button type="button"
                                        className="btn btn-sm px-3 font-size-16 header-item toggle-btn waves-effect"
                                        id="vertical-menu-btn"
                                        onClick={this.toggleSideMenuBar}>
                                    <i className="fa fa-fw fa-bars" title={t('header.menu')} />
                                </button>
                            </div>

                            <div className="header-logo">
                                <div style={{ width: '150px', top: '20px' }} className="navbar-brand-box">
                                    <span className="second-logo">
                                        <a href="https://sodisce.si" target="_blank" rel="noreferrer">
                                            <SecondLogo className="edrazbeLogo" />                                    
                                        </a>
                                    </span>
                                </div>
                            </div>

                            <div>
                                {userBtn}
                                <CurrentTime />
                                <div className="dropdown d-inline-block">
                                    <button type="button" className="btn header-item waves-effect" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img className="" src={"/assets/images/flags/"+localStorage.getItem('SODNEDRAZBE.selectedLanguage')+".png"} alt={t('header.language')} title={t('header.language')} height="16" />
                                    </button>
                                    <LanguageMenu />
                                </div>

                                <div className="dropdown d-inline-block ml-1">
                                    <button type="button" className="btn header-item noti-icon waves-effect" onClick={this.rss} title={t('header.RSSfeed')}>
                                        <i className="mdi mdi-rss-box" />
                                    </button>
                                    <button type="button" className="btn header-item waves-effect biggerIcon" onClick={() => this.props.history.push({pathname: '/help'})}>
                                        <i className="mdi mdi-help" title={t('header.help')} />
                                    </button>
                                </div>
                                {headerMenu}
                            </div>

                        </div>

                    </div>
                </div>
            </header>
        );
    }
}

const mapStateToProps = state => {
    return {
        options: state.options,
        cookiesConsent: state.cookiesConsent
    }
}

export default connect(mapStateToProps)(withRouter(withTranslation()(Header)));
