export const countErrors = (fields, errors, type) => {
    let cnt = 0;
    if (errors === null) {
        return cnt;
    }
    for(let i = 0; i < errors[type].length; i++) {
        if (fields.indexOf(errors[type][i]) !== -1) {
            cnt += 1;
        }
    }

    return cnt;
}

export const countGroupErrors = (errors, type) => {
    if (errors === null) {
        return 0;
    } else {
        let cnt = 0;

        const keys = Object.keys(errors[type]);
        for(let i = 0; i < keys.length; i++) {
            cnt += errors[type][keys[i]].length;
        }
        return cnt;
    }
}
