import React, {Component, Fragment} from 'react';
import {withTranslation} from'react-i18next';
import {isNull, number_format, eurFields} from "../../../izUtils";

class InputText extends Component {

    state = {
        flags: [false, false, false, false],
        inputValue: ''
    }

    isNumeric = (e, type, otherFields) => {
        /* allow only one comma */
        if ((e.which === 188) || (e.which === 110)) {
            for (let i = 0; i < e.target.value.length;i++) {
                if (e.target.value[i] === ',') {
                    e.preventDefault();
                }
            }
        }

        if (otherFields !== undefined) {
            if (otherFields.indexOf(e.key) !== -1) {
                return true;
            }
        }

        if (e.shiftKey) {
            e.preventDefault();
        }

        if ((((e.which >= 48) && (e.which <= 57)) ||
            (e.which === 8) || /* backspace */
            (e.which === 9) || /* tab */
            ((e.which >= 37) && (e.which <= 40)) || /* arrows */
            ((e.which >= 96) && (e.which <= 105)) /* num pad */
            )
        ) {
        } else {
            if (type === "float") {
                if (((e.which === 190) || (e.which === 188)) || /* dot and comma */
                    (e.which === 110) /* num pad comma */) {
                } else {
                    e.preventDefault();
                }
            } else {
                e.preventDefault();
            }
        }
    }

    inputElement = null;

    arrayType = 'root';

    flags = [
        {id: 'de-DE', title: 'German'},
        {id: 'it-IT', title: 'Italian'},
        {id: 'en-GB', title: 'English'},
        {id: 'hu-HU', title: 'Hungarian'}
    ];

    formatFloatNumber = (e) => {
        if (this.props.type === "float") {
            if (e.target.value.length !== 0) {
                let val = e.target.value.replace(/\./g, '');
                val = val.replace(',', '.');
                val = number_format(val, 2, ',', '.');
                this.setState({inputValue: val});
            }
        }
        this.props.change(e);
    }

    formatIntegerNumber = (e) => {
        let val = e.target.value
        while (val.charAt(0) === "0") {
            val = val.substring(1);
        }
        this.setState({inputValue: val});
        e.target.value = val;
        this.props.change(e);
    }

    changeTextInput = (e, numberChecker) => {
        this.setState({inputValue: e.target.value});
        /*if (!numberChecker) {
            this.props.change(e);
        }*/
    }

    setValue = (value) => {
        this.setNativeValue(this.inputElement, value);

        this.inputElement.dispatchEvent(new Event('input', { bubbles: true }));
        const e = {
            target: {
                value: value,
                name: this.inputElement.name
            }
        }
        this.props.change(e);
    }

    setNativeValue(element, value) {
        const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
        const prototype = Object.getPrototypeOf(element);
        const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;

        if (valueSetter && valueSetter !== prototypeValueSetter) {
            prototypeValueSetter.call(element, value);
        } else {
            valueSetter.call(element, value);
        }
    }

    componentDidMount() {
        let value = this.props.value;

        this.arrayType = this.props.arrayType;

        if (this.props.type === "float") {
            if (value.length !== 0) {
                value = number_format (value, 2, ',', '.');
            }
        }

        let state = {
            inputValue: value
        };

        /* if any of translations is filled open it */
        if (this.props.translations) {
            const localData = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));

            let flags = this.state.flags;
            for(let index = 0; index < this.flags.length; index++) {
                if (this.props.index !== undefined) {
                    if (localData['values'][this.arrayType][this.props.index] !== undefined) {
                        if (localData['values'][this.arrayType][this.props.index][this.props.name + '|' + this.flags[index].id] !== undefined) {
                            flags[index] = true;
                        }
                    }
                } else {
                    if (localData['values'][this.arrayType][this.props.name + '|' + this.flags[index].id] !== undefined) {
                        flags[index] = true;
                    }
                }
            }
            state.flags = flags;
        }
        this.setState(state);
    }

    showLanguage = (index) => {
        let flags = this.state.flags;
        flags[index] = true;
        this.setState({flags: flags});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.value !== this.props.value) && (this.state.inputValue !== this.props.value)) {
            if (this.props.type === "float") {
                this.setState({inputValue: number_format(this.props.value, 2, ',', '.')});
            } else {
                this.setState({inputValue: this.props.value});
            }
        }
    }

    render() {
        const {t} = this.props;
        if (this.props.display || (this.props.display === undefined)) {
            let cols = this.props.cols;
            if (cols !== undefined) {
                cols = cols.split('/');
                cols = ['col-' + cols[0], 'col-' + cols[1]];
            } else {
                cols = ['col-md-2', 'col-md-10'];
            }

            let disabled = false;
            if (this.props.setDisabled !== undefined) {
                disabled = true;
            }

            let readOnly = false;
            if ((this.props.setReadOnly !== undefined) && (this.props.setReadOnly !== false)) {
                readOnly = true;
            }

            let inputClass = '';

            let labelAppendix = '';
            if (this.props.name === 'area') {
                labelAppendix = ' (m2)';
            } else if (eurFields().indexOf(this.props.name) > -1) {
                labelAppendix = ' (EUR)';
            }

            if ((this.props.error !== undefined) && !isNull(this.props.error)) {
                if ((this.props.error.code.length)) {
                    if (this.props.error.code === 'FEmandatory') {
                        if (this.state.inputValue.length === 0) {
                            inputClass = ' mandatory';
                        }
                    } else {
                        inputClass = ' mandatory';
                    }
                }
            }

            if (this.props.mandatory) {
                labelAppendix += '*';
            }

            let numberChecker = false;
            if ((this.props.type === 'integer') || (this.props.type === 'float')) {
                numberChecker = true;
            }

            let numberCheckerOtherKeys;
            if ((this.props.name === "hangingBuildingNumber") || (this.props.name === "parcelNumber")) {
                numberChecker = true;
                numberCheckerOtherKeys = ['/', '*'];
            }

            let buttons, fields = undefined;
            if (this.props.translations) {
                const localData = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));

                buttons = this.flags.map((flag, index) => {
                    return (
                        <img className="flag_button" key={flag.id} alt={flag.id} onClick={() => this.showLanguage(index)} src={"/assets/images/flags/" + flag.id + ".png"}/>
                    );
                });

                fields = this.flags.map((flag, index) => {
                    if (this.state.flags[index]) {
                        let value = '';
                        if (this.props.index !== undefined) {
                            if (localData['values'][this.arrayType][this.props.index][this.props.name + '|' + flag.id] !== undefined) {
                                value = localData['values'][this.arrayType][this.props.index][this.props.name + '|' + flag.id];
                            } else {
                                value = localData['values'][this.arrayType][this.props.name + '|' + flag.id];
                            }
                        }
                        return (
                            <div className="form-group row" key={"input_" + flag.id}>
                                <label htmlFor={this.props.name + '|' + flag.id}
                                       className={cols[0] + " col-form-label" + (this.props.labelRight !== undefined ? " text-right" : "")}>
                                    <img className="label_flag" src={"/assets/images/flags/" + flag.id + ".png"} alt={flag.id} />
                                    {this.props.label + ' (' + flag.title + ')'}
                                </label>
                                <div className={cols[1]}>
                                    <input
                                        className={this.props.type + " form-control " + this.props.name + '|' + flag.id}
                                        type="text"
                                        defaultValue={value}
                                        onChange={(e) => this.props.change(e)}
                                        id={this.props.name + '|' + flag.id}
                                        name={this.props.name + '|' + flag.id}
                                    />
                                </div>
                            </div>
                        );
                    } else {
                        return null;
                    }
                });
            }

            let placeHolder = t('placeholders.inputData');

            let onBlur = (e) => this.props.change(e);
            let value = this.state.inputValue;
            if (this.props.type === "float") {
                onBlur = this.formatFloatNumber;
                placeHolder = t('placeholders.inputData');
            } else if (this.props.type === "integer") {
                placeHolder = t('placeholders.inputNumber');
                onBlur = this.formatIntegerNumber;
            }

            let fontBold = '';
            if ((this.props.labelBold !== undefined) && (this.props.labelBold === true)) {
                fontBold = 'font-weight-bold ';
            }

            let mainClass = "";
            if (this.props.mainClass !== undefined) {
                mainClass = " "+this.props.mainClass;
            }
            return (
                <Fragment>
                    <div className={"form-group row"+mainClass}>
                        <label htmlFor={this.props.name+(this.props.index !== undefined ? this.props.index : '')}
                               className={fontBold + cols[0] + " col-form-label" + (this.props.labelRight !== undefined ? " text-right" : "")}>{this.props.label + labelAppendix}</label>
                        <div className={cols[1] + (this.props.translations ? ' translations' : '')}>
                            <input
                                className={this.props.type + " form-control" + inputClass}
                                type="text"
                                disabled={disabled}
                                readOnly={readOnly}
                                placeholder={(this.props.name === 'shareInTheBasicLegalPosition') ? t('placeholders.inputFragment') : placeHolder}
                                value={value}
                                onKeyDown={numberChecker ? (e) => this.isNumeric(e, this.props.type, numberCheckerOtherKeys) : this.props.onkeydown}
                                onKeyUp={this.onKeyUpHandler}
                                onChange={readOnly ? null : (e) => this.changeTextInput(e, numberChecker)}
                                ref={input => { this.inputElement = input }}
                                onBlur={readOnly ? null : onBlur}
                                id={this.props.name+(this.props.index !== undefined ? this.props.index : '')}
                                name={this.props.name}
                                autoComplete="off"
                            />
                            {(this.props.name === 'dynamicExtensionInterval') ? <i> ({t('auctionExtendedBeforeEnd')})</i> : '' }
                            {(this.props.name === 'shareInTheBasicLegalPosition') ?
                                <Fragment>
                                    <div className="mt-2">{t('preSelection')}:</div>
                                    <div className="btn-group mt-2 btn-group-sm" role="group">
                                        <button type="button" className="btn btn-primary" onClick={() => this.setValue('1/1')}>1/1</button>
                                        <button type="button" className="btn btn-primary" onClick={() => this.setValue('1/2')}>1/2</button>
                                        <button type="button" className="btn btn-primary" onClick={() => this.setValue('1/3')}>1/3</button>
                                        <button type="button" className="btn btn-primary" onClick={() => this.setValue('1/4')}>1/4</button>
                                    </div>
                                </Fragment>
                                : ''}
                            {this.props.translations ? (
                                <div className="translation_flags">
                                    {buttons}
                                </div>
                            ) : ''}
                        </div>
                    </div>
                    {fields}
                </Fragment>
            );
        } else {
            return ('');
        }
    }
}

export default withTranslation()(InputText);
