import React, {Component} from 'react';
import {isNull} from "../../../izUtils";
import {withTranslation} from 'react-i18next';

class InputSelect extends Component {

    state = {
        val: null
    }

    cols = ['col-md-2', 'col-md-10'];
    disabled = null;
    readOnly = null;

    options = null;

    change(e) {
        this.setState({val: e.target.value});
        this.props.change(e, this.props.index);
    }

    componentDidMount() {
        this.reloadComponent();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.options !== this.props.options) {
            this.reloadComponent();
        } else if ((prevProps.value !== this.props.value) && (this.state.val !== this.props.value)) {
            this.reloadComponent();
        }
    }

    reloadComponent(){
        if (this.props.display || (this.props.display === undefined)) {
            if ((this.props.options === undefined) || (this.props.options === null)) {
                const {t} = this.props;
                this.options = [{value: null, label: t('placeholders.select')}].map((option, key) => {
                    return (
                        <option
                            key={this.props.name + '_' + option.value + '_' + key}
                            value={option.value}>
                            {option.label}
                        </option>
                    );
                });
            } else {
                this.options = this.props.options.map((option, key) => {
                    return (
                        <option
                            key={this.props.name + '_' + option.value + '_' + key}
                            value={option.value}
                            data-code={option.code}>
                            {option.label}
                        </option>
                    );
                });
            }

            if (this.props.cols !== undefined) {
                this.cols = this.props.cols;
                if (this.cols !== undefined) {
                    this.cols = this.cols.split('/');
                    this.cols = ['col-' + this.cols[0], 'col-' + this.cols[1]];
                }
            }

            this.disabled = false;
            if (this.props.setDisabled !== undefined) {
                this.disabled = true;
            }

            this.readOnly = false;
            if (this.props.setReadOnly !== undefined) {
                this.readOnly = true;
            }

            let val = this.props.value;
            if ((this.options.length === 2) && (this.props.value === "0")) {
                if (this.options[1].props.value !== undefined) {
                    this.change({target: {name: this.props.name, value: this.options[1].props.value}}, this.props.index);
                } else if (this.options[1].value !== undefined) {
                    this.change({target: {name: this.props.name, value: this.options[1].value}}, this.props.index);
                }
            } else {
                this.setState({val: val});
            }
        }
    }

    render() {
        if (this.state.val !== null) {
            if (this.props.display || (this.props.display === undefined)) {

                let labelAppendix = '';
                let inputClass = [];
                if ((this.props.error !== undefined) && !isNull(this.props.error)) {
                    if ((this.props.error.code === 'mandatory') || (this.props.error.code === 'uuid')) {
                        if ((this.state.val === 0) || (this.state.val === "0") || (this.state.val.length === 0)) {
                            inputClass.push('mandatory');
                        }
                    } else if (this.props.error.code === 'invalid_option') {
                        inputClass.push('mandatory');
                    }
                }

                let mainClass = ["form-group", "row"];
                if (this.props.mainClass !== undefined) {
                    mainClass = mainClass.concat(this.props.mainClass);
                }

                if (this.props.mandatory) {
                    labelAppendix = '*';
                }

                return (
                    <div className={mainClass.join(' ')}>
                        <label htmlFor={this.props.name}
                               className={this.cols[0] + " col-form-label" + (this.props.labelRight !== undefined ? " text-right" : "")}>{this.props.label + labelAppendix}</label>
                        <div className={this.cols[1]}>
                            <select
                                className={"custom-select " + inputClass.join(' ')}
                                disabled={this.disabled}
                                readOnly={this.readOnly}
                                value={this.state.val}
                                id={this.props.name + ((this.props.index !== undefined) ? '_' + this.props.index : '')}
                                name={this.props.name}
                                onChange={(e) => this.change(e)}>
                                {this.options}
                            </select>
                        </div>
                    </div>
                );
            } else {
                return ('');
            }
        } else {
            return ('');
        }
    }
};

export default withTranslation()(InputSelect);
