import React, {Component} from 'react';
import {isNull} from "../../../izUtils";
import moment from "moment";
import {withTranslation} from 'react-i18next';
import { DatePicker } from 'antd';

class InputYear extends Component {

    state = {
        labelAppendix: '',
        inputClass: []
    }

    disabledYearFuture = (current) => {
        if (current && current > moment().endOf('day')) {
            return true;
        } else if (current && current < moment('01/01/1500')){
            return true;
        }

        return false;
    }

    mandatoryField() {
        let labelAppendix = '';
        let inputClass = [];
        if ((this.props.error !== undefined) && !isNull(this.props.error)) {
            if ((this.props.error.code === 'mandatory') && ((this.props.value === undefined) || (this.props.value.length === 0))) {
                inputClass.push(this.props.error.code);
            }
        }

        if (this.props.mandatory) {
            labelAppendix = '*';
        }

        this.setState({labelAppendix: labelAppendix});
        this.setState({inputClass: inputClass});
    }

    componentDidMount() {
        this.mandatoryField();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value) {
            this.mandatoryField();
        }
    }

    onChange = (e) => {
        this.props.change(e, this.props.name);
    }

    render() {
        if (this.props.display || (this.props.display === undefined)) {
            let cols = this.props.cols;
            if (cols !== undefined) {
                cols = cols.split('/');
                cols = ['col-' + cols[0], 'col-' + cols[1]];
            } else {
                cols = ['col-md-2', 'col-md-10'];
            }

            let yearValue = '';
            if ((this.props.value !== undefined) && (this.props.value.length !== 0)) {
                yearValue = moment({year :this.props.value});
            }

            const {t} = this.props;

            return (
                <div className="form-group row dateTimeWrapper" >
                    <label htmlFor={this.props.name}
                           className={cols[0] + " col-form-label" + (this.props.labelRight !== undefined ? " text-right" : "")}>{this.props.label + this.state.labelAppendix}</label>
                    <div className={cols[1]}>
                        <DatePicker picker="year" placeholder={t('placeholders.selectYear')} value={yearValue} disabledDate={this.disabledYearFuture} className={this.state.inputClass.join(' ')} onChange={(e) => this.onChange(e)} style={{width: "100%"}}/>
                    </div>
                </div>
            );
        } else {
            return ('');
        }
    }
};
export default withTranslation()(InputYear);
