import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import {number_format, getUserRole, setSearchParams, getSearchParams} from '../../izUtils';
import {Log} from '../Log/Log'

import Menu from "./Menu/Menu";

import classes from './Leftside.module.css';
import axios from "axios";
import env from "../../env/src_config";
import axiosErrorResponse from "../AxiosErrorResponse/AxiosErrorResponse";
import UserTimeout from "./UserTimeout/UserTimeout"
import {CheckboxHandler} from "../../Bidder2/Search/CommonFunctions/CheckboxHandler";
import {connect} from "react-redux";

class Leftside extends Component {

    state = {
        type: null,
        filter: null,
        listParams: null
    }

    range = {
        from: null,
        to: null
    }

    localStorageName = 'SODNEDRAZBE.filterDisplay';
    localStorageNameCalled = 'SODNEDRAZBE.filterDisplayCalled';
    localStorageResponse = 'SODNEDRAZBE.publication_list_response';

    filtersDisplay = ["court", "saleForm", "saleMethod", "saleType", "saleSubject", "subjectType", "startingPrice", "status"];

    checkboxHandle = (e, filter, value) => {
        CheckboxHandler(this.localStorageName, e, filter, value);
        setSearchParams(this.localStorageName, this.props.history);
        this.getFilter();
    }

    rangeChange = (array) => {
        let localStorageFilter = JSON.parse(localStorage.getItem(this.localStorageName));

        if (localStorageFilter.filter['startingPrice'] === undefined) {
            localStorageFilter.filter['startingPrice'] = [];
        }

        if (array[0] === 0) {
            localStorageFilter.filter['startingPrice'][0] = "";
        } else {
            localStorageFilter.filter['startingPrice'][0] = ((this.range.to)*(array[0])/100).toString();
        }

        if (array[1] === 100) {
            localStorageFilter.filter['startingPrice'][1] = "";
        } else {
            localStorageFilter.filter['startingPrice'][1] = ((this.range.to)*(array[1])/100).toString();
        }

        if ((localStorageFilter.filter['startingPrice'][0].length === 0) && (localStorageFilter.filter['startingPrice'][1].length === 0)) {
            delete localStorageFilter.filter['startingPrice'];
        }
        localStorageFilter.pagination = {offset: 1, limit: 25}
        localStorage.setItem(this.localStorageName, JSON.stringify(localStorageFilter));
    }

    rangeAfterChange = () => {
        setSearchParams(this.localStorageName, this.props.history);
        this.getFilter();
    }

    timestamp = 0;
    getFilter(passedParams) {
        let localStorageFilter = localStorage.getItem(this.localStorageName);
        if (localStorageFilter === null) {
            localStorageFilter = {
                "filter": {},
                "sort": {
                    "by": "publicationAt",
                    "direction": "desc"
                },
                "pagination": {
                    "offset": 0,
                    "limit": 25
                }
            };
            localStorage.setItem(this.localStorageName, JSON.stringify(localStorageFilter));
        } else {
            localStorageFilter = JSON.parse(localStorageFilter);
        }

        if (localStorageFilter.pagination !== undefined) {
            if (localStorageFilter.pagination.offset !== 0) {
                localStorageFilter.pagination.offset = (localStorageFilter.pagination.offset * localStorageFilter.pagination.limit)-localStorageFilter.pagination.limit;
            }
        }

        /* this loop inside is just to skip loading at the beginning */
        let response = JSON.parse(localStorage.getItem(this.localStorageResponse));
        const currentTime = Math.floor(Date.now() / 1000);
        if (localStorage.getItem(this.localStorageNameCalled) === JSON.stringify(localStorageFilter) && (response !== null) && ((currentTime - this.timestamp) < 10)) {
            localStorage.removeItem(this.localStorageNameCalled);
            this.reloadList(response.response, response.passedParams);
        } else {
            this.timestamp = Math.floor(Date.now() / 1000);
            localStorage.setItem(this.localStorageNameCalled, JSON.stringify(localStorageFilter));
            axios.post(env.api.public + 'publication/list', localStorageFilter, this.props.options.postHeaders).then((newResponse) => {
                if (newResponse.status === 200) {
                    if (newResponse.data.list.length === 0) {
                        Log('call publication/list 2');
                        axios.post(env.api.public + 'publication/list', localStorageFilter, this.props.options.postHeaders).then((newResponse2) => {
                            if (newResponse2.status === 200) {

                                localStorage.setItem(this.localStorageResponse, JSON.stringify({
                                    response: newResponse2,
                                    passedParams: passedParams
                                }));
                                this.reloadList(newResponse2, passedParams);
                            }
                        });
                    } else {
                        localStorage.setItem(this.localStorageResponse, JSON.stringify({
                            response: newResponse,
                            passedParams: passedParams
                        }));

                        this.reloadList(newResponse, passedParams);
                    }
                }
            }, (error) => {
                axiosErrorResponse(error);
            });
        }
    }

    reloadList = (response, passedParams) => {
        this.props.reloadBidderList(response.data);
        if (passedParams !== undefined) {
            this.setState({
                type: this.props.type,
                filter: response.data.filter,
                listParams: passedParams,
            });
        } else {
            this.setState({
                type: this.props.type,
                filter: response.data.filter,
            });
        }
    }

    uncheckFilter = () => {
        localStorage.removeItem(this.localStorageName);
        localStorage.removeItem(this.localStorageResponse)
        this.range = {from: null, to: null};
        setSearchParams(this.localStorageName, this.props.history);

        this.props.reloadPagination({}, true); // Set pagination and sort to default
        this.getFilter();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            if ((this.props.location.pathname === '/bidder/published/list') && (this.props.type === "bidder")) {
                if (this.props.scrollType === 'scroll') {
                    // if (prevProps.location.pathname.includes('/single')) { // Show list of all previous items
                        // this.props.setShowPrevProps(true)
                    // } else { // Show list of new items
                        this.resetOffset();
                    // }
                }
                this.remount();
            } else if ((this.props.location.pathname === '/') && (this.props.type === "public")) {
                if (this.props.scrollType === 'scroll') {
                    if (prevProps.location.pathname.includes('/single')) { // Show list of all previous items
                        this.props.setShowPrevProps(true)
                    } else { // Show list of new items
                        this.resetOffset();
                    }
                }
                this.remount();
            }
        } else if (JSON.stringify(prevProps.options) !== JSON.stringify(this.props.options)) {
            this.remount(true);
        } else if (this.props.type !== this.state.type) {
            this.remount(true);
        } else if (JSON.stringify(this.state.listParams) !== JSON.stringify(this.props.listParams)) {
            if (this.props.listParams !== null) {
                let localStorageFilter = JSON.parse(localStorage.getItem(this.localStorageName));
                if (this.props.listParams !== undefined && localStorageFilter !== null) {
                    if (this.props.listParams.pagination !== undefined) {
                        if (localStorageFilter.pagination === undefined) {
                            localStorageFilter.pagination = {};
                        }
                        localStorageFilter.pagination.offset = this.props.listParams.pagination.offset;
                        localStorageFilter.pagination.limit = this.props.listParams.pagination.limit;
                        localStorage.setItem(this.localStorageName, JSON.stringify(localStorageFilter));
                    }
                    if (this.props.listParams.sort !== undefined) {
                        if (localStorageFilter.sort === undefined) {
                            localStorageFilter.sort = {};
                        }
                        localStorageFilter.sort.by = this.props.listParams.sort.by;
                        localStorageFilter.sort.direction = this.props.listParams.sort.direction;
                        localStorage.setItem(this.localStorageName, JSON.stringify(localStorageFilter));
                    }
                }
                this.setState({listParams: this.props.listParams}, () => {
                    this.getFilter(this.props.listParams);
                })
            }
        }
    }

    // This function is called on refresh, url change, .. to reset offset in LS for infinite scroll
    resetOffset = () => {
        let localStorageFilter = JSON.parse(localStorage.getItem(this.localStorageName));
        if ((localStorageFilter !== null) && (localStorageFilter.pagination !== undefined) && (localStorageFilter.pagination !== null)) {
            localStorageFilter.pagination.offset = 0;
            localStorage.setItem(this.localStorageName, JSON.stringify(localStorageFilter));
        }

        let response = JSON.parse(localStorage.getItem(this.localStorageResponse));
        if ((response !== null) && (response.passedParams !== undefined) && (response.passedParams !== null)) {
            response.passedParams.pagination.offset = 0;
            localStorage.setItem(this.localStorageResponse, JSON.stringify(response));
        }

        this.props.reloadPagination({}, true); // Set pagination and sort to default
    }

    remount = (resetOffset = false) => {
        if (resetOffset && this.props.scrollType === 'scroll') this.resetOffset();
        if (this.props.type === "bidder") {
            if (this.props.location.pathname === '/bidder/published/list') {
                this.setState({type: this.props.type}, () => this.getFilter());
            } else {
                this.setState({type: this.props.type});
            }
        } else if (this.props.type === "public") {
            if (this.props.location.pathname === '/') {
                this.setState({type: this.props.type}, () => this.getFilter());
            } else {
                this.setState({type: this.props.type});
            }
        } else {
            this.setState({type: this.props.type, filter: null});
        }
    }

    componentDidMount() {
        localStorage.removeItem(this.localStorageNameCalled);
        if ((window.location.search !== undefined) && (window.location.search.length !== 0)) {
            getSearchParams(this.localStorageName);
        } else {
            setSearchParams(this.localStorageName, this.props.history);
        }
        this.remount(true);
    }

    render() {
        const {t} = this.props;

        let userTitle = '';
        if (this.props.options.parsedToken !== undefined) {
            if (this.props.options.parsedToken.vashId !== undefined) {
                userTitle = <div className="mt-3">
                    <Link to="/user/settings" className="text-dark font-weight-medium user_name">{this.props.options.parsedToken.name}</Link>
                    <p className="text-body mt-1 mb-0 user_title">{getUserRole(this.props.options.parsedToken.vashRoleId)}</p>
                    <p className={classes.user_location + " text-dark mt-1 mb-0"}>{this.props.options.parsedToken.vashCourtTitle}</p>
                </div>;
            } else {
                userTitle = <div className="mt-3">
                    <Link to="/user/settings" className="text-dark font-weight-medium font-size-16 user_name">{this.props.options.parsedToken.name + ' ' + this.props.options.parsedToken.surname}</Link>
                </div>;
            }
        }

        /* filter */
        let filterDisplay = null;
        if ((this.state.filter !== null) && ((this.props.history.location.pathname === "/") || (this.props.history.location.pathname === '/bidder/published/list'))) {
            const localStorageFilter = JSON.parse(localStorage.getItem(this.localStorageName));
            filterDisplay = this.state.filter.map((filter, index) => {
                if (this.filtersDisplay.indexOf(filter.id) !== -1) {
                    if (filter.id === "startingPrice") {
                        let selectedTo = 100;
                        let selectedToDB = 0;
                        let selectedFrom = 0;
                        let selectedFromDB = 0;
                        for(let i = 0; i < filter.options.length; i++) {
                            switch(filter.options[i].id) {
                                case "selectedTo":
                                    if (filter.options[i].title.length) {
                                        selectedTo = (parseFloat(filter.options[i].title)*100)/this.range.to;
                                        selectedToDB = parseFloat(filter.options[i].title);
                                    }
                                    break;
                                case "rangeTo":
                                    this.range.to = parseFloat(filter.options[i].title);
                                    break;
                                case "selectedFrom":
                                    if (filter.options[i].title.length) {
                                        selectedFrom = (parseFloat(filter.options[i].title)*100)/this.range.to;
                                        selectedFromDB = parseFloat(filter.options[i].title);
                                    }
                                    break;
                                case "rangeFrom":
                                    this.range.from = parseFloat(filter.options[i].title);
                                    break;
                                default: break;
                            }
                        }

                        if (selectedToDB === 0) {
                            selectedToDB = this.range.to;
                        }

                        return (
                            <li key={'filter_range'}>
                                <span className={classes.sideBarSpan + " has-arrow waves-effect"} id="headingRange" data-toggle="collapse" aria-expanded="false" data-target="#collapseRange" aria-controls="collapseRange">
                                    <i className={classes.sideBarIcon + " mdi mdi-account-circle-outline"}/>
                                    <span>{filter.title}</span>
                                </span>

                                <ul className={classes.subMenu + " collapse mt-2"} id="collapseRange" aria-labelledby="headingRange">
                                    <Range allowCross={false} step={10} style={{width: "87%"}} value={[selectedFrom, selectedTo]} onChange={this.rangeChange} onAfterChange={this.rangeAfterChange} />
                                    <div className="mt-2">{t('leftSide.startingPriceFrom')}:<br /> <b>{number_format(selectedFromDB, 2, ',', '.')} EUR</b></div>
                                    <div className="">{t('leftSide.startingPriceTo')}:<br /> <b>{number_format(selectedToDB, 2, ',', '.')} EUR</b></div>
                                </ul>
                            </li>
                        );
                    } else {
                        const keys = Object.keys(filter.options);
                        const options = keys.map(option => {
                            let checked = false;

                            if ((localStorageFilter !== null) && (localStorageFilter.filter[filter.id] !== undefined) && (localStorageFilter.filter[filter.id].indexOf(filter.options[option].id) !== -1)) {
                                checked = true;
                            }
                            return (
                                <li key={'option_'+filter.options[option].id}>
                                    <div className="custom-control custom-checkbox mb-2">
                                        <input type="checkbox" className="custom-control-input" checked={checked} id={filter.options[option].id} onChange={(e) => this.checkboxHandle(e, filter.id, filter.options[option].id)} />
                                        <label className="custom-control-label" htmlFor={filter.options[option].id}>{filter.options[option].title} (<b>{filter.options[option].count}</b>)</label>
                                    </div>
                                </li>
                            );
                        });

                        return (
                            <li key={'filter_'+index}>
                                <span className={classes.sideBarSpan + " has-arrow waves-effect"} id={"heading"+index} data-toggle="collapse" aria-expanded="false" data-target={"#collapse"+index} aria-controls={"collapse"+index}>
                                    <i className={classes.sideBarIcon + " mdi mdi-account-circle-outline"}/>
                                    <span>{filter.title}</span>
                                </span>
                                <ul className={classes.subMenu + " collapse mt-2"} id={"collapse"+index} aria-labelledby={"heading"+index}>
                                    {options}
                                </ul>
                            </li>);
                    }
                } else {
                    return null;
                }
            });
            /*if (filterDisplay !== null) {
                filterDisplay.push(<span key={'filter_reset'} onClick={this.uncheckFilter} className="btn btn-primary btn-sm" style={{width: "90%", marginLeft: "5%"}}>{t('leftSide.resetFilters')}</span>);
            }*/
        }
        /* end of filter */

        // Count filters in localStorage
        const filterNotification = JSON.parse(localStorage.getItem(this.localStorageName));
        let filtercount = 0;
        let keys = [];
        if(filterNotification !== null && filterNotification !== undefined) {
            keys = Object.keys(filterNotification.filter)
        }

        for(let i = 0; i < keys.length; i++) {
            if(keys[i] !== 'zip') {
                // eslint-disable-next-line
                filterNotification.filter[keys[i]].forEach(filter => {
                    filtercount = filtercount + 1
                })
            }
        }

        if (this.state.type !== null) {
            return (
                <div>
                    <div id="sidebar-filter" className={classes[this.state.type+'SideMenu'] + " vertical-menu"}>
                        <div className="h-100">
                            <div className="user-wid text-center py-4">
                                {userTitle}
                                {this.props.options.parsedToken !== undefined ? <UserTimeout sign={this.props.sign} /> : '' }
                            </div>
                            <div id="sidebar-menu">
                                {
                                    filterDisplay !== null ?
                                    <div>
                                        <p style={{ fontSize: '1.1em', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <span style={{ marginLeft: 30 }}>{t('leftSide.filterCounter')}: {filtercount}</span>
                                            <span style={{ marginRight: 20 }} onClick={this.uncheckFilter} className="btn btn-primary btn-sm">{t('leftSide.resetFilters')}</span>
                                        </p>
                                        <hr/>
                                    </div>
                                    :
                                    ""
                                }

                                <ul className="metismenu list-unstyled" id="side-menu">
                                    {filterDisplay}
                                </ul>
                                {
                                    filterDisplay !== null ?
                                        <div>
                                            <hr/>
                                            <p style={{ fontSize: '1.1em', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <span style={{ marginLeft: 30 }}>{t('leftSide.filterCounter')}: {filtercount}</span>
                                                <span style={{ marginRight: 20 }} onClick={this.uncheckFilter} className="btn btn-primary btn-sm">{t('leftSide.resetFilters')}</span>
                                            </p>
                                            <hr/>
                                        </div>
                                        :
                                        ""
                                }
                                <ul className="metismenu list-unstyled" id="side-menu">
                                    <Menu type={this.state.type} />
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div id="close-filter" style={{ position: 'fixed', top: '70px', right: 0, width: '0%', height: '100vh', zIndex: 999 }} />
                </div>
            );
        } else {
            return '';
        }
    }
}

const mapStateToProps = state => {
    return {
        options: state.options,
        scrollType: state.scrollType,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setShowPrevProps: (value) => dispatch({type: 'SETSHOWPREVPROPS', value: value}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Leftside)));
