import * as actionTypes from '../store/actions';
import {parseJwt} from "../izUtils";

const initialState = {
    dictionary: {},
    cookiesConsent: [],
    initializedGA: false,
    ws: null,
    wsConnected: false,
    wsPublication: [],
    lastWsMessage: null,
    wsTime: null,
    bidderSubmitIds: null,
    options: {
        postHeaders: {
            headers: {
                "Accept": 'application/json'
            }
        }
    },
    scrollType: null,
    canAppendItems: false,
    showPrevProps: false,
    loadingFiles: false,
};

const reducer = (state = initialState, action) => {
    const newState = Object.assign({}, state);

    switch(action.type) {
        case actionTypes.SETACCEPTLANUAGE:
            newState.options.postHeaders.headers['Accept-Language'] = action.value;
            return newState;
        case actionTypes.SETJWTTOKEN:
            if (action.value.length !== 0) {
                newState.options.postHeaders.headers['Authorization'] = action.value;
                newState.options.parsedToken = parseJwt(action.value);
            } else {
                delete newState.options.postHeaders.headers.Authorization;
                delete newState.options.parsedToken;
            }

            return newState;
        case actionTypes.SETDICTIONARY:
            newState.dictionary = action.value;

            return newState;

        case actionTypes.SETWSACTIVITY:
            newState.wsConnected = action.value;
            return newState;
        case actionTypes.SETCOOKIECONSENT:
            newState.cookiesConsent = action.value;

            return newState;
        case actionTypes.SETBIDDERIDS:
            newState.bidderSubmitIds = action.value;

            return newState;
        case actionTypes.INITIALIZEGA:
            newState.initializedGA = true;

            return newState;
        case actionTypes.SETTIME:
            newState.wsTime = action.value;

            return newState;
        case actionTypes.SETWSMSG:
            if (action.value.t === "publication") {
                newState.lastWsMessage = action.value;
                let newArray = [...newState.wsPublication];
                newArray.push(action.value);
                newState.wsPublication = newArray;
            } else if (action.value.t === "time") {
                newState.wsTime = action.value.unix;
            }
            return newState;
        case actionTypes.SETSCROLLTYPE:
            newState.scrollType = action.value;
            return newState;
        case actionTypes.SETAPPENDITEMS:
            newState.canAppendItems = action.value;
            return newState;
        case actionTypes.SETSHOWPREVPROPS:
            newState.showPrevProps = action.value;
            return newState;
        case actionTypes.SETLOADINGFILES:
            newState.loadingFiles = action.value
            return newState
        default: return state;
    }
};

export default reducer;
