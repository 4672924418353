import React, {Component, Fragment} from 'react';
import {isNull, fillNull, equalObjects} from "../../../../../izUtils";
import InputAddress from "../../../../../hoc/Form/Input/InputAddress";
import InputText from "../../../../../hoc/Form/Input/Text";
import axios from "axios";
import env from "../../../../../env/src_config";
import Debtor from "./CreateDisplayDebtor";
import {withTranslation} from 'react-i18next';

import Swal from 'sweetalert2';

class Debtors extends Component {
    state = null;
    inputs = null;

    componentDidMount() {
        this.clear();
    }

    recalculateCnt = () => {
        let debtorCnt = 0, creditorCnt = 0;

        const data = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));
        for (let i = 0; i < data.values.entities.length; i++) {
            if (data.values.entities[i] !== null) {
                if (data.values.entities[i].type === "debtor") {
                    debtorCnt += 1;
                } else {
                    creditorCnt += 1;
                }
            }
        }

        return [debtorCnt, creditorCnt];
    }

    clear = () => {
        const cnt = this.recalculateCnt();
        const initialState = {
            person: 0,
            type: 0,
            debtorCnt: cnt[0],
            creditorCnt: cnt[1],
            displayAddForm: false,
            representativeAddressMandatory: false
        }

        this.setState({ ...initialState });

        this.inputs = {
            mainFields: {
                company: null,
                registrationNumber: null,
                firstName: null,
                lastName: null,
                identityNumber: null,
                addressId: null,
                representative: null,
                representativeAddress: null
            }
        };

        this.editIndex = null;
    }

    mainSelectChangeHandler = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    inputFieldChangeHandler = (e) => {
        if (e.target.name === 'representative') {
            if ((e.target.value.length > 0) && (!this.state.representativeAddressMandatory)) {
                this.setState({representativeAddressMandatory: true});
            } else if ((this.state.representativeAddressMandatory) && (e.target.value.length === 0)) {
                this.setState({representativeAddressMandatory: false});
            }
        }

        this.inputs.mainFields[e.target.name] = e.target.value;
    }

    emptyAlert = () => {
        const { t } = this.props;
        Swal.fire(
            {
                title: t('debtors.swal.empty.title'),
                html: t('debtors.swal.empty.content'),
                icon: 'error',
                confirmButtonColor: '#1068ce',
                confirmButtonText: t('doUnderstand')
            }
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!equalObjects(prevProps.entities, this.props.entities)) {
            const cnt = this.recalculateCnt();

            this.setState({ debtorCnt: cnt[0], creditorCnt: cnt[1] });
        }
    }

    continueAddingPerson = (e) => {
        /* check if fields are not empty */
        if (isNull(this.state.person) || (this.state.person.length === 0)) {
            this.emptyAlert();
            return;
        }

        if (isNull(this.state.type) || (this.state.type.length === 0)) {
            this.emptyAlert();
            return;
        }

        if (this.state.person === "legal") {
            if (isNull(this.inputs.mainFields['company']) || (this.inputs.mainFields['company'].length === 0)) {
                this.emptyAlert();
                return;
            }
        }

        if (this.state.person === "natural") {
            if ((isNull(this.inputs.mainFields['firstName'])  || (this.inputs.mainFields['firstName'].length === 0)) ||
                (isNull(this.inputs.mainFields['lastName'])   || (this.inputs.mainFields['lastName'].length === 0))) {
                this.emptyAlert();
                return;
            }
        }

        let entitie;
        if (this.state.person === "natural") {
            entitie = {
                "type": this.state.type,
                "person": this.state.person,
                "firstName": this.inputs.mainFields['firstName'],
                "lastName": this.inputs.mainFields['lastName'],
                "addressId": this.inputs.mainFields['addressId'],
                "representative": this.inputs.mainFields['representative'],
                "representativeAddress": this.inputs.mainFields['representativeAddress'],
            }
        }

        if (this.state.person === "legal") {
            entitie = {
                "type": this.state.type,
                "person": this.state.person,
                "company": this.inputs.mainFields['company'],
                "addressId": this.inputs.mainFields['addressId'],
                "representative": this.inputs.mainFields['representative'],
                "representativeAddress": this.inputs.mainFields['representativeAddress'],
            }
        }

        let data = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));
        if (isNull(this.editIndex)) { //add person
            data.values.entities.push(entitie);
        } else { //edit person
            data.values.entities[this.editIndex] = entitie;
        }

        localStorage.setItem('SODNEDRAZBE.JSData', JSON.stringify(data));

        const {t} = this.props;
        Swal.fire(
            {
                title: t('debtors.swal.success.title'),
                html: (this.editIndex === null) ? t('debtors.swal.success.added') : t('debtors.swal.success.saved'),
                icon: 'success',
                confirmButtonColor: '#1068ce',
                confirmButtonText: t('doUnderstand')
            }
        );

        this.props.reloadState();
        this.clear();
    }

    addPerson = (e) => {
        if (this.inputs.mainFields['addressId'] !== null && this.inputs.mainFields['addressId'].length === 0) {
            setTimeout(() => this.continueAddingPerson(e), 1000)
        } else {
            this.continueAddingPerson(e)
        }
    }

    debtorAddressChangeRootHandler = (currentAddress, field) => {
        let go = 1;
        Object.keys(currentAddress).forEach((value) => {
            if (['latitude', 'longitude'].indexOf(value) === -1) {
                if (currentAddress[value].length === 0) {
                    if (value !== 'region') {
                        go = 0;
                    }
                }
            }
        });

        const inputs = this.inputs;
        inputs.mainFields[field] = '';

        if (go) {
            let postData = {values: currentAddress};
            delete postData.values.latitude;
            delete postData.values.longitude;
            axios.post(env.api.private + 'address', postData, this.props.headers).then((response) => {
                if (response.status === 200) {
                    if (response.data.valid) {
                        /* if valid store address */
                        postData['save'] = true;
                        axios.post(env.api.private + 'address', postData, this.props.headers).then((response) => {
                            if (response.status === 200) {
                                inputs.mainFields[field] = response.data.record.id;
                            }
                        }, (error) => {
                            console.log(error);
                        });
                    }
                }
            }, (error) => {
                console.log(error);
            });
        }
    }

    editPerson = (index) => {
        const data = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));
        const entitie = data.values.entities[index];

        this.editIndex = index;

        let initialState = {
            person: entitie.person,
            type: entitie.type,
            displayAddForm: true,
        }

        if (entitie.person === 'natural') {
            this.inputs = {
                mainFields: {
                    firstName: entitie.firstName,
                    lastName: entitie.lastName,
                    addressId: entitie.addressId,
                    representative: entitie.representative,
                    representativeAddress: entitie.representativeAddress
                }
            };
        } else {
            this.inputs = {
                mainFields: {
                    company: entitie.company,
                    addressId: entitie.addressId,
                    representative: entitie.representative,
                    representativeAddress: entitie.representativeAddress
                }
            };
        }
        this.setState({ ...initialState });
    }

    cancelEdit = () => {
        this.clear();
    }

    deletePerson = (index) => {
        const {t} = this.props;
        Swal.fire({
            title: t('debtors.swal.delete.title'),
            html: t('debtors.swal.delete.content'),
            icon:"question",
            showCancelButton: true,
            confirmButtonColor: '#1068ce',
            cancelButtonColor: '#cda73a',
            cancelButtonText: t('no'),
            confirmButtonText: t('yes')
        }).then((result) => {
            if (result.value) {
                let data = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));
                let entities = [];
                for (let i = 0; i < data.values.entities.length; i++) {
                    if (i !== index) {
                        entities.push(data.values.entities[i]);
                    } else {
                        if (data.values.entities[i].type === "creditor") {
                            this.setState({creditorCnt: (this.state.creditorCnt - 1)});
                        } else {
                            this.setState({debtorCnt: (this.state.debtorCnt - 1)});
                        }
                    }
                }
                data.values.entities = entities;

                localStorage.setItem('SODNEDRAZBE.JSData', JSON.stringify(data));
                this.props.reloadState();
            }
        });
    }

    displayAdd = (type, creditor) => {
        const initialState = {
            person: creditor,
            type: type,
            displayAddForm: true
        }
        this.setState({ ...initialState });
    }

    render() {
        const {t} = this.props;
        if (this.state !== null) {
            const legal = (
                <Fragment>
                    <InputText label={t('debtors.input.company')} error={{ code: "FEmandatory", title: t('mandatory') }} name="company" mandatory={true} change={this.inputFieldChangeHandler} value={fillNull(this.inputs.mainFields['company'], '')} />
                    <InputAddress key="addressId" name="addressId" label={t('debtors.adress')} display={true} addresses={this.props.addresses} value={this.inputs.mainFields.addressId} mainCol={12} change={this.debtorAddressChangeRootHandler} />
                </Fragment>);

            const natural = (
                <Fragment>
                    <InputText label={t('debtors.name')} error={{ code: "FEmandatory", title: t('mandatory') }} name="firstName" mandatory={true} change={this.inputFieldChangeHandler} value={fillNull(this.inputs.mainFields['firstName'], '')} />
                    <InputText label={t('debtors.surname')} error={{ code: "FEmandatory", title: t('mandatory') }} name="lastName" mandatory={true} change={this.inputFieldChangeHandler} value={fillNull(this.inputs.mainFields['lastName'], '')} />

                    <InputAddress key="addressId" name="addressId" label={t('debtors.adress')} display={true} addresses={this.props.addresses} value={this.inputs.mainFields.addressId} mainCol={12} change={this.debtorAddressChangeRootHandler} />
                </Fragment>
            );

            let list = [{type: 'debtor', mainTitle: t('debtors.headers.debtors'), legalTitle: t('debtors.headers.debtorCompany'), naturalTitle: t('debtors.headers.debtorPerson')}];


            if (this.props.token.vashRoleId !== 304) {
                list.push({type: 'creditor', mainTitle: t('debtors.headers.creditors'), legalTitle: t('debtors.headers.creditorCompany'), naturalTitle: t('debtors.headers.creditorPerson') });
            }

            const tables = list.map(user => {
                let rowClass = ["card-body"];
                if (this.state[user.type+'Cnt'] === 0) {
                    rowClass.push("mandatory");
                }

                return (
                    <div className="col-md-12" key={user.type}>
                        <h5 className="cardTitle">{user.mainTitle}*</h5>
                        <div className="card">
                            <div className={rowClass.join(" ")}>
                                <h4 className="card-title"><button onClick={() => this.displayAdd(user.type, 'legal')} className="btn btn-primary">{t('add')}</button> {user.legalTitle}</h4>
                                <div className="table-responsive mb-5">
                                    <table className="table mb-0">
                                        <thead className="thead-light">
                                        <tr>
                                            <th>{t('debtors.action')}</th>
                                            <th>{t('debtors.title')}</th>
                                            <th>{t('debtors.seat')}</th>
                                        </tr>
                                        </thead>
                                        <tbody><Debtor key={user.type + "_legal"} type={user.type} clickEdit={this.editPerson} clickDelete={this.deletePerson} person="legal" entities={this.props.entities} adresses={this.props.addresses} /></tbody>
                                    </table>
                                </div>
                                <h4 className="card-title"><button onClick={() => this.displayAdd(user.type, 'natural')} className="btn btn-primary">{t('add')}</button> {user.naturalTitle}</h4>
                                <div className="table-responsive">
                                    <table className="table mb-0">
                                        <thead className="thead-light">
                                        <tr>
                                            <th>{t('debtors.action')}</th>
                                            <th>{t('debtors.surname')}</th>
                                            <th>{t('debtors.name')}</th>
                                            <th>{t('debtors.adress')}</th>
                                        </tr>
                                        </thead>
                                        <tbody><Debtor key={user.type + "_natural"} clickEdit={this.editPerson} clickDelete={this.deletePerson} type={user.type} person="natural" entities={this.props.entities} adresses={this.props.addresses} /></tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            });

            return (
                <div className="tab-pane" id="debtors" role="tabpanel">
                    <div className="row">
                        {this.state.displayAddForm ?
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4>{this.state.type === "creditor" ? t('debtors.form.creditor') : t('debtors.form.debtor')} - {this.state.person === "legal" ? t('debtors.form.company') : t('debtors.form.person')}</h4>
                                        {this.state.person === 'legal' ? legal : this.state.person === 'natural' ? natural : ''}
                                        <button onClick={this.addPerson} className="btn btn-primary">{t('debtors.button.save')}</button>
                                        <button onClick={this.cancelEdit} className="btn btn-primary ml-3">{t('debtors.button.cancel')}</button>
                                    </div>
                                </div>
                            </div>
                            :
                            tables
                        }
                    </div>
                </div>
            );
        } else {
            return ('');
        }
    }
}

export default withTranslation()(Debtors);
