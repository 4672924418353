import React from 'react';
import {withTranslation} from'react-i18next'

const InputBoolean = (props) => {
    const {t} = props;
    if (props.display || (props.display === undefined)) {
        let cols = props.cols;
        if (cols !== undefined) {
            cols = cols.split('/');
            cols = ['col-' + cols[0], 'col-' + cols[1]];
        } else {
            cols = ['col-md-2', 'col-md-10'];
        }

        let checked_true = false;
        let checked_false = false;
        if (props.checked !== undefined) {
            if (props.checked === "true") {
                checked_true = true;
            } else {
                checked_false = true;
            }
        }

        let labelAppendix = '';
        if (props.mandatory){
            labelAppendix = '*';
        }

        const name = props.name+(props.index !== undefined ? '_' + props.index : '');

        return (
            <div className="form-group row">
                <label htmlFor={props.name} className={cols[0] + " col-form-label" + (props.labelRight !== undefined ? " text-right" : "")}>{props.label + labelAppendix}</label>
                <div className={cols[1]}>
                    <div className="custom-control custom-radio mb-2">
                        <input type="radio" id={name + "_boolean_true"} name={name} className="custom-control-input" value="true" checked={checked_true} onChange={props.change} />
                            <label className="custom-control-label" htmlFor={name + "_boolean_true"}>{t('yes')}</label>
                    </div>
                    <div className="custom-control custom-radio">
                        <input type="radio" id={name + "_boolean_false"} name={name} className="custom-control-input" value="false" checked={checked_false} onChange={props.change} />
                            <label className="custom-control-label" htmlFor={name + "_boolean_false"}>{t('no')}</label>
                    </div>
                </div>
            </div>
        );
    } else {
        return ('');
    }
};

export default withTranslation()(InputBoolean);
