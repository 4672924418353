import React, { Component } from 'react';
import FileUpload from "../../../../../hoc/Form/FileUpload/FileUpload";

class File extends Component {

    render() {
        return (
            <div className="tab-pane" id="file" role="tabpanel">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <FileUpload
                                    showLeavePrompt={this.props.showLeavePrompt}
                                    isMandatory={true}
                                    headers={this.props.headers} cols="1/12"
                                    files={this.props.files}
                                    filter={["pdf"]}
                                    storedFiles={this.props.storedFiles}
                                    selectChangeFilesHandler={this.props.selectChangeFilesHandler}
                                    textChangeFilesHandler={this.props.textChangeFilesHandler}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default File;
