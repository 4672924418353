export const singleColumn = (fields) => {
    for (let i = 0; i < fields.length; i++) {
        if (fields[i] !== null) {
            return true;
        }
    }

    return false;
}

export const twoColumns = (fields, fields1) => {

    for (let i = 0; i < fields.length; i++) {
        if (fields[i] !== null) {
            return true;
        }
    }

    for (let i = 0; i < fields1.length; i++) {
        if (fields1[i] !== null) {
            return true;
        }
    }

    return false;
}
