import React, { Component } from 'react';
import * as moment from 'moment';

import classes from './CurrentTime.module.css';
import axios from "axios";
import env from "../../../env/src_config";
import axiosErrorResponse from "../../AxiosErrorResponse/AxiosErrorResponse";
import {connect} from "react-redux";

class CurrentTime extends Component {
   state = {
       currentDate: null
   }

    unix = null;

    int = null;
    intUnix = null;

    updateClock() {
        this.int = setInterval(() => {
            if (this.unix !== null) {
                this.unix += 1;
                this.setState({
                    currentDate: moment.unix(this.unix).format('DD. MM. YYYY HH:mm:ss')
                })
            }
        }, 1000);
    }

    updateUnix() {
        this.intUnix = setInterval(() => {
            this.getUnix(false);
        }, 30000);
    }

    componentWillUnmount() {
        clearInterval(this.int);
        clearInterval(this.intUnix);
    }

    getUnix(setUpdateClock) {
        axios.post(env.api.api + '/time', {}, this.props.options.postHeaders).then(res => {
            if (res.status === 200) {
                this.unix = res.data.unix;
                if (setUpdateClock) {
                    this.updateClock();
                }
            }
        }, (error) => {
            axiosErrorResponse(error);
        });
    }


    componentDidMount() {
        this.getUnix(true);
    }

    render() {
        return (
            <div className={"d-none d-md-inline-block "+classes.headerDatetime}>
                {this.state.currentDate}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        options: state.options
    }
}

export default connect(mapStateToProps)(CurrentTime);
