import $ from "jquery";
import classes from "./Subscriber/Drafts/List/List.module.css";

import moment from "moment";

/* datatables */
import 'react-pdfmake';
import 'datatables.net-responsive/js/dataTables.responsive';
import './assets/css/datatables/dataTables.bootstrap4.min.css';
import './assets/css/datatables/datatables-buttons-fix.css';
import './assets/css/datatables/responsive.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
import JSZip from 'jszip';


require('datatables.net-bs4'); //https://www.npmjs.com/package/datatables.net-bs4
require('datatables.net-buttons')();
require('datatables.net-buttons/js/buttons.html5.js')();

window.JSZip = JSZip;

/* end_of datatables */

export const isNumeric = (e, type, otherFields) => {
    /* allow only one comma */
    if ((e.which === 188) || (e.which === 110)) {
        for (let i = 0; i < e.target.value.length;i++) {
            if (e.target.value[i] === ',') {
                e.preventDefault();
            }
        }
    }

    if (otherFields !== undefined) {
        if (otherFields.indexOf(e.key) !== -1) {
            return true;
        }
    }

    if (e.shiftKey) {
        e.preventDefault();
    }

    if ((((e.which >= 48) && (e.which <= 57)) ||
        (e.which === 8) || /* backspace */
        (e.which === 9) || /* tab */
        ((e.which >= 37) && (e.which <= 40)) || /* arrows */
        ((e.which >= 96) && (e.which <= 105)) /* num pad */
    )
    ) {
    } else {
        if (type === "float") {
            if (((e.which === 190) || (e.which === 188)) || /* dot and comma */
                (e.which === 110) /* num pad comma */) {
            } else {
                e.preventDefault();
            }
        } else {
            e.preventDefault();
        }
    }
}

export const enableOnlineAuction = (fields) => {
    if (fields.saleFormRelation.valueCode === "02" && fields.saleMethodRelation.valueCode === "01" && ["waiting_start", "pending", "active", "buyer_right"].indexOf(fields.status) !== -1) {
        return true;
    }
    return false;
}

export const eurFields = () => {
    return ['debtAmount', 'estimatedPrice', 'startingPrice', 'securityPrice', 'stepPrice', 'oldEstimatedPrice', 'oldStartingPrice', 'estSubjectPrice', 'lowestPrice', 'achivedSalePrice'];
}

export const isBidder = (options) => {
    return (options !== undefined) && (options.parsedToken !== undefined) && (options.parsedToken.bidderId !== undefined);
}

export const b64DecodeUnicode = (str) => {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}

export const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export const compareDates = (first, second) => {
    const f = new Date(first);
    const s = new Date(second);

    if (f.getTime() > s.getTime()) {
        return true;
    }

    return false;
};

export const hhmmss = (secs) => {
    let minutes = Math.floor(secs / 60);
    secs = secs%60;
    let hours = Math.floor(minutes/60)
    minutes = minutes%60;
    return `${hours}:${pad(minutes)}:${pad(secs)}`;
}

function pad (num) {
    return ("0"+num).slice(-2);
}

export const findGetParameter = (parameterName) => {
    let result = null,
        tmp = [];
    let items = window.location.search.substr(1).split("&");
    for (var index = 0; index < items.length; index++) {
        tmp = items[index].split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    }
    return result;
}

export const getUserRole = (vashRoleId) => {
    let returnVar;
    switch (vashRoleId) {
        case 484:
            returnVar = 'eDražbe - urednik';
            break;
        case 485:
            returnVar = 'eDražbe - interna javnost';
            break;
        case 303 || 482:
            returnVar = 'izvršitelji';
            break;
        case 304:
            returnVar = 'upravitelji';
            break;
        default:
            returnVar = 'Neznan status';
            break;
    }

    return returnVar;
}

export const time_reformat = (dbtime, seconds = false) => {
    const d = new Date(dbtime);

    let t = [d.getDate(), (d.getMonth() + 1), d.getHours(), d.getMinutes(), d.getSeconds()];

    for (let i = 0; i < t.length; i++) {
        if (t[i] < 10) {
            t[i] = '0' + t[i];
        }
    }

    return [t[0] + '.' + t[1] + '.' + d.getFullYear(), t[2] + ':' + t[3] +(seconds ? ':'+t[4] : '')];
};

export const time_reformat_toMoment = (dbtime) => {
    return moment(new Date(dbtime));
}

export const date_reformat = (input) => {
    const split = input.split('.');

    if (split.length === 3) {
        return split[2] + '-' + split[1] + '-' + split[0];
    } else {
        return '';
    }
};

export const date_format = (input) => {
    if ((input !== undefined) && (input.length)) {
        const split = input.split('-');

        if (split.length === 3) {
            return split[0] + '.' + split[1] + '-' + split[2];
        } else {
            return '';
        }

    } else {
        return '';
    }
}
export const time_reformat_toISO = (input) => {
    let tmp = input.split(' ');
    if (tmp.length === 2) {
        const d = tmp[0].split('.');
        if (d.length === 3) {
            const t = tmp[1].split(':');
            if (t.length === 2) {
                return (new Date(d[2], d[1] - 1, d[0], t[0], t[1], 0, 0)).toISOString();
            }
        }
    }

    return '';
};
export const time_reformat_toISO_with_seconds = (input) => {
    let tmp = input.split(' ');
    if (tmp.length === 2) {
        const d = tmp[0].split('.');
        if (d.length === 3) {
            const t = tmp[1].split(':');
            if (t.length === 3) {
                return (new Date(d[2], d[1] - 1, d[0], t[0], t[1], t[2], 0)).toISOString();
            }
        }
    }

    return '';
};

export const number_format = (number, decimals, dec_point, thousands_sep) => {
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    let n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        toFixedFix = function (n, prec) {
            let k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    let s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

export const isNull = (variable) => {
    return ((variable === null) || (variable === 'null'));
}

export const create_datatable = (t, id, bid = null, columnDefs, order = [ 1, "desc" ], pageLength = 25) => {
    let columns = [];

    let action_buttons = -1;
    if ((columnDefs !== undefined) && (columnDefs.targets !== undefined)) {
        action_buttons = columnDefs.targets;
    }

    for (let i = 1; i < $("#" + id + " tr th").length; i++) {
        if (bid) columns.push(i);
        else if (!bid && i !== action_buttons) { //action_button
            columns.push(i);
        }
    }

    let dataTables = [{
        "targets": 0,
        "orderable": false
    }];
    if (columnDefs !== undefined) {
        dataTables.push(columnDefs);
    }

    return $("#" + id).DataTable({
        "lengthMenu": [[10, 25, 50, 100], [10, 25, 50, 100]],
        "pageLength" : pageLength,
        "responsive": true,
        "language": t('dataTableLanguageSI', {returnObjects: true}),
        "dom": 'lfrtipB',
        "columnDefs": dataTables,
        "order": [order],
        "buttons":
            [
                {
                    "extend": 'pdf',
                    "text": '<i class="bx bx-printer"></i>' + t('printPDF'),
                    "className": classes.Icons,
                    "orientation": 'landscape',
                    "pageSize": 'LEGAL',
                    "exportOptions": {
                        columns: columns
                    }
                },
                {
                    "extend": 'excel',
                    "text": '<i class="bx bx-export"></i>' + t('exportXLSX'),
                    "className": classes.Icons,
                    "exportOptions": {
                        columns: columns,
                        format: {
                            body: function ( data, row, column, node ) {
                                return data.replace(/\./g, '').replace(',', '.');
                            }
                        }
                    }
                }
            ]
        }
    );
}


/* specific for this project */
export const getAddress = (adresses, id) => {
    let address = '';

    if (!((adresses === null) || (adresses === 'null'))) {
        for (let i = 0; i < adresses.length; i++) {
            if (adresses[i].id === id) {
                address = adresses[i].street + ' ' + adresses[i].houseNumber + ', ' + adresses[i].zip + ' ' + adresses[i].city + ', ' + adresses[i].country;
                break;
            }
        }
    }

    return address;
}

export const fillNull = (text, char) => {
    if ((text === null) || (text === 'null')) {
        if (char === undefined) {
            return '/';
        } else {
            return char;
        }
    } else {
        return text;
    }
}
export const getAddressField = (addresses, id, field) => {
    for (let i = 0; i < addresses.length; i++) {
        if (addresses[i].id === id) {
            return addresses[i][field];
        }
    }

    return '/';
}

export const equalObjects = (object1, object2) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        // eslint-disable-next-line
        if ((areObjects) && (!equalObjects(val1, val2)) || (!areObjects) && (val1 !== val2)) {
            return false;
        }
    }

    return true;
}

function isObject (object){
    return object != null && typeof object === 'object';
}

export const getSearchParams = (localStorageName) => {
    if ((window.location.search !== undefined) && (window.location.search.length !== 0)) {
        let localStorageFilter = {
            "filter": {},
            "pagination": {offset: 0, limit: 25},
            "sort": {by: "publicationAt", direction: "desc"}
        };

        let items = decodeURI(window.location.search).substring(1).split("&");
        let param = [];

        items.forEach(item => {
            param = item.split("=");

            if (localStorageFilter.filter[param[0]] !== undefined) {
                localStorageFilter.filter[param[0]].push(param[1].split(','));
            } else {
                localStorageFilter.filter[param[0]] = param[1].split(',');
            }
        })

        localStorage.setItem(localStorageName, JSON.stringify(localStorageFilter));
    }
} 

export const setSearchParams = (localStorageName, history) => {
    const LS = JSON.parse(localStorage.getItem(localStorageName));
    let search = '';
    if (LS !== null && LS.filter !== undefined) {
        const keys = Object.keys(LS.filter);
        search = '?';
        keys.forEach((key, index) => {
            let value = LS.filter[key].map(val => {
                let replacedVal = val.replace(",",".");
                return replacedVal;
            })

            search += (index !== 0 ? '&' : '') + key + '=' + value;
        });
    }

    history.replace({
        pathname: window.location.pathname,
        search: search
    });
}