import React, {Component} from "react";
import classes from './Breadcrumbs.module.css'
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";

class Breadcrumb extends Component {

    state = {
        grayscale: false,
        darkmode: false,
        fontSize: "0.875rem",
        mounted: false
    }

    defaultSize = "0.875rem";

    localStorageName = 'SODNEDRAZBE.disabled';

    componentDidMount() {
        let localStorageSettings = localStorage.getItem(this.localStorageName);
        if (localStorageSettings !== null) {
            localStorageSettings = JSON.parse(localStorageSettings);

            const state = {
                grayscale: localStorageSettings.grayscale,
                darkmode: localStorageSettings.darkmode,
                fontSize: localStorageSettings.fontSize,
                mounted: true
            }
            if (localStorageSettings.grayscale) {
                document.getElementsByTagName("body")[0].classList.add("grayscale");
            }
            if (localStorageSettings.darkmode) {
                document.getElementsByTagName("body")[0].classList.add("darkMode");
            }

            if (localStorageSettings.fontSize !== this.defaultSize) {
                document.getElementsByTagName("body")[0].style.fontSize = localStorageSettings.fontSize;
            }
            this.setState(state);
        } else {
            localStorageSettings = {
                grayscale: false,
                fontSize: this.defaultSize,
                darkmode: false
            }
            localStorage.setItem(this.localStorageName, JSON.stringify(localStorageSettings));
        }
    }

    greyScale = (set) => {
        if (!set) {
            document.getElementsByTagName("body")[0].classList.remove("grayscale");
        } else {
            document.getElementsByTagName("body")[0].classList.add("grayscale");
        }

        this.changeStorage('grayscale', !this.state.grayscale);
        this.setState({grayscale: !this.state.grayscale})
    }

    darkMode = (set) => {
        if (!set) {
            document.getElementsByTagName("body")[0].classList.remove("darkMode");
        } else {
            document.getElementsByTagName("body")[0].classList.add("darkMode");
        }

        this.changeStorage('darkmode', !this.state.darkmode);
        this.setState({darkmode: !this.state.darkmode})
    }

    changeStorage = (setting, value) => {
        let localStorageSettings = JSON.parse(localStorage.getItem(this.localStorageName));
        localStorageSettings[setting] = value;
        localStorage.setItem(this.localStorageName, JSON.stringify(localStorageSettings));
    }

    fontScale = (type) => {
        let currentSize = document.getElementsByTagName("body")[0].style.fontSize;
        if (type === 'reset') {
            this.changeStorage('fontSize', this.defaultSize);
            document.getElementsByTagName("body")[0].style.fontSize = this.defaultSize;
        } else {
            if (currentSize.length === 0) {
                currentSize = this.defaultSize;
            }
            currentSize = currentSize.replace('rem', '');
            currentSize = parseFloat(currentSize);
            if (type === 'up') {
                currentSize += 0.1;
            } else {
                currentSize -= 0.1;
            }
            this.changeStorage('fontSize', currentSize+"rem");
            document.getElementsByTagName("body")[0].style.fontSize = currentSize+"rem";
        }
    }
    render() {
        const {t} = this.props;
        return (
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        {/* eslint-disable-next-line */}
                        <h4 className="page-title mb-0 font-size-18"></h4>
                        <div className="page-title-right">
                            <button onClick={() => this.fontScale('up')} className={classes.bigA} title={t('disabled.fontInc')}>A+</button>
                            <button onClick={() => this.fontScale('reset')} className={classes.resetA} title={t('disabled.fontReset')}>A</button>
                            <button onClick={() => this.fontScale('down')} className={classes.smallA} title={t('disabled.fontDec')}>A-</button>
                            {
                                this.state.mounted ?
                                    <div className={classes.disabled + " dropdown d-inline-block"}>
                                        <button type="button" className="btn header-item"
                                                id="page-header-user-dropdown" data-toggle="dropdown" aria-haspopup="true"
                                                aria-expanded="false">
                                            <span className="d-inline-block" />
                                            <i className="mdi mdi-wheelchair-accessibility d-inline-block" title={t('disabled.adjustView')} />
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-right">
                                            {this.state.grayscale ?
                                                <span className="dropdown-item d-block cursor" onClick={() => this.greyScale(false)}>
                                                    <i className="mdi mdi-image-filter-black-white mr-1" /> {t('disabled.color')}
                                                </span>
                                                :
                                                <span className="dropdown-item d-block cursor" onClick={() => this.greyScale(true)}>
                                                    <i className="mdi mdi-image-filter-black-white mr-1" /> {t('disabled.grayscale')}
                                                </span>
                                            }

                                            {this.state.darkmode ?
                                                <span className="dropdown-item d-block cursor" onClick={() => this.darkMode(false)}>
                                                    <i className="mdi mdi-image-filter-black-white mr-1" /> {t('disabled.whiteMode')}
                                                </span>
                                                :
                                                <span className="dropdown-item d-block cursor" onClick={() => this.darkMode(true)}>
                                                    <i className="mdi mdi-image-filter-black-white mr-1" /> {t('disabled.darkMode')}
                                                </span>
                                            }
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

};
export default withRouter(withTranslation()(Breadcrumb));
