import React from 'react';
import './Tab.css';

const Tab = React.forwardRef((props, ref) => {
    let error = '';
    if (!((props.errors === undefined) || (props.errors === 0))) {
        error = <span className="errorCircle">{props.errors}</span>;
    }
    return (
        <li className="nav-item">
            <a className={props.class + " nav-link"} data-toggle="tab" href={props.tablink} ref={ref} role="tab">
                <span className="d-none d-sm-block">{props.title} {error}</span>
            </a>
        </li>
    );
})


export default Tab;
