import React, { Component, Fragment } from 'react';
import {linker} from "../../Helpers/Linker";


class SaleLocation extends Component {

    mapLinker = (input, mainLinkerFields) => {
        const inputlinkerFields = {
            input: input,
            textHandler: (e) => this.props.textChangeRootHandler(e, input)
        }

        return linker({...mainLinkerFields, ...inputlinkerFields});
    }

    render() {
        const mainLinkerFields = {
            data: this.props.data,
            addressChangeRootHandler: this.props.addressChangeRootHandler,
            cols: "4/8",
            addresses: this.props.addresses,
            booleanHandler: undefined
        }

        const main = ['saleTitle', 'saleAddress', 'salePlace'].map(input => {
            return this.mapLinker(input, mainLinkerFields);
        });
        return (
            <Fragment>
                <div className="tab-pane" id="saleLocation" role="tabpanel">
                    {main}
                </div>
            </Fragment>
        );
    }
}

export default SaleLocation;
