import React, { Component } from 'react';
import {linker} from "../../Helpers/Linker";

class Info extends Component {

    render() {
        const fieldsLeft = ['procedureType', 'procedureSubtype', 'caseNumber', 'caseYear'].map(input => {
            return linker(input, this.props.data, this.props.selectChangeRootHandler, this.props.textChangeRootHandler, this.props.datetimeChangeRootHandler, "4/8");
        });

        const fieldsRight = ['court', 'registerType', 'judge', 'executor', 'bankruptcyTrustee'].map(input => {
            return linker(input, this.props.data, this.props.selectChangeRootHandler, this.props.textChangeRootHandler, null, "4/8");
        });

        return (
            <div className="tab-pane active" id="info" role="tabpanel">
                <div className="row">
                    <div className="col-lg-6">
                        {fieldsLeft}
                    </div>
                    <div className="col-lg-6">
                        {fieldsRight}
                    </div>
                </div>
            </div>
        );
    }
}

export default Info;
