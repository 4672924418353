import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import classes from './Dashboard.module.css';
import Breadcrumb from "../../hoc/Header/Breadcrumbs/Breadcrumb";
import ReactGA from 'react-ga4';
import {connect} from 'react-redux';
import axios from "axios";
import env from "../../env/src_config";

class Dashboard extends Component {

    state = {
        cards: [
            { url: "/subscriber/drafts/create2/", title: '', icon: "mdi mdi-pencil-plus-outline", number: 0, up: '0,29%', progress: 0, level:0, color: 'bg-primary' },
            { url: "/subscriber/drafts/my-list", title: '', icon: "mdi mdi-file-document-box-plus-outline", number: 0, up: '0,29%', progress: 0, level:0, color: 'bg-success' },
            { url: "/subscriber/drafts/all-list", title: '', icon: "mdi mdi-file-document-box-plus", number: 0, up: '0,29%', progress: 0, level:0, color: 'bg-info' },
            { url: "/subscriber/published/my-list", title: '', icon: "mdi mdi-file-document-box-search-outline", number: 0, up: '0,29%', progress: 0, level:1, color: 'bg-warning' },
            { url: "/subscriber/published/all-list", title: '', icon: "mdi mdi-file-document-box-search", number: 0, up: '0,29%', progress: 0, level:1, color: 'bg-secondary' },
            { url: "/subscriber/finished/my-list", title: '', icon: "mdi mdi-file-key-outline", number: 0, up: '0,29%', progress: 0, level:2, color: 'bg-secondary' },
            { url: "/subscriber/published/bidders/my-list", title: '', icon: "mdi mdi-eye-plus-outline", number: 0, up: '0,29%', progress: 0, level:2, color: 'bg-secondary' }
        ]
    }

    clickCard = (url) => {
        this.props.history.push({ pathname: url });
    }

    setCard = (cards, url, param, value) => {
        for(let i = 0; i < cards.length; i++) {
            if (cards[i].url === url) {
                cards[i][param] = value;
                return cards;
            }
        }
    }

    componentDidMount() {
        this.reactGa();
        const {t} = this.props;
        let stateCards = [...this.state.cards];
        stateCards = this.setCard(stateCards, "/subscriber/drafts/create2/", "title", t('menu.draft.link.new'));
        stateCards = this.setCard(stateCards, "/subscriber/drafts/my-list", "title", t('menu.draft.link.my_draft'));
        stateCards = this.setCard(stateCards, "/subscriber/drafts/all-list", "title", t('menu.draft.link.all_draft'));
        stateCards = this.setCard(stateCards, "/subscriber/published/my-list", "title", t('menu.published.link.my_published'));
        stateCards = this.setCard(stateCards, "/subscriber/published/all-list", "title", t('menu.published.link.all_published'));
        stateCards = this.setCard(stateCards, "/subscriber/finished/my-list", "title", t('dashboard.cards.noData'));
        stateCards = this.setCard(stateCards, "/subscriber/published/bidders/my-list", "title", t('dashboard.cards.bidders'));

        axios.post(env.api.private + 'publication/count-status', {}, this.props.options.postHeaders).then((response) => {
            if (response.status === 200) {
                /* draft card */
                let draftListMy = 0;
                if (response.data.count.user !== undefined) {
                    if (response.data.count.user.draft_invalid !== undefined) {
                        draftListMy += response.data.count.user.draft_invalid;
                    }
                    if (response.data.count.user.draft_valid !== undefined) {
                        draftListMy += response.data.count.user.draft_valid;
                    }
                }

                let draftListCourt = 0;
                if (response.data.count.court !== undefined) {
                    if (response.data.count.court.draft_invalid !== undefined) {
                        draftListCourt += response.data.count.court.draft_invalid;
                    }
                    if (response.data.count.court.draft_valid !== undefined) {
                        draftListCourt += response.data.count.court.draft_valid;
                    }
                }

                stateCards = this.setCard(stateCards, "/subscriber/drafts/my-list", "number", draftListMy);
                stateCards = this.setCard(stateCards, "/subscriber/drafts/all-list", "number", draftListCourt);

                stateCards = this.setCard(stateCards, "/subscriber/drafts/my-list", "progress", (draftListMy*100)/(draftListMy+draftListCourt));
                stateCards = this.setCard(stateCards, "/subscriber/drafts/all-list", "progress", (draftListCourt*100)/(draftListMy+draftListCourt));

                /* publication card */
                let publicationListMy = 0;
                if (response.data.count.user !== undefined) {
                    if (response.data.count.user.waiting_start !== undefined) {
                        publicationListMy += response.data.count.user.waiting_start;
                    }
                    if (response.data.count.user.active !== undefined) {
                        publicationListMy += response.data.count.user.active;
                    }
                    if (response.data.count.user.pending !== undefined) {
                        publicationListMy += response.data.count.user.pending;
                    }
                }

                let publicationListCourt = 0;
                if (response.data.count.court !== undefined) {
                    if (response.data.count.court.waiting_start !== undefined) {
                        publicationListCourt += response.data.count.court.waiting_start;
                    }
                    if (response.data.count.court.active !== undefined) {
                        publicationListCourt += response.data.count.court.active;
                    }
                    if (response.data.count.court.pending !== undefined) {
                        publicationListCourt += response.data.count.court.pending;
                    }
                }

                stateCards = this.setCard(stateCards, "/subscriber/published/my-list", "number", publicationListMy);
                stateCards = this.setCard(stateCards, "/subscriber/published/all-list", "number", publicationListCourt);

                stateCards = this.setCard(stateCards, "/subscriber/published/my-list", "progress", (publicationListMy*100)/(publicationListMy+publicationListCourt));
                stateCards = this.setCard(stateCards, "/subscriber/published/all-list", "progress", (publicationListCourt*100)/(publicationListMy+publicationListCourt));

                this.setState({cards: stateCards});
            }
        });

        this.setState({cards: stateCards});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.props.initializedGA !== prevProps.initializedGA) && this.props.initializedGA) {
            this.reactGa();
        }
    }

    reactGa = () => {
        if (this.props.initializedGA) {
            ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search })
        }
    }

    render() {
        const { t } = this.props;

        let displayCard = [[], [], []];
        for (let i = 0; i < this.state.cards.length; i++) {
            displayCard[this.state.cards[i]['level']].push(
                <div onClick={() => this.clickCard(this.state.cards[i].url)} className={classes.dashboardCard+ " card"} key={'dashboard_'+i}>
                    <div className="card-body">
                        <div className="media">
                            <div className="avatar-sm font-size-20 mr-3">
                                <span className="avatar-title bg-soft-primary text-primary rounded">
                                <i className={this.state.cards[i].icon}/>
                            </span>
                            </div>
                            <div className="media-body">
                                <div className="font-size-16 mt-2">{this.state.cards[i].title}</div>
                            </div>
                        </div>
                        <h4 className="mt-4">{(this.state.cards[i].number !== 0) ? this.state.cards[i].number : ''}&nbsp;<br /><br /><br /></h4>
                        <div className="row">
                            <div className="col-7">
                                {/*<p className="mb-0"><span className="text-success mr-2"> {cards[i].up} <i className="mdi mdi-arrow-up"></i> </span></p>*/}
                            </div>
                            {/*<div className="col-5 align-self-center">
                                <div className="progress progress-sm">
                                    <div className={"progress-bar " + this.state.cards[i].color} role="progressbar" style={{width: this.state.cards[i].progress+ "%"}} aria-valuenow={this.state.cards[i].progress} aria-valuemin="0" aria-valuemax="100" />
                                </div>
                            </div>*/}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <Fragment>
                <Breadcrumb title={t("myDesktop")}/>

                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-md-6 col-lg-6 col-xl-4">
                                <div className="col-12">
                                    {displayCard[0]}
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-4">
                                <div className="col-12">
                                    {displayCard[1]}
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-4">
                                <div className="col-12">
                                    {displayCard[2]}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        options: state.options,
        initializedGA: state.initializedGA
    }
}

export default connect(mapStateToProps)(withRouter(withTranslation()(Dashboard)));
