import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import {isNull} from "./izUtils";

let lang = 'sl-SI';
const new_lang = localStorage.getItem('SODNEDRAZBE.selectedLanguage');
if (!isNull(new_lang)) {
    lang = new_lang;
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: lang,
    backend: {
      /* translation file path */
      loadPath: '/assets/i18n/{{ns}}/{{lng}}.json'
    },
    fallbackLng: 'sl-SI',
    debug: false,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    ns: ['translations'],
    defaultNS: 'translations',
    /*keySeparator: false,*/
    interpolation: {
      escapeValue: false/*,
      formatSeparator: ','*/
    },
    react: {
      // wait: true
      useSuspense: true
    }
  })

export default i18n
