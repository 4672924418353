import React, {Component} from 'react';
import {isNull} from "../../../izUtils";
import {koNumbers, koName} from "./elements/katastrke_obcine";
import Select from 'react-select';
import {withTranslation} from 'react-i18next';

class InputSelect2 extends Component {

    state = {
        val: null
    }

    cols = ['col-md-2', 'col-md-10'];
    disabled = null;
    readOnly = null;

    options = null;

    change(e) {
        this.setState({val: e.value});

        this.props.change({target: {name: this.props.name, value: e.value}}, this.props.index);
    }

    componentDidMount() {
        if (this.props.name === 'cadastralMunicipalityCode') {
            this.options = koNumbers();
        } else if (this.props.name === 'cadastralMunicipalityName') {
            this.options = koName();
        }

       this.reloadComponent();
    }

    reloadComponent(){
        if (this.props.display || (this.props.display === undefined)) {

            this.cols = this.props.cols;
            if (this.cols !== undefined) {
                this.cols = this.cols.split('/');
                this.cols = ['col-' + this.cols[0], 'col-' + this.cols[1]];
            }

            this.disabled = false;
            if (this.props.setDisabled !== undefined) {
                this.disabled = true;
            }

            this.readOnly = false;
            if (this.props.setReadOnly !== undefined) {
                this.readOnly = true;
            }

            /*let val = this.props.value;*/
            this.setState({val: this.props.value});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value) {
            this.setState({val: this.props.value});
        }
    }

    render() {
        if (this.state.val !== null) {
            const {t} = this.props;
            if (this.props.display || (this.props.display === undefined)) {
                let labelAppendix = '';
                let inputClass = [];
                if ((this.props.error !== undefined) && !isNull(this.props.error)) {
                    if (((this.props.error.code === 'mandatory') || (this.props.error.code === 'uuid'))) {
                        if ((this.state.val === 0) || (this.state.val === "0") || (this.state.val.length === 0)) {

                            inputClass.push('mandatory');
                        }
                    }
                }

                if (this.props.mandatory) {
                    labelAppendix = '*';
                }

                let colourStyles;
                if ((this.props.error !== undefined) && (this.props.error !== null) && (this.props.error.code === 'mandatory')) {
                    colourStyles = {
                        placeholder: (defaultStyles) => {
                            return {
                                ...defaultStyles,
                                color: '#ff6969;',
                            }
                        },
                        control: (defaultStyles) => {
                            return {
                                ...defaultStyles,
                                background: '#ffb9b3',
                            }
                        },
                    }
                } else {
                    if (document.getElementsByTagName("body")[0].classList.contains("darkMode")) {
                        colourStyles = {
                            placeholder: (defaultStyles) => {
                                return {
                                    ...defaultStyles,
                                    color: '#8687a7',
                                }
                            },
                            option: (defaultStyles) => {
                                return {
                                    ...defaultStyles,
                                    background: '#2b384a',
                                }
                            },
                            singleValue: (defaultStyles) => {
                                return{
                                    ...defaultStyles,
                                    color: '#fff'
                                }
                            },
                            control: (defaultStyles) => {
                                return {
                                    ...defaultStyles,
                                    background: '#2b384a',
                                    border: '1px solid #364458',
                                }
                            },
                        }
                    } else {
                        colourStyles = {
                            placeholder: (defaultStyles) => {
                                return {
                                    ...defaultStyles,
                                    color: '#8687a7',
                                }
                            }
                        }
                    }

                }

                let noOptions = ['cadastralMunicipalityName', 'cadastralMunicipalityCode'];
                let noOptionsMsg = {};
                if (noOptions.indexOf(this.props.name) !== -1) {
                    noOptionsMsg.noOptionsMessage = () => t('notExists');
                }

                return (
                    <div className="form-group row">
                        <label htmlFor={this.props.name}
                               className={this.cols[0] + " col-form-label" + (this.props.labelRight !== undefined ? " text-right" : "")}>{this.props.label + labelAppendix}</label>
                        <div className={this.cols[1]}>
                            <Select
                                className={inputClass.join(' ')}
                                disabled={this.disabled}
                                readOnly={this.readOnly}
                                value={(this.state.val !== "0") ? {value: this.state.val, label: this.state.val} : ''}
                                id={this.props.name + ((this.props.index !== undefined) ? '_' + this.props.index : '')}
                                name={this.props.name}
                                onChange={(e) => this.change(e)}
                                options={this.options}
                                placeholder={t('placeholders.select')}
                                noResultsText={t('search.notFound')}
                                styles={colourStyles}
                                {...noOptionsMsg} />
                        </div>
                    </div>
                );
            } else {
                return ('');
            }
        } else {
            return ('');
        }
    }
};

export default withTranslation()(InputSelect2);
