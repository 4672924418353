import React, {Component, Fragment} from 'react';
import {isNull} from "../../../izUtils";
import Select from 'react-select';
import {countries, zipNumbers, cityName, getCity, getZip} from "./countries.js";
import {withTranslation} from 'react-i18next';

class InputAddress extends Component {

    state = {
        address: null,
        componentDidMount: false,
        hideAddress: false
    }

    countryList = countries();
    zipList = zipNumbers();
    cityName = cityName();

    componentDidMount() {
        this.reloadAddress();
    }

    reloadAddress() {
        let setTo = false;
        if ((this.props.hideAddress !== undefined) && (this.props.hideAddress.length > 0)) {
            if (this.props.hideAddress.indexOf(this.props.name) !== -1) {
                setTo = true;
            }
        }
        this.setState({hideAddress: setTo});

        const address = this.getAddress(setTo);
        this.setState({address: address});
    }

    getAddress(setTo){
        let stars = '';
        if ((setTo !== undefined) && (setTo === true)) {
            stars = '****';
        } else if (this.state.hideAddress) {
            stars = '****';
        }

        let address = {
            street: stars,
            houseNumber: stars,
            zip: '',
            city: '',
            country: 'Slovenija'
        }

        if (!isNull(this.props.value) && (this.props.value !== undefined) && (this.props.value.length) && !isNull(this.props.addresses)) {
            for (let i = 0; i < this.props.addresses.length; i++) {
                if (this.props.addresses[i].id === this.props.value) {
                    address = this.props.addresses[i];
                }
            }
        }

        return address;
    }

    onChange = (e, field, executePropsChange) => {
        let currentAddress = {...this.state.address};
        if (e.target !== undefined) {
            currentAddress[field] = e.target.value;
        } else {
            currentAddress[field] = e.value;
        }

        if ((field === 'zip') && (this.state.address.country === "Slovenija")) {
            currentAddress['city'] = getCity(currentAddress[field]);
        } else if ((field === 'city') && (this.state.address.country === "Slovenija")) {
            currentAddress['zip'] = getZip(currentAddress[field]);
        } else if (field === 'country') {
            currentAddress['city'] = '';
            currentAddress['zip'] = '';
        }

        this.setState({address: currentAddress});

        if ((executePropsChange !== undefined) && (executePropsChange)) {
            this.props.change(currentAddress, this.props.name, this.props.index);
        }
    }

    textInput = (field, label, labelAppendix, cols, placeholder) => {
        return (
            <div className="form-group row">
                <label htmlFor={this.props.name + "_" + field} className={cols[0] + " col-form-label"}>{label + labelAppendix}</label>
                <div className={cols[1]}>
                    <input
                        placeholder={placeholder}
                        value={this.state.address[field] !== '****' ? this.state.address[field] : ''}
                        id={this.props.name + "_" + field}
                        name={this.props.name + "_" + field}
                        className={"form-control" + ((this.props.error !== undefined) && (this.props.error !== null) && (this.props.error.code === 'mandatory') && (this.state.address[field].length === 0) ? ' ' + this.props.error.code : '' )}
                        type="text"
                        onChange={(e) => this.onChange(e, field)}
                        onBlur={() => this.props.change(this.state.address, this.props.name, this.props.index)}
                        />
                </div>
            </div>
        );
    }

    selectInput = (field, label, labelAppendix, cols, placeholder, list) => {
        let value = null;
        const {t} = this.props;
        if (this.state.address[field].length !== 0) {
            value = {value: this.state.address[field], label: this.state.address[field]};
        }
        let colourStyles;
        if ((this.props.error !== undefined) && (this.props.error !== null) && (this.props.error.code === 'mandatory') && (this.state.address[field].length === 0)) {
            //error
            if (document.getElementsByTagName("body")[0].classList.contains("darkMode")) {
                colourStyles = {
                    placeholder: (defaultStyles) => {
                        return {
                            ...defaultStyles,
                            color: '#ff6969;',
                        }
                    },
                    option: (defaultStyles) => {
                        return {
                            ...defaultStyles,
                            background: '#2b384a',
                        }
                    },
                    control: (defaultStyles) => {
                        return {
                            ...defaultStyles,
                            background: '#2b384a',
                            border: '1px solid #364458',
                        }
                    },
                }
            } else {
                colourStyles = {
                    placeholder: (defaultStyles) => {
                        return {
                            ...defaultStyles,
                            color: '#ff6969;',
                        }
                    },
                    control: (defaultStyles) => {
                        return {
                            ...defaultStyles,
                            background: '#ffb9b3',
                        }
                    },
                }
            }
        } else {
            //no error
            if (document.getElementsByTagName("body")[0].classList.contains("darkMode")) {
                colourStyles = {
                    control: (defaultStyles) => {
                        return {
                            ...defaultStyles,
                            background: '#2b384a',
                            border: '1px solid #364458',
                        }
                    },
                    option: (defaultStyles) => {
                        return {
                            ...defaultStyles,
                            background: '#2b384a',
                        }
                    },
                    singleValue: (defaultStyles) => {
                        return{
                            ...defaultStyles,
                            color: '#fff'
                        }
                    },
                    placeholder: (defaultStyles) => {
                        return {
                            ...defaultStyles,
                            color: '#fff',
                        }
                    }
                }
            } else {
                colourStyles = {
                    placeholder: (defaultStyles) => {
                        return {
                            ...defaultStyles,
                            color: '#8687a7',
                        }
                    }
                }
            }
        }

        return (
            <div className="form-group row">
                <label htmlFor={this.props.name + "_" + field} className={cols[0] + " col-form-label"}>{label + labelAppendix}</label>
                <div className={cols[1]}>
                    <Select options={list} value={value} placeholder={placeholder} onChange={(e) => this.onChange(e, field, true)} styles={colourStyles} noOptionsMessage={() => t('notExists')} />
                </div>
            </div>
        )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.props.name === 'viewAddress') || (this.props.name === 'saleAddress') || (this.props.name === 'openBidAddress')) {
            if (this.props.value !== prevProps.value) {
                this.reloadAddress();
            }
        } else if (this.props.hideAddress !== prevProps.hideAddress) {
            this.reloadAddress();
        }
    }

    render() {
        if (this.state.address !== null) {
            if (this.props.display || (this.props.display === undefined)) {
                const {t} = this.props;

                let labelAppendix = '';
                if (this.props.mandatory) {
                    labelAppendix = '*';
                }

                let cols = this.props.cols;
                if (cols !== undefined) {
                    cols = cols.split('/');
                    cols = ['col-' + cols[0], 'col-' + cols[1]];
                } else {
                    cols = ['col-md-2', 'col-md-10'];
                }

                let colourStyles;
                if (document.getElementsByTagName("body")[0].classList.contains("darkMode")) {
                    colourStyles = {
                        control: (defaultStyles) => {
                            return {
                                ...defaultStyles,
                                background: '#2b384a',
                                border: '1px solid #364458',
                            }
                        },
                        option: (defaultStyles) => {
                            return {
                                ...defaultStyles,
                                background: '#2b384a',
                            }
                        },
                        singleValue: (defaultStyles) => {
                            return{
                                ...defaultStyles,
                                color: '#fff'
                            }
                        },
                        placeholder: (defaultStyles) => {
                            return {
                                ...defaultStyles,
                                color: '#fff',
                            }
                        }
                    }
                }

                const hr = ((this.props.hr === undefined) || (this.props.hr)) ? <hr/> : '';
                return (
                    <Fragment>
                        {hr}
                        <div className="form-group row">
                            <div className={"col-lg-"+(this.props.mainCol !== undefined ? this.props.mainCol : "6" )}>
                                {!this.state.hideAddress ? this.textInput("street", t('bidder.profile.input.street'), labelAppendix, cols, t('bidder.profile.placeholder.street')) : ''}
                                {!this.state.hideAddress ? this.textInput("houseNumber", t('bidder.profile.input.houseNumber'), labelAppendix, cols, t('bidder.profile.placeholder.houseNumber')) : ''}
                                {
                                    (this.state.address.country !== "Slovenija") ?
                                        this.textInput("zip", t('bidder.profile.input.zip'), labelAppendix, cols, t('bidder.profile.placeholder.zip')) :
                                        this.selectInput("zip", t('bidder.profile.input.zip'), labelAppendix, cols, t('bidder.profile.placeholder.zip'), this.zipList)
                                }
                                {
                                    (this.state.address.country !== "Slovenija") ?
                                        this.textInput("city", t('bidder.profile.input.city'), labelAppendix, cols, t('bidder.profile.placeholder.city')) :
                                        this.selectInput("city", t('bidder.profile.input.city'), labelAppendix, cols, t('bidder.profile.placeholder.city'), this.cityName)
                                }
                                <div className="form-group row">
                                    <label htmlFor={this.props.name + "_country"} className={cols[0] + " col-form-label"}>{t('bidder.profile.input.country')}{labelAppendix}</label>
                                    <div className={cols[1]}>
                                        <Select options={this.countryList} styles={colourStyles} value={{value: this.state.address.country, label: this.state.address.country}} placeholder={t('bidder.profile.placeholder.country')} noOptionsMessage={() => t('notExists')} onChange={(e) => this.onChange(e, "country")} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {hr}
                    </Fragment>
                );

            } else {
                return ('');
            }
        } else {
            return ('');
        }
    }
}
export default withTranslation()(InputAddress);
