import moment from "moment";
import axios from "axios";
import env from "../../../../env/src_config";
import {getKoNameByCode, getKoCode} from "../../../../hoc/Form/Select/elements/katastrke_obcine";


export const selectChangeRootHandler = (e, reloadState) => {
    let data = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));
    if ((e.target.value === "0") || (e.target.value.length === 0)) {
        delete data.values.root[e.target.name];
    } else {
        data.values.root[e.target.name] = e.target.value;
    }
    localStorage.setItem('SODNEDRAZBE.JSData', JSON.stringify(data));

    reloadState();
}

export const textChangeRootHandler = (e, reloadState) => {
    let val = e.target.value;
    let needToReloadState = true;

    if (e.target.getAttribute('class').indexOf('float') !== -1) {
        val = val.replace(/\./g, '').replace(',', '.');
    }

    let data = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));

    if (val.length) {
        data.values.root[e.target.name] = val;
    } else {
        if (data.values.root[e.target.name] !== undefined) {
            delete data.values.root[e.target.name];
        }
    }

    localStorage.setItem('SODNEDRAZBE.JSData', JSON.stringify(data));

    if (needToReloadState) {
        reloadState();
    }
}

export const addressChangeRootHandler = (currentAddress, name, headers, reloadState, index) => {
    let localStorageData = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));

    let go = 1;
    Object.keys(currentAddress).forEach((value) => {
        if (['latitude', 'longitude'].indexOf(value) === -1) {
            if (currentAddress[value].length === 0){
                if (value !== 'region') {
                    go = 0;
                }
            }
        }
    });

    if (index === undefined) {
        localStorageData.values.root[name] = '';
    } else {
        if (localStorageData.values.subjects === undefined) {
            localStorageData.values.subjects = [];
        }
        if (localStorageData.values.subjects[index] === undefined) {
            localStorageData.values.subjects[index] = {};
        } else {
            localStorageData.values.subjects[index][name] = '';
        }
    }
    localStorage.setItem('SODNEDRAZBE.JSData', JSON.stringify(localStorageData));

    if (go) {
        let postData = {values: currentAddress};
        delete postData.values.latitude;
        delete postData.values.longitude;
        axios.post(env.api.private + 'address', postData, headers).then((response) => {
            if (response.status === 200) {
                if (response.data.valid) {
                    /* if valid store address */
                    postData['save'] = true;
                    axios.post(env.api.private + 'address', postData, headers).then((response) => {
                        if (response.status === 200) {

                            if (index === undefined) {
                                localStorageData.values.root[name] = response.data.record.id;
                            } else {
                                localStorageData.values.subjects[index][name] = response.data.record.id;
                            }
                            localStorage.setItem('SODNEDRAZBE.JSData', JSON.stringify(localStorageData));
                            reloadState();
                        }
                    }, (error) => {
                        console.log(error);
                    });
                }
            }
        }, (error) => {
            console.log(error);
        });
    }
}

export const datetimeChangeRootHandler = (date, name, reloadState) => {
    let data = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));

    if (date === null) {
        delete data.values.root[name];
    } else {
        data.values.root[name] = moment(date).toISOString();
    }

    localStorage.setItem('SODNEDRAZBE.JSData', JSON.stringify(data));
    reloadState();
}

export const datetimeChangeSubjectHandler = (date, name, index, reloadState) => {
    let data = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));

    if (data.values.subjects === undefined) {
        data.values.subjects = [];
    }

    if (data.values.subjects[index] === undefined) {
        data.values.subjects[index] = {};
    }

    data.values.subjects[index][name] = moment(date).toISOString();

    if (data.values.subjects[index][name] !== null && data.values.subjects[index][name].length === 0) {
        delete data.values.subjects[index][name];
    }

    localStorage.setItem('SODNEDRAZBE.JSData', JSON.stringify(data));

    reloadState();
}

export const selectChangeSubjectHandler = (e, index, reloadState) => {

    let data = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));

    if ((e.target.value === "0") || (e.target.value.length === 0)) {
        if ((data.values.subjects !== undefined) && (data.values.subjects[index] !== undefined)) {
            delete data.values.subjects[index][e.target.name];
        }
    } else {
        if (data.values.subjects === undefined) {
            data.values.subjects = [];
        }

        if (data.values.subjects[index] === undefined) {
            data.values.subjects[index] = {};
        }

        data.values.subjects[index][e.target.name] = e.target.value;

        /* exceptions */
        if (e.target.name === 'cadastralMunicipalityCode') {
            data.values.subjects[index]['cadastralMunicipalityName'] = getKoNameByCode(e.target.value);
        } else if (e.target.name === 'cadastralMunicipalityName') {
            data.values.subjects[index]['cadastralMunicipalityCode'] = getKoCode(e.target.value);
        } else if (e.target.name === "propertyKind") {
            delete data.values.subjects[index]['address'];
        }
        /* END of exceptions */
    }

    localStorage.setItem('SODNEDRAZBE.JSData', JSON.stringify(data));

    reloadState();
}

export const textChangeSubjectHandler = (e, index, name) => {
    let data = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));

    if (data.values.subjects === undefined) {
        data.values.subjects = [];
    }

    if (data.values.subjects[index] === undefined) {
        data.values.subjects[index] = {};
    }

    if (e instanceof moment) {
        data.values.subjects[index][name] = e.format('YYYY');
    } else {
        let val = e.target.value;
        if (e.target.getAttribute !== undefined) {
            if (e.target.getAttribute('class').indexOf('float') !== -1) {
                val = val.replace(/\./g, '').replace(',', '.');
            }
        }

        if (val.length === 0) {
            delete data.values.subjects[index][e.target.name];
        } else {
            data.values.subjects[index][e.target.name] = val;
        }
    }

    localStorage.setItem('SODNEDRAZBE.JSData', JSON.stringify(data));
}

export const selectChangeFilesHandler = (e, index, reloadState, localStorageName, insideArrayIndex) => {
    if (localStorageName === undefined) {
        localStorageName = 'SODNEDRAZBE.JSData';
    }

    let data = JSON.parse(localStorage.getItem(localStorageName));

    if (data.values.files === undefined) {
        data.values.files = [];
    }

    if (data.values.files[index] === undefined) {
        data.values.files[index] = {};
    }

    if ((insideArrayIndex !== undefined) && (data.values.files[index][insideArrayIndex] !== undefined)) {
        data.values.files[index][insideArrayIndex] = {};
    }

    if (insideArrayIndex !== undefined) {
        data.values.files[index][insideArrayIndex][e.target.name] = e.target.value;
    } else {
        data.values.files[index][e.target.name] = e.target.value;
    }

    localStorage.setItem(localStorageName, JSON.stringify(data));

    if (reloadState !== undefined) {
        reloadState();
    }
}

export const textChangeFilesHandler = (e, index, localStorageName, insideArrayIndex) => {
    if (localStorageName === undefined) {
        localStorageName = 'SODNEDRAZBE.JSData';
    }

    let data = JSON.parse(localStorage.getItem(localStorageName));

    if (data.values.files === undefined) {
        data.values.files = [];
    }

    if (data.values.files[index] === undefined) {
        data.values.files[index] = {};
    }

    if ((insideArrayIndex !== undefined) && (data.values.files[index][insideArrayIndex] !== undefined)) {
        data.values.files[index][insideArrayIndex] = {};
    }

    if (insideArrayIndex !== undefined) {
        data.values.files[index][insideArrayIndex][e.target.name] = e.target.value;
    } else {
        data.values.files[index][e.target.name] = e.target.value;
    }

    localStorage.setItem(localStorageName, JSON.stringify(data));
}
