import React, { Component, Fragment } from 'react';
import {linker} from "../../Helpers/Linker";

class Narok extends Component {

    mapLinker = (input, mainLinkerFields) => {
        const inputlinkerFields = {
            input: input,
            textHandler: (e) => this.props.textChangeRootHandler(e, input)
        }

        return linker({...mainLinkerFields, ...inputlinkerFields});
    }

    render() {
        const mainLinkerFields = {
            data: this.props.data,
            addressChangeRootHandler: this.props.addressChangeRootHandler,
            selectHandler: this.props.selectChangeRootHandler,
            datetimeHandler: this.props.datetimeChangeRootHandler,
            cols: "4/8",
            addresses: this.props.addresses,
            booleanHandler: undefined
        }

        const fields = ['openBidAt', 'openBidTitle', 'openBidAddress', 'openBidPlace'].map(input => {
            return this.mapLinker(input, mainLinkerFields);
        });

        return (
            <Fragment>
                <div className="tab-pane" id="narok" role="tabpanel">
                    <div className="row">
                        <div className="col-lg-12">
                            {fields}
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Narok;
