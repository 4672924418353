import {fillNull} from "../../izUtils";

export const Realestateid = (input, t) => {

    let displayParcelNumber = null;
    if ((input.saleSubject === "2ee072ab-7833-48e6-9e83-d9cf8d7b92f0") && (input.propertyType !== undefined) && (input.propertyKind !== undefined)) {
        if (input.propertyType === "d0f0d839-81b2-4eec-9e26-238b7252c9d9") {
            switch (input.propertyKind) {
                case "5b7e6618-332b-46c3-9302-a80641f8e7e0" /* 01 */:
                    displayParcelNumber = t('published.property.realestateid.parcelNumber')+' ' +  fillNull(input.cadastralMunicipalityCode, '/') + ' ' + fillNull(input.parcelNumber, '/');
                    break;
                case "cb6da222-8b42-4c55-81e5-86ab15058e21" /* 02 */:
                    displayParcelNumber = t('published.property.realestateid.buildingNumber')+' ' +  fillNull(input.cadastralMunicipalityCode, '/') + '-' + fillNull(input.buildingNumber, '/');
                    break;
                case "16b15e12-52c0-42c6-94a5-02a9b64aa79f" /* 03 */:
                    displayParcelNumber = t('published.property.realestateid.partNumber')+' ' +  fillNull(input.cadastralMunicipalityCode, '/') + '-' + fillNull(input.buildingNumber, '/') +'-' + fillNull(input.partNumber, '/');
                    break;
                case "e3cac9ea-1448-4adb-9d70-ad03a3ad6175" /* 05 */:
                    displayParcelNumber = t('published.property.realestateid.hangingBuildingNumber')+' ' +  fillNull(input.cadastralMunicipalityCode, '/') + '-' + fillNull(input.hangingBuildingNumber, '/');
                    break;
                case "ba9d6c1d-22c3-4760-a1ec-1ab2ef7fa5d5" /* 06 */:
                    displayParcelNumber = t('published.property.realestateid.undefined')+' ' +  fillNull(input.cadastralMunicipalityCode, '/') + '-' + fillNull(input.buildingNumber, '/') +'-' + fillNull(input.partNumber, '/');
                    break;
                default:
            }
        } else if (input.propertyType === "49a01e1f-6e9c-487a-83a4-e893ce61f3ac") {
            if (input.propertyKind === "6d1c7395-c075-47d9-a626-a17628add817") { //04
                displayParcelNumber = t('published.property.realestateid.buildingRight')+' ' +  fillNull(input.cadastralMunicipalityCode, '/') + '-' + fillNull(input.hangingBuildingNumber, '/');
            }
        }
    }

    return displayParcelNumber;
};
