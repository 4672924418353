import Swal from "sweetalert2";
import i18n from '../../i18n';

const axiosErrorResponse = async (error) => {
    //let history = useHistory();
    if(error.respose !== undefined) {
        if (error.response.data.debug === "JWT authentication token is invalid") {
            Swal.fire({
                title: i18n.t('error'),
                text: i18n.t('swalError.jwt'),
                icon: 'error',
                confirmButtonColor: '#1068ce',
            });
        } else if (error.response.data.debug === "failed to import publication: failed to open zip: zip: not a valid zip file") {
            Swal.fire({
                title: i18n.t('error'),
                text: i18n.t('swalError.import'),
                icon: 'error',
                confirmButtonColor: '#1068ce',
            });
        } else if (error.response.data.debug === "failed to update publication: denied publication update on upcoming start") {
            Swal.fire({
                title: i18n.t('error'),
                text: i18n.t('swalError.updatePublication'),
                icon: 'error',
                confirmButtonColor: '#1068ce',
            });
        } /*else if (error.response.data.debug === "failed to create publication submit: already submited") {
            return await Swal.fire({
                title: 'Opozorilo',
                text: 'Na to dražbo ste že prijavljeni',
                icon: 'info',
                confirmButtonColor: '#1068ce',
                confirmButtonText: "V redu",
            }).then( result => {
                return 'goBack';
            });
        } */else {
            //"JWT authentication token is malformed"
            alert(error.response.data.error+': '+error.response.data.debug);
            console.log(error.response.data);
        }
    }
};


export default axiosErrorResponse;
