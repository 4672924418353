import {NavLink} from "react-router-dom";
import React, {Fragment} from "react";
import { withTranslation } from 'react-i18next';
import classes from "../Leftside.module.css";

const Menu = (props) => {
    const { t } = props;

    const hideSideBar = () => {
        if (document.getElementsByTagName("body")[0].classList.contains('sidebar-enable')) {
            document.getElementsByTagName("body")[0].classList.remove("sidebar-enable");
        }
    }

    let routs;
    if (props.type === "court") {
        routs = [
            {title: t('menu.draft.title'), content: [
                { url: "/subscriber/drafts/create2/", title: t('menu.draft.link.new'), icon: "mdi mdi-pencil-plus-outline"},
                { url: "/subscriber/drafts/my-list", title: t('menu.draft.link.my_draft'), icon: "mdi mdi-file-document-box-plus-outline" },
                { url: "/subscriber/drafts/all-list", title: t('menu.draft.link.all_draft'), icon: "mdi mdi-file-document-box-plus" },
            ]},
            {title: t('menu.published.title'), content: [
                { url: "/subscriber/published/my-list", title: t('menu.published.link.my_published'), icon: "mdi mdi-file-document-box-search-outline" },
                { url: "/subscriber/published/all-list", title: t('menu.published.link.all_published'), icon: "mdi mdi-file-document-box-search" },
            ]},
            {title: t('menu.bidder.menu_title'), content: [
                { url: "/subscriber/published/bidders/my-list", title: t('menu.bidder.link.my_published'), icon: "mdi mdi-eye-plus-outline" },
                { url: "/subscriber/published/bidders/all-list", title: t('menu.bidder.link.all_published'), icon: "mdi mdi-eye-plus" },
            ]},
            {title: t('menu.finished.menu_title'), content: [
                { url: "/subscriber/finished/my-list", title: t('menu.published.link.my_published'), icon: "mdi mdi-file-key-outline" },
                { url: "/subscriber/finished/all-list", title: t('menu.published.link.all_published'), icon: "mdi mdi-file-key" },
            ]},
            {title: t('menu.archived.menu_title'), content: [
                { url: "/subscriber/archive/my-list", title: t('menu.published.link.my_published'), icon: "mdi mdi-archive-outline" },
                { url: "/subscriber/archive/all-list", title: t('menu.published.link.all_published'), icon: "mdi mdi-archive" },
            ]},
        ];

    } else if (props.type === "bidder") {
        /* this is just so that people from court won't see this */
        routs = [
            { url: "/bidder/published/list", title: t('leftSide.bidder.list'), icon: "mdi mdi-clipboard-list-outline" },
            { url: "/search", title: t('leftSide.bidder.advancedSearched'), icon: "mdi mdi-database-search" },
            { url: "/bidder/profile", title: t('leftSide.bidder.myProfile'), icon: "mdi mdi-human-male-female" },
            { url: "/activelist", title: t('leftSide.bidder.activeAuction'), icon: "mdi mdi-progress-clock" },
            { url: "/activemylist", title: t('leftSide.bidder.myActiveAuction'), icon: "mdi mdi-radar" },
            { url: "/bidder/signed", title: t('leftSide.bidder.myCurrent'), icon: "mdi mdi-file-document-box-check" },
            { url: "/bidder/signedended", title: t('leftSide.bidder.myFinished'), icon: "mdi mdi-archive-outline" },
            { url: "/favouriteslist", title: t('leftSide.bidder.myFavourites'), icon: "mdi mdi-star-outline" },
            { url: "/cancellist", title: t('leftSide.bidder.cancellist'), icon: "mdi mdi-file-document-box-remove" }
        ];
    } else if (props.type === "public") {
        routs = [
            { url: "/", title: t('leftSide.bidder.list'), icon: "mdi mdi-clipboard-list-outline" },
            { url: "/activelist", title: t('leftSide.bidder.activeAuction'), icon: "mdi mdi-progress-clock" },
            { url: "/search", title: t('leftSide.bidder.advancedSearched'), icon: "mdi mdi-database-search" },
            { url: "/cancellist", title: t('leftSide.bidder.cancellist'), icon: "mdi mdi-file-document-box-remove" },
        ];
    }

    return  routs.map((row, index) => {

        let content = null;
        if (row.content !== undefined) {
            content = row.content.map((row, index) => {
                return (
                    <li key={row.url}>
                        <NavLink to={row.url} className="waves-effect" onClick={hideSideBar}><i
                            className={row.icon}/><span>{row.title}</span></NavLink>
                    </li>
                )
            });
        }

        if (content === null) {
            return (
                <li key={row.url}>
                    <NavLink to={row.url} className="waves-effect" onClick={hideSideBar}><i
                        className={row.icon}/><span>{row.title}</span></NavLink>
                </li>
            );
        } else {
            return (
                <Fragment key={"menu_side_"+index}>
                    {/* eslint-disable-next-line */}
                    <li key={row.title}
                        data-toggle="collapse"
                        aria-expanded="false"
                        data-target={"#submenuside"+index}
                        aria-controls={"submenuside"+index}>

                        <span className={classes.sideBarSpan + " has-arrow waves-effect"}>
                            <span>{row.title}</span>
                        </span>
                    </li>

                    <li id={"submenuside"+index} className="collapse">
                        <ul style={{padding: "0px"}}>
                            {content}
                        </ul>
                    </li>
                </Fragment>
            );
        }
    });
};

export default withTranslation()(Menu);





