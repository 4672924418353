export const CheckboxHandler = (localStorageName, e, filter, value) => {
    let localStorageFilter = JSON.parse(localStorage.getItem(localStorageName));
    if (e.target.checked) {
        if (localStorageFilter.filter[filter] === undefined) {
            localStorageFilter.filter[filter] = [];
            localStorageFilter.filter[filter].push(value);
        } else {
            if (localStorageFilter.filter[filter].indexOf(value) === -1) {
                localStorageFilter.filter[filter].push(value);
            }
        }
    } else {
        for( let i = 0; i < localStorageFilter.filter[filter].length; i++){
            if ( localStorageFilter.filter[filter][i] === value) {
                localStorageFilter.filter[filter].splice(i, 1);
            }
        }
        if (localStorageFilter.filter[filter].length === 0) {
            delete localStorageFilter.filter[filter];
        }
    }
    localStorageFilter.pagination = {offset: 1, limit: 25}
    localStorage.setItem(localStorageName, JSON.stringify(localStorageFilter));
}
