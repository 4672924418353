import React, { Component, Fragment } from 'react';
import {linker} from "../../Helpers/Linker";
import {withTranslation} from 'react-i18next';

class SaleInfo extends Component {

    booleandChangeHandler = (e) => {
        if (e.target !== undefined) {
            const setVal = (e.target.value === "true");

            let data = JSON.parse(localStorage.getItem('SODNEDRAZBE.JSData'));
            data.values.root[e.target.name] = setVal;
            if (e.target.name === "isResale") {
                if (data.values.root['isPreviousPublication'] === undefined) {
                    data.values.root['isPreviousPublication'] = false;
                }
            }

            localStorage.setItem('SODNEDRAZBE.JSData', JSON.stringify(data));

            this.props.reloadState();
        }
    }

    render() {
        const {t} = this.props;
        let dynamicContent = null;
        if ((this.props.data.saleForm.value === "7bb966a3-ecb4-4df0-ba57-3ce6df44a019") && (this.props.data.saleMethod.value === "2640f02f-2318-41b5-ab70-1fb95e2514bc")) {
            dynamicContent = t('single.dynamic');
        }

        const fieldsLeft = ['saleForm', 'saleMethod', 'saleType', 'debtAmount', 'saleSequenceNumber'].map(input => {
            return linker(input, this.props.data, this.props.selectChangeRootHandler, this.props.textChangeRootHandler, this.props.datetimeChangeRootHandler, "4/8");
        });

        const fieldsRight = ['saleStartAt', 'saleEndAt', 'notifyAt', /*'isDynamicExtension', 'dynamicExtensionInterval', 'dynamicExtensionEndAt'*/].map(input => {
            return linker(input, this.props.data, this.props.selectChangeRootHandler, this.props.textChangeRootHandler, this.props.datetimeChangeRootHandler, "4/8", undefined, this.booleandChangeHandler);
        });

        const resale = ['isResale'].map(input => {
            return linker(input, this.props.data, null, null, null, "4/8", undefined, this.booleandChangeHandler );
        });

        const previousPublicationLeft = ['isPreviousPublication'].map(input => {
            return linker(input, this.props.data, null, null, null, "4/8", undefined, this.booleandChangeHandler );
        });

        const previousPublicationRight = ['previousPublicationId', 'previousPublicationUrl'].map(input => {
            return linker(input, this.props.data, this.props.selectChangeRootHandler, this.props.textChangeRootHandler, null, "4/8");
        });

        const fieldsBottom = ['saleDescription'].map(input => {
            return linker(input, this.props.data, null, this.props.textChangeRootHandler, null, "4/8");
        });

        return (
            <Fragment>
                <div className="tab-pane" id="saleinfo" role="tabpanel">
                    <div className="row">
                        <div className="col-lg-6">
                            {fieldsLeft}
                        </div>
                        <div className="col-lg-6">
                            {fieldsRight}
                            {dynamicContent}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            {resale}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            {previousPublicationLeft}
                        </div>
                        <div className="col-lg-6">
                            {previousPublicationRight}
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-lg-12">
                            {fieldsBottom}
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default withTranslation()(SaleInfo);
