import React, { Component } from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import './CookieConsent.css';
import cookiePng from './cookie.png';
import {NavLink} from "react-router-dom";
import env from '../../env/src_config';

class CookieConsent extends Component {

    state = {
        displayCookieConsent: true,
        cookies: [
            {id: 'token', checked: true, title: 'Žeton za prijavo'},
            {id: 'ga', checked: false, title: 'Google analitika'},
            env.whichMap !== 'openMap' ? {id: 'gmaps', checked: false, title: 'Google maps'} : null
        ]
    }

    localStorageName = 'SODNEDRAZBE.cookieConsent';

    componentDidMount() {
        let localStorageData = localStorage.getItem(this.localStorageName);
        if (localStorageData !== null) {
            localStorageData = JSON.parse(localStorageData);
            let cookies = [...this.state.cookies];
            let allTrue = true;
            let confirmedCookies = [];
            for(let i = 0; i < cookies.length; i++) {
                if (cookies[i] !== null) {
                    if (localStorageData.indexOf(cookies[i].id) !== -1) {
                        cookies[i].checked = true;
                        confirmedCookies.push(cookies[i].id);
                    } else {
                        allTrue = false;
                    }
                }
            }
            let newState = {
                cookies: cookies
            }
            if (allTrue) {
                newState.displayCookieConsent = false;
            }
            this.props.setCookieConsent(confirmedCookies);
            this.setState(newState);
        }
    }

    change = (e) => {
        let cookies = [...this.state.cookies];
        for(let i = 0; i < cookies.length; i++) {
            if (cookies[i] !== null) {
                if (cookies[i].id === e.target.name) {
                    cookies[i].checked = e.target.checked;
                }
            }
        }
        this.setState({cookies: cookies});
    }

    disagreeToAll = () => {
        localStorage.removeItem(this.localStorageName);
        this.props.setCookieConsent([]);
        this.setState({displayCookieConsent: false});
    }

    agreeToSelected = () => {
        let options = [];
        this.state.cookies.forEach(option => {
            if (option !== null) {
                if (option.checked) {
                    options.push(option.id)
                }
            }
        });
        localStorage.setItem(this.localStorageName, JSON.stringify(options));
        this.props.setCookieConsent(options);
        this.setState({displayCookieConsent: false});
    }

    agreeToAll = () => {
        const options = []; 
        this.state.cookies.forEach(option => {
            if (option !== null) {
                options.push(option.id);
            }
        });
        localStorage.setItem(this.localStorageName, JSON.stringify(options));
        this.props.setCookieConsent(options);
        this.setState({displayCookieConsent: false});
    }

    render() {
        const {t} = this.props;

        if (this.state.displayCookieConsent) {

            const checkboxes = []; 
            this.state.cookies.forEach((option, index) => {
                if (option !== null) {
                    checkboxes.push(
                        <div className="custom-control custom-checkbox mb-1 d-block" key={'cookie_'+index}>
                            <input type="checkbox" className="custom-control-input" checked={option.checked} name={option.id} id={'cookie_'+option.id} onChange={(e) => this.change(e)} />
                            <label className="custom-control-label" htmlFor={'cookie_'+option.id}>{option.title}</label>
                        </div>
                    );
                }
            });

            return (
                <div className="CookieConsent cookie_wrapper">
                    <div className="container-fluid pl-5 pr-5">
                        <div className="row">
                            <div className="col-lg-2 my-auto">
                                <img src={cookiePng} alt="cookie" className="cookie_icon mb-3"/>
                            </div>
                            <div className="col-lg-10 text-white">
                                <p className="cookie_text">
                                    {t('cookie.warning.content')} <NavLink to="/cookies" style={{color: "#F5AF5A"}}>{t('cookie.warning.about')}</NavLink>
                                </p>

                                <div className="row" style={{"background": "rgba(0,0,0,0.2)", "padding": "10px"}}>
                                    <div className="col-lg-3">
                                        {checkboxes}
                                    </div>
                                    <div className="col-lg-9 my-auto mx-auto">
                                        <button className="btn btn-primary mr-3 mb-2" onClick={this.agreeToAll}>{t('cookie.btn.agree')}</button>
                                        <button className="btn btn-secondary mr-3 mb-2" onClick={this.agreeToSelected}>{t('cookie.btn.selected')}</button>
                                        <button className="btn btn-secondary mr-3 mb-2" onClick={this.disagreeToAll}>{t('cookie.btn.disagree')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCookieConsent: (cookies) => dispatch({type: 'SETCOOKIECONSENT', value: cookies}),
    }
}

const mapStateToProps = state => {
    return {
        options: state.options
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CookieConsent));
