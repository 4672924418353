import React, { Component } from 'react';
import * as moment from 'moment';
import env from "../../env/src_config";

import './Footer.css';
import {withTranslation} from "react-i18next";

class Footer extends Component {
    render() {
        const {t} = this.props;
        return (
            <footer className="footer">
                <div className="container-fluid">
                    <div className="footer-flex">

                        <div className="w350">
                            {moment().format('YYYY')} © <a rel="noopener noreferrer" target="_blank" href="http://praetor.si">Praetor d.o.o.</a> {t('association')} <a rel="noopener noreferrer" target="_blank" href="http://izstop.si">Izstop d.o.o.</a><br />
                            Verzija: {env.version} <br />
                            <a target="_blank" href="/privacystatement">{t('privacystatement.title')}</a> | <a target="_blank" href="/cookies">{t('selfDiag.cookie.title')}</a> <br />
                        </div>
                        <div className="logos">
                            <div className="d-block">
                                <img src="/img/logos/VSRS_mono_inverse.svg" className="vsrs" alt=""  />
                                <img src="/img/logos/logo-eu_mono.svg" className="eu-logo" alt=""  />
                                <img src="/img/logos/sodstvo2020_mono-fixed.svg" className="slo-sodstvo" alt="" />
                                <img src="/img/logos/logo-ucinkovito-pravosodje-mono.svg" className="logo-pravosodje" alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            </footer>
        );
    }
}

export default withTranslation()(Footer);
