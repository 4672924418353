import React, { Component, Fragment } from 'react';
import axios from "axios";
import env from "../../../env/src_config";
import axiosErrorResponse from "../../AxiosErrorResponse/AxiosErrorResponse";
import {connect} from "react-redux";
import {withTranslation} from 'react-i18next';

class UserTimeout extends Component {

    state = {
        expTimeout: null,
        showButton: false
    }

    componentDidMount() {
        axios.post(env.api.api + '/time', {}, this.props.options.postHeaders).then(res => {
            if (res.status === 200) {
                let exp = this.props.options.parsedToken.exp - res.data.unix;
                if (exp < 0) {
                    this.props.sign("reasign", "logout");
                }
                this.unix = exp;
                this.updateExp();
            }
        }, (error) => {
            axiosErrorResponse(error);
        });
    }

    unix = null;

    hhmmss = (secs) => {
        let minutes = Math.floor(secs / 60);
        secs = secs%60;
        let hours = Math.floor(minutes/60)
        minutes = minutes%60;
        let update = '|0';
        if ((minutes < 15) && (hours === 0)) {
            update = '|1';
        }
        return `${hours}:${this.pad(minutes)}:${this.pad(secs)}`+update;
    }

    pad = (num) => {
        return ("0"+num).slice(-2);
    }

    int = null;

    componentWillUnmount() {
        clearInterval(this.int);
    }

    updateExp() {
        this.int = setInterval(() => {
            if (this.unix !== null) {
                this.unix -= 1;
                if (this.unix === 0) {
                    clearInterval(this.int);
                    this.props.sign("reasign", "logout");
                } else if (this.unix < 0) {
                    clearInterval(this.int);
                    this.props.sign("reasign", "logout");
                }
                const time = this.hhmmss(this.unix).split('|');
                this.setState({
                    expTimeout: time[0],
                    showButton: time[1] === "1",
                })
            }
        }, 1000);
    }

    render() {

        let link = '';
        if (this.props.options.parsedToken.bidderId) {
            link = env.externallinks.sipass;
        } else {
            link = env.externallinks.vlozisce;
        }

        if (this.state.expTimeout !== null) {
            const {t} = this.props;
            return (
                <Fragment>
                    <div>{t('leftSide.timeout')}: {this.state.expTimeout} </div>
                    {
                        this.state.showButton ?
                            <a href={link} className="btn btn-danger btn-sm mt-2"><span>{t('leftSide.extend')}</span></a>
                            : ''
                    }
                </Fragment>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = state => {
    return {
        options: state.options
    }
}

export default connect(mapStateToProps)(withTranslation()(UserTimeout));
